.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;

  .heading {
    width: 90%;
    text-align: center;
      p{
        font-weight: 500;
        font-size: 40px;
        letter-spacing: 0.01em;
        line-height: 80px;
        text-align: center;
         font-weight: bold;
        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: 35px;
          padding: 1rem 0rem;
      
        }
      
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          font-size: 35px;
          line-height: 60px;
        }
      

      span {
        color: #40a1dc;

        img {
          width: 30px;

          @media screen and (max-width:"600px") {
            width: 15px;
          }

          @media screen and (min-width:"1024px") and (max-width:"1400px") {
            width: 30px;
          }

          @media screen and (min-width:"600px") and (max-width:"1024px") {
            width: 25px;
          }
        }
      }
    }
  }
}