.container {
  width: 100%;
  overflow: auto;
  margin: 20px 0;
  height: 400px;
  max-height: 500px;
  .wrapper {
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .heading {
      padding-top: 1rem;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0.02em;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }
    input {
      background-color: #111422;
      height: 50px;
      border-radius: 7px;
      color: white;
      border: none;
      outline: none;
      padding: 10px;
    }
    .loader {
      display: flex;
      justify-content: center;
      height: 20vh;
    }
    .userWrapper {
      background-color: #24252f;
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      height: 60px;
      border-radius: 4px;
      img {
        width: 35px;
        cursor: pointer;
      }
    }
  }
}
