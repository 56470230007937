.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1750px;
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px;
    // margin: 1.5rem 2.1rem 2rem 2.1rem;
    box-sizing: border-box;
    @media screen and (max-width: 1024px) {
      padding: 20px;
    }

    .heading {
      display: flex;
      width: 100%;
      text-align: center;

      .back {
        width: 40px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .imgLogo {
        width: 100%;
        text-align: center;
        .logo {
          width: 12%;
          margin-right: 35px;
          @media screen and (max-width: 1024px) {
            width: 25%;
          }
          @media screen and (max-width: 574px) {
            width: 50%;
          }
        }
      }
    }

    .CardWrapper {
      display: flex;

      column-gap: 0.8rem;
      flex-direction: row;
      // row-gap: 1rem;
      // min-height: 300px;
      // justify-content: center;
      // flex-wrap: wrap;
      // height: 81vh;
      // align-items: center;

      @media screen and (max-width: 1024px) {
        height: auto;
        flex-direction: column;
        row-gap: 1rem;
        column-gap: 0.8rem;
      }

      .leftCard {
        // height: 550.71px;
        width: 50%;
        row-gap: 1rem;
        // display: flex;
        // justify-content: center;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .buttonWrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: end;

        .btn1 {
          border-radius: 4px;
          // width: 90px;
          padding: 10px;
          display: flex;
          align-items: center;
          // padding-bottom: 8px;
          height: 40px;
          background: transparent;
          border: 1px solid #40a1dc;
          // font-weight: bold;
          font-size: 15px;
          // text-align: center;
          cursor: pointer;
          color: #fff;
          transition: 0.5s;
          img {
            width: 20px;
            margin-left: 5px;
          }
          @media screen and (max-width: 600px) {
            height: 45px;
            font-size: 15px;
          }

          &:hover {
            background: #40a1dc;
            transition: 0.9s;
          }
        }

        .btn2 {
          border-radius: 4px;
          padding: 10px;
          // width: 90px;
          // display: flex;
          height: 40px;
          background: #40a1dc;
          display: flex;
          align-items: center;
          // font-weight: bold;
          font-size: 15px;
          // text-align: center;
          border: none;
          cursor: pointer;
          color: #fff;
          transition: 0.5s;
          margin-left: 10px;
          span {
          }
          img {
            width: 20px;
            margin-left: 5px;
            // margin-top: 10px;
          }

          @media screen and (max-width: 600px) {
            height: 45px;
            font-size: 15px;
          }

          &:hover {
            transition: 0.5s;
            background: #1c587e;
            border: 1px solid #1c587e;
            color: #fff;
            border: 1px solid #1c587e;
          }
        }
      }
      .rightCard {
        width: 50%;
        margin: 2rem 0;

        // max-height: 500px;
        // min-height: 500px;
        // max-height: 610.71px;
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;
        background: #1b1e2f;
        padding: 2rem 2.5rem;
        display: flex;
        flex-direction: column;
        // align-self: center;

        // justify-content: flex-start;
        row-gap: 1.1rem;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 600px) {
          padding: 2rem 1.5rem 2rem 1.5rem;
        }
        .Dec {
          width: 100%;
          display: flex;
          justify-content: center;
          img {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            justify-content: center;
            width: 200px;
          }
          label {
            font-weight: 600;
            font-size: 22px;
            font-size: 500;
            margin-bottom: 20px;
            line-height: 32px;
            text-align: left;
            color: #40a1dc;

            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
          }

          p {
            font-weight: 300;
            font-size: 14px;
            line-height: 32px;
            text-align: left;
            color: #c8c8c8;

            a {
              color: #40a1dc;
              transition: 0.9s;

              &:hover {
                color: #2c739f;
                transition: 0.5s;
              }
            }
          }
        }
        .cardHeading {
          font-weight: bold;
          font-size: 25px;
          letter-spacing: 0.06em;
          line-height: 32px;
          text-align: left;
          color: #fcfcfc;

          @media screen and (max-width: 600px) {
            font-size: 25px;
          }
        }

        .paragraphWrapper {
          display: flex;
          column-gap: 2rem;

          @media screen and (max-width: 600px) {
            justify-content: space-between;
          }

          .leftSide {
            display: flex;
            flex: 0.5;
            flex-direction: column;
            row-gap: 0.5rem;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              text-align: left;
              color: #fff;

              @media screen and (max-width: 600px) {
                font-size: 15px;
              }
            }

            p {
              font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              text-align: left;
              color: #fff;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }

          .rightSide {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            flex: 0.5;

            label {
              font-size: 16px;
              line-height: 32px;
              text-align: left;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }

              img {
                width: 15px;
              }
            }

            p {
              font-size: 16px;
              line-height: 32px;
              text-align: left;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }
        }

        .secparagraphWrapper {
          display: flex;
          column-gap: 2rem;

          @media screen and (max-width: 600px) {
            justify-content: space-between;
          }

          .leftSide {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            flex: 0.5;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              text-align: left;
              color: #fff;

              @media screen and (max-width: 600px) {
                font-size: 15px;
              }
            }

            p {
              font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              text-align: left;
              color: #fff;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }

          .rightSide {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            flex: 0.5;

            label {
              // font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              text-align: left;
              // color: #fff;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }
            }

            p {
              font-size: 16px;
              line-height: 32px;
              text-align: left;

              @media screen and (max-width: 600px) {
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }
        }

        .labelWrapper {
          .bottomLabel {
            display: flex;
            justify-content: flex-end;
            font-weight: normal;
            font-size: 14px;
            text-align: left;
            color: #fff;
            opacity: 0.6;
          }
        }

        .buttonWrapper {
          display: flex;
          justify-content: end;

          .btn1 {
            border-radius: 4px;
            // width: 90px;
            padding: 10px;
            display: flex;
            align-items: center;
            // padding-bottom: 8px;
            height: 40px;
            background: transparent;
            border: 1px solid #40a1dc;
            // font-weight: bold;
            font-size: 15px;
            // text-align: center;
            cursor: pointer;
            color: #fff;
            transition: 0.5s;
            img {
              width: 20px;
              margin-left: 5px;
            }
            @media screen and (max-width: 600px) {
              height: 45px;
              font-size: 15px;
            }

            &:hover {
              background: #40a1dc;
              transition: 0.9s;
            }
          }

          .btn2 {
            border-radius: 4px;
            padding: 10px;
            // width: 90px;
            // display: flex;
            height: 40px;
            background: #40a1dc;
            display: flex;
            align-items: center;
            // font-weight: bold;
            font-size: 15px;
            // text-align: center;
            border: none;
            cursor: pointer;
            color: #fff;
            transition: 0.5s;
            margin-left: 10px;
            span {
            }
            img {
              width: 20px;
              margin-left: 5px;
              // margin-top: 10px;
            }

            @media screen and (max-width: 600px) {
              height: 45px;
              font-size: 15px;
            }

            &:hover {
              transition: 0.5s;
              background: #1c587e;
              border: 1px solid #1c587e;
              color: #fff;
              border: 1px solid #1c587e;
            }
          }
        }
      }
      .exchangeWrapper {
        width: 50%;
        margin-top: 32px;
        // max-height: 500px;
        // min-height: 500px;
        // max-height: 610.71px;
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;
        background: #21263b;
        padding: 2rem 2.5rem;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-self: center;
        justify-content: flex-start;
        row-gap: 1.1rem;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 600px) {
          padding: 2rem 1.5rem 2rem 1.5rem;
        }
        .userWrapper {
          display: flex;
          // padding-top: 3rem;
          justify-content: space-between;
          .userInfo {
            display: flex;
            flex-wrap: wrap;

            img {
              border-radius: 50%;
              width: 40px;
              height: 40px;
              margin-right: 10px;
              border: 2px solid #40a1dc;
            }
            span {
              font-size: 15px;
              font-weight: 500;
            }
            p {
              font-size: 13px;
            }
          }
          img {
            width: 40px;
            cursor: pointer;
          }
        }
        .textField {
          display: flex;
          width: 75%;
          margin-left: 3rem;
          margin-top: 20px;
          justify-content: center;
          flex-direction: column;
          padding-top: 1rem;
          @media screen and (max-width: 600px) {
            width: 100%;
            margin-left: 0rem;
          }
          form {
            display: flex;
            justify-content: center;
            flex-direction: column;

            // row-gap: 2rem;
            label {
              color: #a6a8b1;
              font-size: 12px;
              margin-bottom: 7px;
            }
            textarea {
              outline: none;
              font-family: Montserrat;
              background: #1b1e2f;
              display: flex;
              resize: none;
              justify-content: center;
              border-radius: 7px;
              // border-bottom: 1px solid rgba(255, 255, 255, 0.37) !important;
              color: white;
              border: none;
              // width: 75%;
              // height: 45.5px;
              padding: 1rem;
              margin-bottom: 7px;

              &::placeholder {
                color: #8d8f97 !important;
                // opacity: 1; /* Firefox */
              }
            }
            input {
              outline: none;
              font-family: Montserrat;
              background: #1b1e2f;
              display: flex;
              border-radius: 7px;
              justify-content: center;
              // border-bottom: 1px solid rgba(255, 255, 255, 0.37) !important;
              color: white;
              border: none;
              // width: 75%;
              height: 45.5px;
              padding: 1rem;
              margin-bottom: 7px;

              &::placeholder {
                color: #8d8f97 !important;
                // opacity: 1; /* Firefox */
              }
            }
            .buttonWrapper {
              display: flex;
              font-family: Montserrat;
              justify-content: end;
              .btn2 {
                padding-left: 30px;
                padding-right: 30px;
                margin-top: 30px;
                font-weight: normal;
                border-radius: 5px;
                background: #40a1dc;
                color: white;
                margin-left: 5px;
                font-size: 18px;
                border: none;
                cursor: pointer;

                &:hover {
                  background: #1c587e;
                  border: 1px solid #1c587e;
                  color: white;
                  transition: 0.9s;
                }
              }
              .btn1 {
                padding-left: 15px;
                padding-right: 15px;

                margin-top: 30px;
                font-weight: normal;
                border-radius: 5px;
                background: transparent;
                color: white;
                margin-left: 5px;
                font-size: 18px;
                border: 1px solid #40a1dc;
                cursor: pointer;

                &:hover {
                  background: #40a1dc;

                  color: white;
                  transition: 0.9s;
                }
              }
            }
          }
        }
      }
    }
  }
}
