.container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    .heading {
      width: 100%;
      padding: 20px 0px;
      p {
        font-weight: 500;
        font-size: 30px;
      }
    }
    .optionCard {
      width: 100%;
      height: 100px;
      padding: 0px 20px;
      background-color: #131a29;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .option {
        display: flex;
        align-items: center;
        .icon {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 20px;
          }
        }
        .text {
          margin-left: 10px;
          font-weight: 600;
        }
      }
      .arrow {
        display: flex;
        column-gap: 20px;
        margin-right: 10px;
      }
    }
    button {
      background: #cf0101;
      box-shadow: 0 6px 30px rgb(0 0 0 / 16%);
      padding-left: 20px;
      border: 0px;
      padding-right: 20px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-weight: 600;
      font-size: 15px;
      border-radius: 7px;
      color: white;
      cursor: pointer;
    }
  }
}
.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  align-items: center;
  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 0px 0px;
    p {
      font-size: 25px;
      color: #279af1;
    }
  }
  .inputfields {
    width: 70%;
    padding: 30px 0px;
  }
  button {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 70%;
    height: 40px;
    // padding: 10px 0px;
    outline: none;
    border: 1px solid #279af1;
    cursor: pointer;
    color: #fff;
    background-color: #279af1;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;
    &:hover {
      background-color: transparent;
      border: 1px solid #279af1;
    }
  }
}
.deletemodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  align-items: center;
  img {
    width: 70px;
    height: 70px;
  }
  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 0px 0px;
    p {
      font-size: 18px;
      margin-bottom: 5px;
      color: #d03739;
    }
  }

  button {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    height: 40px;
    // padding: 10px 0px;
    outline: none;
    border: 1px solid #d03739;
    cursor: pointer;
    color: #fff;
    background-color: #d03739;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;
    &:hover {
      background-color: transparent;
      border: 1px solid #d03739;
    }
  }
  button {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    height: 40px;
    // padding: 10px 0px;
    outline: none;
    border: 1px solid #ce2023;
    cursor: pointer;
    color: #fff;
    background-color: #ce2023;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;
    &:hover {
      background-color: transparent;
      border: 1px solid #ce2023;
    }
  }
  .cancelbutton {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    height: 40px;
    // padding: 10px 0px;
    outline: none;
    border: 1px solid white;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;
    &:hover {
      background-color: #ce2023;
      border: 1px solid #ce2023;
    }
  }
}
