.container {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  padding: 4rem 0rem 4rem 0rem;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
  .wrapper {
    width: 100%;
    max-width: 1750px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.158);
    .imgWrapper {
      width: 90%;
      display: grid;
      row-gap: 1.5rem;
      column-gap: 1.5rem;
      @media screen and (max-width: 1024px) {
        row-gap: 0.5rem;
        column-gap: 0.5rem;
      }
      .img1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .img2 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .img3 {
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
// @media only screen and (max-width: 600px) {
//   .container {
//     padding: 2rem 0rem 2rem 0rem;
//     .imgWrapper {
//       display: flex;
//       flex-direction: column !important;
//       row-gap: 2rem;

//       align-items: center !important;
//       img {
//         width: 300px !important;
//       }
//     }
//   }
// }
// @media only screen and (min-width: 600px) and (max-width: 1024px) {
//   .container {
//     padding: 2rem 0rem 2rem 0rem;
//     .imgWrapper {
//       display: flex;
//       flex-direction: column !important;
//       align-items: center !important;
//       row-gap: 2rem;
//       img {
//         width: 500px !important;
//       }
//     }
//   }
// }
// @media only screen and (min-width: 1024px) and (max-width: 1400px) {
//   .container {
//     flex-direction: row !important;
//     padding: 4rem 0rem 4rem 0rem;

//     .wrapper {
//       .imgWrapper {
//         column-gap: 2rem;
//         justify-content: center;
//         align-items: flex-start;
//         img {
//           width: 400px;
//         }
//       }
//     }
//   }
// }
