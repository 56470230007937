.heading {
  display: flex;
  flex-direction: row;
  // flex-direction: row-reverse;
  // align-self: center;
  justify-content: center;

  font-weight: bold;
  font-size: 3rem;
  line-height: 65px;
  text-align: left;
  color: #fff;
  margin-bottom: 2rem;

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 2rem;
  }

  span {
    color: #40a1dc;
    margin-left: 1rem;
  }
}

.container {
  width: 100%;
  padding: 0rem 0rem 0rem 0rem;
  justify-content: center;
  overflow: hidden;
  // align-self: center;
  display: flex;
  box-sizing: border-box;

  .Wrapper {
    width: 100%;
    max-width: 1950px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .leftCard {
      width: 100%;
      display: flex;
      margin-left: 3rem;
      justify-content: space-between;
      align-self: center;
      flex-direction: row-reverse;

      box-sizing: border-box;
      padding: 0rem 5rem 0rem 0rem;
      align-items: center;

      background-color: red;
    }

    .firstCard {
      animation-name: left;
      animation-duration: 5s;
      align-content: center;
      justify-content: left;
      margin-bottom: 4rem;
    }

    .secCard {
      display: flex;
      margin-bottom: 0rem;

      animation-name: right;
      animation-duration: 5s;
    }

    .righCard {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-self: center;
      flex-direction: row;

      box-sizing: border-box;
      padding: 0rem 0rem 0rem 5rem;
      align-items: center;
    }

    .secCard {
      display: flex;

      @media screen and (min-width: 1024px) {
        margin-right: 3rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .Wrapper {
      flex-direction: column;

      .leftCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .righCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .secCard {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .Wrapper {
      flex-direction: column;

      .firstCard {
        margin: 0 1rem;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1180px) {
  .container {
    .Wrapper {
      flex-direction: column;

      .firstCard {
        margin: 0 5rem;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    .Wrapper {
      flex-direction: column;

      .leftCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .righCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .secCard {
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1180px) {
  .container {
    .Wrapper {
      flex-direction: column;

      .leftCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .righCard {
        flex-direction: column;
        padding: 0rem 2rem 0rem 2rem;
      }

      .secCard {
        margin-top: 5rem;
        display: flex;
      }
    }
  }
}

@keyframes left {
  0% {
    transform: translateX(-50vw);
  }

  100% {
    transform: translateX(50);
  }
}

@keyframes right {
  0% {
    transform: translateX(50vw);
  }

  100% {
    transform: translateX(50);
  }
}
