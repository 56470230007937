.container {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    max-width: 1550px;
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 1rem;
    margin: 0 1rem;

    .headingWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      .heading {
        font-size: 3rem;
        font-weight: bold;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
      .subHeading {
        font-size: 1.5rem;
        @media screen and (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }
  }
}
