.container {
  width: 100%;
  .wrapper {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .mainBox {
      // border: 1px solid red;
      width: 50%;
      min-height: 50vh;
      background-color: #1f222f;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 30px;
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
        rgba(178, 196, 212, 0.982) 0px 18px 36px -18px inset;
      // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      .heading {
        font-weight: 600;
        font-size: 25px;
      }
      .buttons {
        width: 60%;
        display: flex;
        column-gap: 10px;

        button {
          border-radius: 10px;

          width: 50%;
          height: 40px;
          color: white;
          background-color: rgb(64 158 220);
          border: none;
          font-size: 16px;
          outline: none;
          transition: all 0.5s;
          &:hover {
            cursor: pointer;
            background-color: #1f222f;
            columns: rgb(64 158 220);
            border: 1px solid rgb(64 158 220);
            transition:all 0.5s;
          }
        }
      }
    }
  }
}
