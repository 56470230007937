.container {
  width: 100%;
  text-align: center;
  // padding: 40px;
  margin: 0rem 0;
  .loaderPosition {
    display: flex;
    height: 30vh;
    align-items: center;
  }
  p {
    // text-align: start;
    font-size: 30px;
    @media screen and (max-width: 624px) {
      font-size: 22px;
    }
  }
  .wrapper {
    height: 500px;
    overflow: auto;
    // display: flex;
    // justify-content: center;
    width: 100%;

    .noData {
      width: 200px;

      margin-top: 50px;
    }
    table {
      width: 100%;
      padding-right: 10px;
      overflow-x: auto;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 25px;

      tr {
        // height: 80px;

        td {
          background: #191c2c;
          padding: 1rem;
          white-space: nowrap;
          text-align: center;

          .tabelWrapper {
            display: flex;
            align-items: center;
            column-gap: 2rem;
            text-align: left;

            .innerWrapper {
              span {
                font-weight: normal;
                font-size: 16px;
                color: #ffffff;
              }
            }
          }

          .bottomtabelWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            column-gap: 2rem;
            text-align: right;
            .imgWithName {
              display: flex;
              column-gap: 5px;
            }
            img {
              width: 20px;
              height: 20px;
            }

            span {
              font-weight: normal;
              font-size: 16px;
              color: #fcfcfd;
            }
          }

          &:nth-child(2) {
            justify-content: flex-end;
            display: flex;
            // height: 80px;
            text-align: right !important;
            // padding-right: 22px;
          }
        }
      }

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
}
