.container {
  width: 100%;
  overflow: auto;
  margin: 20px 0;
  max-height: 500px;
  .wrapper {
    
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .heading {
      padding-top: 1rem;
      font-weight: bold;
      font-size: 26px;
      letter-spacing: 0.02em;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      label {
        font-weight: bold;
        font-size: 22px;
        text-align: left;
        color: #fff;
      }

      p {
        font-weight: 300;
        font-size: 15px;
        line-height: 32px;
        text-align: left;
        color: #fff;
      }
    }

    .btn {
      border-radius: 8px;
      width: 100%;
      height: 49px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      border: none;
      cursor: pointer;
      color: #fff;
      transition: 0.5s;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        height: 45px;
        font-size: 15px;
      }

      &:hover {
        background: transparent;
        border: 1px solid #40a1dc;
        transition: 0.9s;
      }
    }

    .loadingBtn {
      border-radius: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 49px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      border: none;
      cursor: pointer;
      color: #fff;
      transition: 0.5s;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        height: 45px;
        font-size: 15px;
      }

      &:hover {
        background: transparent;
        border: 1px solid #40a1dc;
        transition: 0.9s;
      }
    }
  }
}