.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1750px;
    width: 100%;

    .loaderPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
    }

    .addbutton {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .backWrapper {
        display: flex;
        gap: 3;
        img {
          width: 11px;
        }
        label {
          font-size: 25px;
          margin-left: 20px;
          @media screen and (max-width: 600px) {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      img {
        max-width: 2rem;
        cursor: pointer;
      }
    }

    .tableWrapper {
      margin-top: 20px;
      overflow-x: auto;
      width: 100%;

      text-align: left;
      .noDataWrapper {
        margin-top: 20px;
      }
      .emptyImg {
        position: absolute;
        top: 30%;
        left: 40%;
        bottom: 30%;
      }
      table {
        width: 100%;
        // border-collapse: collapse;
        text-align: left;
        border-collapse: separate;
        border-spacing: 0px 15px;
        tr:not(:first-child):hover {
          transition: 0.9s;
          box-shadow: inset -1290px 0 0 0 #212642, inset 1290px 0 0 0 #212642;
        }
        tr {
          background-color: #1b1e2f;
          cursor: pointer;

          td {
            padding: 20px;
            .icon {
              cursor: pointer;
            }
          }
          td:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          th {
            color: #8e909c;
            font-weight: 400;
            font-size: 15px;
            padding: 20px;
          }
          th:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          th:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;

    p {
      font-size: 18px;
      color: #fff;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #be2539;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #800b1a;

        transition: 0.5s;
      }

      &:nth-last-child(2) {
        background: #40a1dc;

        &:hover {
          background: #11537c;
          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
