//Mobile Query Starts here
.mainmobileNav {
  display: none;
}
@media screen and (max-width: 1023px) {
  .mainmobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 25px 30px 10px 30px;
    .logo {
      border: 0;
      justify-content: left;
      display: flex;
      align-items: center;
      padding: 0px;
      display: block;
      cursor: pointer;
      height: 30px;
    }
    .menuItem {
      position: relative;

      a {
        &:hover {
          color: #40a1dc;
        }
      }
      button {
        width: 180px;
        height: 65px;
        border-radius: 12px;
        background: #40a1dc;
        outline: none;
        border: 1px solid #40a1dc;
        cursor: pointer;
        font-weight: normal;
        border-radius: 5px;
        transition: 1s;
        color: #fff;
        &:hover {
          background-color: transparent;
          color: #40a1dc;
        }
      }
    }

    .RightWrapper {
      display: flex;
      column-gap: 15px;
      align-items: center;
      .navbtn {
        display: block;
        img {
          height: 35px;
        }
      }
    }
  }
}
.listitem {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
//Mobile Query Ends here

//Desktop Query Stats Here
.container {
  display: none;
}
.shows {
  display: none;
}
@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;
    background-color: transparent;

    .wrapper {
      width: 90%;
      max-width: 1750px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftsection {
        width: 40%;
        padding: 10px 0px 0px 0px;
        .logo {
          max-width: 167px;
          height: 50px;
        }
      }
      .rightsection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 33%;
        .menuItem {
          position: relative;

          a {
            &:hover {
              color: #40a1dc;
            }
          }

          button {
            padding: 0.7rem 2rem;
            background-color: #40a1dc;
            outline: none;
            border: 1px solid #40a1dc;
            // align-items: right;
            // border: 1px solid red;
            cursor: pointer;
            font-weight: normal;
            border-radius: 5px;
            transition: 0.5s;
            font-size: 14px;
            color: #fff;
            &:hover {
              transition: 0.5s;
              background: #1c587e;
              border: 1px solid #1c587e;
              color: #fff;
              border: 1px solid #1c587e;
            }
          }
        }
        .active {
          color: #40a1dc;
        }
      }
    }
  }
  .shows {
    width: 100%;
    display: flex;
    align-items: center;

    justify-content: space-between;
    // padding: 1px 0px;
    height: 5rem;
    background-color: rgb(32 35 47);
    // backdrop-filter: blur(5px);
    z-index: 1;
    // display: block;

    // position: sticky;
    // top: 0;

    position: sticky;
    top: -1px;
    @media screen and (max-width: "1023px") {
      position: unset;
      top: auto;
    }
    p {
      text-align: left;
      margin-left: 20px;

      // margin-left: 10px;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    span {
      margin-left: 10px;
      cursor: pointer;
    }
    .email {
      text-align: center;
      margin-left: 20px;

      white-space: nowrap;
    }
    .RightWrapper {
      display: flex;
      margin-right: 40px;
      align-items: center;
      .search {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-right: 1rem;
      }
      .profilediv {
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0px;
        row-gap: 10px;
        cursor: pointer;

        .profile {
          width: 45px;
          height: 45px;
          max-width: 50px;
          max-height: 50px;
          border-radius: 100%;
          position: relative;
          background-color: white;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .img2 {
            width: 45px;
            height: 45px;
            border-radius: 100%;
            // background-size: 100% 100%;
            overflow: hidden;
            object-fit: cover;
          }

          .img1 {
            width: 20px;
            height: 20px;
            text-align: center;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;

            @-webkit-keyframes spin {
              0% {
                -webkit-transform: rotate(0deg);
              }

              100% {
                -webkit-transform: rotate(360deg);
              }
            }

            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }

          .editprofile {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            text-align: center;
            background-color: #000000;
            width: 20px;
            height: 20px;
            position: absolute;
            right: -5px;
            bottom: -5px;
            border: 2px solid #fff;
            background-color: #40a1dc;

            input {
              display: none;
            }

            .selectimage {
              width: 100%;
              height: 100%;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                // padding-bottom: 1px;

                @media screen and (max-width: 1024px) {
                  position: absolute;
                  top: 1px;
                  right: 0px;
                }
              }
            }
          }
        }

        .showmail {
          width: 100%;
          // max-width: 12rem;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;

          .textElllip {
            white-space: nowrap;
            width: 14rem;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }

          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: 14px;
            color: #fff;
            opacity: 0.6;
            padding: 0 1rem;
            word-break: break-word;

            @media screen and (max-width: 1024px) {
              font-size: 10px;
            }

            &:hover {
              color: #40a1dc;
              opacity: 1;
            }
          }
        }
      }
      img {
        width: 40px;
        height: 40px;

        cursor: pointer;
        border-radius: 50%;
      }
      p {
        cursor: pointer;
      }
    }
  }
}
//Desktop Query Ends here
