.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem;
  .wrapper {
    max-width: 1500px;
    .headingWrapper {
      padding: 2rem 2rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      row-gap: 1rem;
      .heading {
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: #fff;
      }
      .subHeading {
        font-weight: 300;
        font-size: 30px;
        line-height: 44px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .subHeading {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .heading {
    font-size: 27px !important;
    line-height: 40px !important;
  }
  .subHeading {
    font-size: 20px !important;
    line-height: 30px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .heading {
    font-size: 35px !important;
    line-height: 55px !important;
  }
  .subHeading {
    font-size: 25px !important;
    line-height: 38px !important;
  }
}
