.container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: auto;

  .wrapper {
    max-width: 1750px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .noDataWrapper {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      img {
        width: 100%;
      }
    }
    img {
      width: 30%;
    }

    .loaderPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
    }
    .addbutton {
      width: 85%;
      display: flex;
      justify-content: flex-end;
      img {
        max-width: 2rem;
        cursor: pointer;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 1rem;
      position: relative;

      .emptyImg {
        position: absolute;
        top: 30%;
        left: 40%;
        bottom: 30%;
      }

      tr {
        text-align: left;
        margin-bottom: 30px;

        td {
          padding: 0 9px;

          .nameWrapper {
            display: flex;
            column-gap: 1rem;
            justify-content: flex-start;
            text-align: left;
            align-items: center;
            .green {
              color: rgb(106, 205, 106);
            }
            .red {
              color: rgb(180, 41, 41);
            }
            .purple {
              color: rgb(207, 180, 61);
            }
            img {
              // background: #fff;
              width: 50px;
              height: 50px;
              border-radius: 5px;
            }
          }
          .estApyWrapper {
            img1 {
              // filter: invert(100%) sepia(54%) saturate(570%) hue-rotate(206deg) brightness(118%) contrast(108%);
              // border: 1px solid red;
            }
            .img2 {
              filter: invert(45%) sepia(99%) saturate(513%) hue-rotate(142deg)
                brightness(100%) contrast(98%);
            }
          }
          .firstimage {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            img {
              width: 35px;
            }

            label {
              color: rgba(255, 255, 255, 0.514);
            }
          }

          .bottomimage {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;
          }
        }
      }

      td:first-child {
        padding-left: 2rem;
        padding: 1rem 1rem;
      }

      tr:first-child {
        font-weight: 400;
        height: 18px;
        border-radius: 5px;
      }

      tr:not(:first-child) {
        cursor: pointer;
        font-weight: 500;
        height: 75px;
        border-radius: 5px;
        background: #1b1e2f;

        .description {
          font-weight: normal;
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          margin-top: 10px;
        }
        .changedDescription {
          font-size: 12px;
          margin-top: 10px;
          font-weight: normal;
          width: 350px;
        }
        :nth-child(7) {
          :not(:first-child) {
            color: rgba(255, 255, 255, 0.438);

            padding-left: 2.1rem;
          }
        }
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;
  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 0px 0px;
    p {
      font-size: 25px;
      color: #279af1;
    }
  }
  .inputfields {
    width: 70%;
    padding: 30px 0px;
  }
  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
  }
  .termAndCondition {
    margin: 1rem 0;
  }
  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;
    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;
      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
