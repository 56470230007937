.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .heading {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .textsection {
    width: 80%;
    text-align: center;
    line-height: 3rem;

    p {
      font-size: 20px;
      text-transform: capitalize;
    }
    .btn {
     
      width: 50%;
      height: 40px;
      background-color: #40a1dc;
      border-radius: 10px;
      border: none;
      color: white;
      font-weight: 400;
    transition: 0.5s;

      &:hover {
      //  background-color: #0c5c8d;
       border: 2px solid #0c5f93;
       background-color: transparent;
       cursor: pointer;
      //  font-weight: bold;
    transition: 0.7s;
      }
      
    }
  }
}
