.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px 30px 0px;

    .backbutton {
      width: 100%;
      padding: 10px 0px;

      img {
        max-width: 20px;
        cursor: pointer;
      }
    }

    .detailsection {
      width: 100%;

      .mainbody {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        .heading {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 10px;

          p {
            font-size: 30px;
          }

          label {
            font-size: 12px;
            opacity: 0.5;
          }

          span {
            font-size: 25px;
          }
        }

        .formbody {
          width: 85%;
          display: flex;
          flex-direction: column;
          row-gap: 30px;
          padding: 30px 0px;
          row-gap: 10px;

          .inputfield {
            width: 100%;

            p {
              font-size: 12px;
              opacity: 0.5;
            }

            .field {
              margin-top: 10px;
              width: 100%;
              display: flex;
              justify-content: space-around;
              border-radius: 5px;
              border: 1px solid #34afba;

              input {
                width: 80%;
                height: 50px;
                background-color: transparent;
                outline: none;
                border: none;
                border-radius: 5px;
                color: #fff;

                &::placeholder {
                  font-size: 15px;
                  color: #fff;
                }
              }

              img {
                cursor: pointer;
                width: 23px;
                max-width: 30px;
              }
            }

            button {
              width: 100%;
              height: 40px;
              background-color: #40a1dc;
              border: 1px solid #3b94cb;
              outline: none;

              font-weight: 600;
              font-size: 15px;
              border-radius: 5px;
              transition: 0.5s;
              cursor: pointer;

              &:hover {
                background-color: transparent;
                border: 1px solid #3b94cb;
              }
            }

            .fields {
              margin-top: 10px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-around;
              border-radius: 5px;

              input {
                width: 10%;
                height: 50px;
                background-color: transparent;
                outline: none;
                border: none;
                border-bottom: 1px solid #fff;

                color: #fff;

                &::placeholder {
                  font-size: 15px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .inputfield {
      width: 85%;

      p {
        font-size: 12px;
        opacity: 0.5;
      }

      .field {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        border-radius: 5px;
        border: 1px solid #34afba;

        input {
          width: 80%;
          height: 50px;
          background-color: transparent;
          outline: none;
          border: none;
          border-radius: 5px;
          color: #fff;

          &::placeholder {
            font-size: 15px;
            color: #fff;
          }
        }

        img {
          cursor: pointer;
          width: 23px;
          max-width: 30px;
        }
      }

      button {
        width: 100%;
        height: 40px;
        background-color: #40a1dc;
        border: 1px solid #3b94cb;
        outline: none;

        font-weight: 600;
        font-size: 15px;
        border-radius: 5px;
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          background-color: transparent;
          border: 1px solid #3b94cb;
        }
      }
    }
  }
}