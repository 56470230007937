.container {
  // width: 70%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  @media screen and (max-width: "600px") {
    width: 98%;
    // height: auto;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    row-gap: 1rem;
    // background-color: #1b1e2f46;
    padding: 20px 20px;
    border-radius: 20px;
    min-height: 75vh;
    @media screen and (max-width: 624px) {
      padding: 0px 0px;
    }
    .cardWraper {
      display: flex;
      flex-direction: row;
      row-gap: 1.5rem;
      column-gap: 1.5rem;
      @media screen and (max-width: 924px) {
        flex-direction: column;
      }
      .cardImg {
        width: 50%;
        // text-align: center;
        align-items: center;
        padding: 0px 0 0 20px;
        @media screen and (max-width: 924px) {
          width: 100%;
          padding: 0px;
        }
        .bactTo {
          margin-top: -60px;
          width: 35px;
          margin-bottom: 15px;
          cursor: pointer;
          @media screen and (max-width: 924px) {
            margin: 0px;
          }
        }

        label {
          font-size: 40px;
          display: block;
          // text-align: center;
          font-weight: bold;
          margin-bottom: 20px;

          img {
            width: 30px;
          }
        }

        .text {
          font-size: 17px;
          display: block;
          text-align: justify;
          width: 90%;

          width: 100%;
          img {
            width: 14px;
            text-align: center;
          }
          @media screen and (max-width: 1024px) {
            font-size: 18px;
            // width: 90%;
          }
        }

        .btnBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          width: 100%;
          height: 50px;
          outline: none;
          border: none;
          border-radius: 10px;
          background: #40a1dc;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          transition: 0.3s;
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          margin-top: 10px;
          color: #fff;

          @media screen and (max-width: 1024px) {
            width: 100%;
            align-self: flex-start;
            height: 50px;
            font-size: 18px;
          }

          &:hover {
            transition: 0.5s;
            background: transparent;
            border: 1px solid #40a1dc;
            color: #40a1dc;
          }
        }

        form {
          width: 95%;
          display: flex;
          flex-direction: column;
          row-gap: 3px;
          label {
            color: #a0a1a7;
            font-size: 18px;
            margin-bottom: 0px;
          }
          .btnBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            width: 100%;
            height: 55px;
            outline: none;
            border: none;
            border-radius: 10px;
            background: #40a1dc;
            box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
            transition: 0.3s;
            cursor: pointer;
            font-weight: 600;
            font-size: 20px;
            margin-top: 10px;
            color: #fff;

            @media screen and (max-width: 1024px) {
              width: 100%;
              align-self: flex-start;
              height: 50px;
              font-size: 18px;
            }

            &:hover {
              transition: 0.5s;
              background: transparent;
              border: 1px solid #40a1dc;
              color: #40a1dc;
            }
          }
        }
      }
      .card {
        width: 50%;
        margin-top: 20px;
        // text-align: center;
        align-items: center;
        padding: 0px 0 0 40px;
        @media screen and (max-width: 924px) {
          width: 100%;
          padding: 0px;
        }

        label {
          font-size: 40px;
          display: block;
          // text-align: center;
          font-weight: bold;
          margin-bottom: 20px;

          img {
            width: 30px;
          }
        }

        .text {
          font-size: 17px;
          display: block;
          text-align: justify;
          width: 90%;

          width: 100%;
          img {
            width: 14px;
            text-align: center;
          }
          @media screen and (max-width: 1024px) {
            font-size: 18px;
            // width: 90%;
          }
        }

        .btnBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          width: 100%;
          height: 50px;
          outline: none;
          border: none;
          border-radius: 10px;
          background: #40a1dc;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          transition: 0.9s;
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          margin-top: 10px;
          color: #fff;

          @media screen and (max-width: 1024px) {
            width: 100%;
            align-self: flex-start;
            height: 50px;
            font-size: 18px;
          }
          &:hover {
            transition: 0.9s;
            background: #1c587e;
            border: 1px solid #1c587e;
            color: #fff;
            border: 1px solid #40a1dc;
          }
        }

        form {
          width: 95%;
          display: flex;
          flex-direction: column;
          row-gap: 3px;
          label {
            color: #a0a1a7;
            font-size: 18px;
            margin-bottom: 0px;
          }
          .btnBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            width: 100%;
            height: 55px;
            outline: none;
            border: none;
            border-radius: 10px;
            background: #40a1dc;
            box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
            transition: 0.5s;
            cursor: pointer;
            font-weight: 600;
            font-size: 20px;
            margin-top: 10px;
            color: #fff;

            @media screen and (max-width: 1024px) {
              width: 100%;
              align-self: flex-start;
              height: 50px;
              font-size: 18px;
            }
            &:hover {
              transition: 0.5s;
              background: #1c587e;
              border: 1px solid #1c587e;
              color: #fff;
              border: 1px solid #1c587e;
            }
          }
        }
      }
    }
    .QSymbolImg {
      img {
        width: 12px;
      }
    }
  }
}
