.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0rem 2.1rem 1rem 2.1rem;
  box-sizing: border-box;
  overflow: auto;

  .filtersWrapper {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      width: 80px;
    }
    .inputSearch {
      background-color: #1b1e2f;
      border: none;
      border-radius: 10px;
      margin-right: 10px;
      height: 40px;
      margin-top: 10px;
      outline: none;
      padding: 10px;
      color: white;
      border: 1px solid #40a1dc;
      @media screen and (max-width: 1025px) {
        margin-top: 30px;
      }
    }
    .btnWrapper {
      display: flex;
      flex-wrap: wrap;
      // z-index: -1000;

      // z-index: 0;
      // .sorting {
      //   width: 130px;
      //   @media screen and(max-width:624px) {
      //     width: 90px;
      //   }
      // }

      .filter {
        width: 130px;
        margin-right: 20px;

        @media screen and (max-width: 624px) {
          width: 90px;
        }
      }

      .btn2 {
        outline: none;
        margin-top: 10px;
        margin-right: 130px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        // margin-left: 10px;
        height: 42px;
        border-radius: 8px;
        background: transparent;
        border: 1px solid #40a1dc;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: 0.5s;
        @media screen and (max-width: 1020px) {
          margin-right: 20px;
          margin-top: 30px;
        }
        &:hover {
          transition: 0.9s;
          box-shadow: inset -90px 0 0 0 #40a1dc, inset 90px 0 0 0 #40a1dc;
          color: white;
        }
      }
    }
  }

  .pageHeight {
    // min-height: 65vh;
    .loaderPositionWave {
      display: flex;
      justify-content: center;
      height: 70vh;
    }
    .noDataPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
      img {
        width: 200px;
        object-fit: contain;
      }
    }

    .cardWrapper {
      display: flex;

      column-gap: 1rem;
      row-gap: 2rem;
      padding: 5px;
      flex-wrap: wrap;
      justify-content: center;

      .loaderPosition {
        display: flex;
        justify-content: center;
        height: 70vh;
        img {
          width: 50%;
          object-fit: contain;
        }
      }

      .card {
        margin-top: 2rem;
        min-width: 250px;
        padding: 10px;
        max-width: 280px;
        height: 470px;
        position: relative;
        // height: auto;
        border-radius: 10px;
        background: #1b1e2f;
        display: flex;
        flex-direction: column;
        // justify-content: center;

        .imgWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          //   padding: 5px;
          img {
            // max-width: 280px;
            width: 100%;
            align-items: center;
            // height: 190px;
            border-radius: 6px;
          }
          // .img1 {
          //   height: 200px;
          //   padding: 0 1rem;
          //   cursor: pointer;
          //   text-align: center;
          // }
        }
        .userWrapper {
          display: flex;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 15px;
          .userimg {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 5px;
            border: 2px solid #40a1dc;
          }
          span {
            font-size: 15px;
            font-weight: 600;
          }
          label {
            font-size: 13px;
            // min-height: 2.5rem;
            display: block;
            word-break: break-all;
          }
        }

        .cardHeading {
          padding-top: 0.6rem;
          padding-left: 0.3rem;
          padding-right: 0.3rem;

          width: 150px;

          font-weight: bold;
          font-size: 20px;
          letter-spacing: 0.01em;
          line-height: 32px;
          text-align: left;
          color: #fcfcfc;
        }

        .paragraphWrapper {
          padding-bottom: 1rem;
          padding-top: 0.6rem;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          display: flex;
          justify-content: space-between;

          .leftSide {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 25px;
              text-align: left;
              color: #fff;
              white-space: nowrap;
            }

            p {
              font-weight: 500;
              font-size: 14px;
              margin-top: 5px;
              text-align: left;
              color: #fff;
              white-space: nowrap;
            }
          }

          .rightSide {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            span {
              text-align: right;
              color: #fff;

              img {
                width: 12%;
              }
            }

            p {
              font-size: 14px;
              margin-top: 5px;
              text-align: right;
              color: #fff;
            }
          }
        }

        .btn {
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          outline: none;
          position: absolute;
          bottom: 9px;
          // right: 5px;
          // left: 5px;
          // right: 20px;
          border: none;
          width: 95%;
          height: 49px;
          border-radius: 8px;
          background: #40a1dc;

          font-size: 18px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          transition: 0.5s;

          &:hover {
            transition: 0.5s;
            background: #1c587e;
            color: white;
          }
        }
      }
    }
  }
}
