.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  // min-height: 89vh;
  // overflow-y: auto;
  .wrapper {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-self: center;
    // background: #161824;
    border-radius: 16px;
    // @media screen and (max-width: 1024px) {
    //   width: 90%;
    // }
    .cardwrapper {
      box-sizing: border-box;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;

      .iconImg {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px 0px 0px 0px;

        img {
          text-align: left;
          height: 20px;
          cursor: pointer;
        }
        p {
          width: 100%;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          color: #fff;
        }
        label {
          width: 100%;
          font-weight: 600;
          font-size: 30px;
          text-align: center;
          color: #fff;
        }
      }
      .labelWrapper {
        justify-content: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin: 2rem 0rem;
        padding: 0 0;
        span {
          font-weight: 300;
          font-size: 20px;
          text-align: left;
          color: #a4a4a4;
        }
        .Selection {
          display: flex;
          flex-direction: column;
          width: 100%;
          @media screen and (max-width: 1024px) {
            width: 100%;

            align-self: center;
          }

          img {
            height: 30px;
            width: 30px;
          }
        }

        img {
          height: 100px;
        }
        .topLabel {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          span {
            font-weight: 300;
            font-size: 20px;
            text-align: left;
            color: #a4a4a4;
          }

          .inputWrapper {
            width: 100%;
            height: 60px;
            border-radius: 12px;
            background: #1b1e2f;
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-self: center;
            padding: 0 1rem;
            .textWrapper {
              overflow: hidden;
              // text-overflow: ellipsis;
              width: 300px;
            }
            img {
              width: 30px;
              cursor: pointer;
              @media screen and (max-width: 1024px) {
                width: 20px;
              }
            }
          }
        }

        .btn2 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 20%;
            height: 40px;
            border-radius: 4px;
            background: #1b1e2f;
            // background-color: transparent;
            cursor: pointer;
            &:nth-child(3) {
              background: #40a1dc;
              color: white;
              border-radius: 4px;
            }
            &:focus-within {
              background: lightyellow;
            }
          }
        }
        .bottomLabel {
          .p3 {
            font-weight: 500;
            font-size: 15px;
            text-align: right;
            color: #a4a4a4;
          }
        }
        .btn3 {
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          color: #fff;
          border: none;
          outline: none;
          border-radius: 10px;
          cursor: pointer;
          transition: 1.3s;
          width: 100%;
          height: 60px;
          background: #40a1dc;
          filter: drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.16));
          &:hover {
            transition: 0.8s;
            color: white;
            background: transparent;
            border: 1px solid #a4a4a4;
          }
        }
      }
    }
  }
}
.usdwrapper {
  display: flex;
  justify-content: end;
  width: 100%;
  p {
    font-size: 12px;
    margin-top: 7px;
    margin-right: 3px;
  }
  label {
    font-size: 15px;
    margin-top: 5px;
  }
}
