.container {
  width: 100%;
  height: auto;
  .wrapper {
    width: 100%;
    .heading {
      width: 100%;
      padding: 30px 0px;
      p {
        font-size: 25px;
        font-weight: 500;
      }
    }
    .text {
      width: 100%;
      max-width: 900px;
      p {
        text-align: left;
        line-height: 2rem;
      }
    }
    .selectionarea {
      max-width: 400px;
      padding: 30px 0px;
      p {
        color: #a4a4a4;
      }
    }
  }
}
