.container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  .mainHeading {
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }
  .rowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    .leftWrapper {
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.02em;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }
    .rightWrapper {
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.02em;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }
    .Completed {
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.02em;
      line-height: 24px;
      text-align: center;
      color: #37cc69;
    }
  }
}
