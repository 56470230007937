.marqueWrapper {
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 50px 0px 50px;
    color: #fff;
    .heading {
      p {
        font-weight: 500;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
      }
    }
    .subHeading {
      padding: 10px 0px;
      p {
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        max-width: 400px;
      }
    }
  }
  .cardContainer {
    display: flex;
    margin: 50px 30px 100px 30px;
    row-gap: 50px;
    column-gap: 50px;
    .testiMonial {
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      width: 250px;
      height: 200px;
      border-radius: 10px;
      border: 1px solid #090631;
      background-color: #fff;
      justify-content: space-around;
      color: black;
      .position1 {
        display: flex;
        align-items: flex-start !important;
        flex-direction: column;
        .column {
          p {
            font-size: 12px !important;
            padding-top: 20px;
          }
        }
      }
      .imgsection {
        width: 70%;
        column-gap: 10px;
        display: flex;
        align-items: center;
        img {
          border-radius: 50%;
          max-width: 30%;
        }
        .textColor {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          label {
            font-size: 12px;
            font-weight: 500;
          }
          span {
            font-size: 8px;
          }
        }
      }
    }
    .testiMonial:nth-child(3) {
      background-color: #40a1dc;
      color: #fff;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .marqueWrapper {
    width: 100%;
    .container {
      .heading {
        padding: 10px 0px;
        p {
          font-size: 50px;
        }
      }
      .subHeading {
        p {
          margin-top: 10px;
        }
      }
    }
    .cardContainer {
      .testiMonial {
        .position1 {
          p {
          }
        }
      }
      .imgsection {
        img {
        }
        .textColor {
        }
      }
    }
  }
}
