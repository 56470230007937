.container {
  width: 100%;
  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
    .textWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 0;

      label {
        font-weight: bold;
        font-size: 23px;
        letter-spacing: 0.02em;
        line-height: 24px;
        text-align: center;
        color: #fff;
        margin: 0.5rem 0 1rem 0;
        @media screen and (max-width: 600px) {
          font-size: 19px;
        }
      }
      p {
        font-size: 18px;
        letter-spacing: 0.02em;
        line-height: 24px;
        text-align: center;
        color: #b9b3b3;
        margin: 0rem 0 1rem 0;
      }
      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
        .button {
          width: 80%;
          height: 70px;
          border-radius: 10px;
          background: #202336;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          color: white;
          border: none;
          outline: none;
          font-weight: normal;
          font-size: 22px;
          letter-spacing: 0.02em;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            font-size: 15px;
            height: 60px;
          }
          @media screen and (min-width: 600px) and (max-width: 750px) {
            font-size: 15px;
          }
          img {
            height: 15px;
            text-align: center;
            @media screen and (min-width: 600px) and (max-width: 750px) {
              height: 14px;
            }
            @media screen and (max-width: 600px) {
              height: 12px !important;
              width: 12px !important;
            }
          }
        }
      }
    }
  }
}
