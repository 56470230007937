.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  .wrapper {
    width: 90%;
    border-radius: 10px;
    background-color: #4286b3;
    display: flex;
    flex-direction: column;
    height: auto;
    .leftsection {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      padding: 2rem 0rem;
      label {
        font-size: 35px;
        font-weight: 500;
        line-height: 3rem;
        text-align: center;
        span {
          color: #000000;
          font-weight: 500;
        }
      }
      p {
        line-height: 1.5rem;
        font-size: 13px;
        text-align: center;
      }
      .btndiv {
        width: 100%;
        max-width: 250px;
        display: flex;
        column-gap: 20px;
        align-self: center;
        a {
          width: 50%;

          img {
            width: 100%;
            height: 50px;

            cursor: pointer;
          }
        }

        img {
          width: 50%;

          cursor: pointer;
        }
      }
    }
    .rightsection {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        max-width: 80%;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container {
    .wrapper {
      flex-direction: row;
      justify-content: space-evenly;
      height: auto;
      .leftsection {
        width: 45%;
        justify-content: center;
        padding-left: 20px;
        row-gap: 40px;
        label {
          max-width: 460px;
          font-size: 45px;
          line-height: 2.7rem;
          text-align: left;
        }
        p {
          font-size: 20px;
          text-align: left;
          line-height: 2rem;
        }
        .btndiv {
          align-self: flex-start;
        }
      }
      .rightsection {
        width: 45%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        img {
          position: absolute;
          bottom: 0;
          max-width: 100%;
          max-height: 110%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    .wrapper {
      .leftsection {
        width: 45%;
        justify-content: center;
        padding-left: 20px;
        row-gap: 15px;
        label {
          max-width: 460px;
          font-size: 25px;
          line-height: 2.7rem;
          text-align: left;
        }
      }
    }
  }
}
