.Card {
  width: 80%;
  display: flex;
  align-self: center;
  background: #1b1e2f;
  padding: 5rem;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 5px #2c314b, 0 0 10px #2a2e42;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 1rem;
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    row-gap: 2rem;
    .imgWrapper {
      width: 20%;
      display: flex;
      justify-content: center;
      img {
        height: 150px;
        // padding: 1rem;
        @media screen and (max-width: 600px) {
          height: 80px;
          margin-right: 1rem;
        }
      }
    }
    span {
      font-size: 20px;
      // padding-right: 1rem;
      text-align: center;
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        width: 40%;
        height: 40px;
        outline: none;
        border: none;
        border-radius: 10px;
        background: #40a1dc;
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
        transition: 0.3s;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        transition: 0.9s;
        @media screen and (max-width: 600px) {
          width: 40%;
          font-size: 10px;
          height: 30px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          width: 40%;
          font-size: 12px;
          height: 35px;
        }
        &:hover {
          transition: 0.5s;
          background: transparent;
          border: 1px solid #40a1dc;
          color: #40a1dc;
        }
      }
    }
  }
}
