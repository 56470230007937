.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1750px;
    width: 100%;

    .loaderPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      height: 200px;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 15px;
      // padding-bottom: 70vh;
      position: relative;

      .emptyImg {
        position: absolute;
        top: 30%;
        left: 40%;
        bottom: 30%;
      }

      tr {
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        height: 60px;
        border-radius: 5px;
        &:hover {
          transition: 0.9s;
          box-shadow: inset -1290px 0 0 0 #212642, inset 1290px 0 0 0 #212642;
        }
        td {
          padding: 0 9px;
          .seemore {
            color: #40a1dc;
            cursor: pointer;
          }
          .nameWrapper {
            display: flex;
            column-gap: 1rem;
            justify-content: flex-start;
            text-align: left;
            align-items: center;

            img {
              width: 40px;
              height: 40px;
            }
          }

          .firstimage {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            img {
              width: 35px;
            }

            label {
              color: rgba(255, 255, 255, 0.514);
            }
          }

          .bottomimage {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;
          }
        }
      }
      td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      td:first-child {
        padding-left: 2rem;
      }

      tr:first-child {
        font-weight: 400;
        height: 50px;
        border-radius: 5px;
        color: #8e909c;
      }

      tr {
        cursor: pointer;
        font-weight: 500;
        height: 75px;
        border-radius: 5px;
        background: #1b1e2f;

        .description {
          font-weight: normal;
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          margin-top: 10px;
        }
        .changedDescription {
          font-size: 12px;
          margin-top: 10px;
          font-weight: normal;
          width: 350px;
        }
        :nth-child(7) {
          color: rgba(255, 255, 255, 0.438);

          padding-left: 2.1rem;
        }
      }
    }
  }
}
