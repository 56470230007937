.container {
  width: 100%;
  height: auto;
  .wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 160px;
        // height: 200px;
      }
    }
  }
}
