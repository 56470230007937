.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .wrapper {
    max-width: 1500px;
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .headingWrapper {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 80%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .heading {
        font-weight: bold;
        font-size: 65px;
        text-align: center;
        color: white;
        line-height: 150px;
        span {
          color: #40a1dc;
        }
      }
      .subHeading {
        font-size: 38px;
        line-height: 35px;
        text-align: center;
        color: white;
        white-space: nowrap;
        label {
          color: rgb(165, 163, 163);
          font-size: 38px;
          font-style: italic;
          
        }
        span {
          font-size: 38px;
          color: #40a1dc;
          font-style: italic;

          
        }
      }
      .textHeading {
        font-size: 18px;
        padding: 2.5rem 0;
        color: rgb(204, 203, 203);
        line-height: 30px;

        span {
          color: #40a1dc;
        }
      }
      .secHeading {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        padding: 1rem 0;
      }
      .text {
        font-size: 18px;
        font-weight: normal;
        padding: 1rem 0;
        ol {
          padding-left: 1rem;
          li {
            list-style-type: block;
            padding: 0.5rem 0;
            color: rgb(204, 203, 203);
          }
          span {
            color: #40a1dc;
            font-weight: bold;
          }
        }
      }
      .secText {
        p {
          font-size: 18px;
          padding: 0.5rem 0;
          color: rgb(204, 203, 203);
        }
      }
      .p2 {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding: 1rem 0;
        .text {
          ol {
            padding-left: 1rem;
            li {
              list-style-type: block;
              padding: 0.5rem 0;
              color: rgb(204, 203, 203);
            }
            span {
              color: #40a1dc;
              font-weight: bold;
            }
          }
        }
      }

      .p2Inner {
        font-size: 18px;
        color: rgb(204, 203, 203);
      }
      .bottomText {
        font-size: 18px;
        color: rgb(204, 203, 203);
        span {
          color: #40a1dc;
          font-weight: bold;
          font-style: italic;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .container {
    .wrapper {
      .headingWrapper {
        padding: 0rem 1.5rem;

        .heading {
          font-size: 40px;
          text-align: left;
          line-height: 90px;
        }
        .subHeading {
          font-size: 18px;
          line-height: 16px;
          text-align: left;
          width: 90%;
          label{
            font-size: 18px;
          }
          span{
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    .wrapper {
      .headingWrapper {
        padding: 0rem 3rem;

        .heading {
          font-size: 40px;
          // text-align: left;
          line-height: 100px;
        }
        .subHeading {
          font-size: 18px;
          line-height: 20px;
          // text-align: left;
          label{
            font-size: 18px;
          }
          span{
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    .wrapper {
      .headingWrapper {
        .heading {
          font-weight: bold;
          font-size: 66px;
          // text-align: left;
          color: #40a1dc;
          line-height: 110px;
        }
        .subHeading {
          font-weight: lighter;
          font-size: 38px;
          line-height: 25px;
          label{
            font-size: 38px;
          }
          span{
            font-size: 38px;
          }
        }
      }
    }
  }
}
