.container {
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  video {
    z-index: 10000;
  }
  .wrapper {
    width: 90%;

    display: flex;
    flex-direction: column;
    .leftsection {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      padding: 2rem 0rem;
      label {
        font-size: 40px;
        font-weight: 500;
        line-height: 2.5rem;
        text-align: center;
        span {
          color: #40a1dc;
          position: relative;
        }
      }
      h6 {
        font-size: 44px;
        font-weight: 500;
        line-height: 2.5rem;
        text-align: center;
        span {
          color: #40a1dc;
          position: relative;
        }
      }
      .textsection {
        p {
          line-height: 1.5rem;
          font-size: 12px;
          text-align: center;
        }
        ul {
          margin-left: 2rem;
          padding: 10px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          li {
            font-size: 13px;
            line-height: 1.5rem;
            list-style-type: circle;
          }
        }
      }
      .btndiv {
        width: 100%;
        max-width: 400px;
        display: flex;
        align-items: center;
        column-gap: 25px;
        justify-content: center;
        align-self: center;
        .button1 {
          padding: 11px 20px;
          outline: none;
          border: 1px solid #40a1dc;
          background: #40a1dc;
          border-radius: 5px;
          color: #fff;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            border: 1px solid #40a1dc;
            background: transparent;
            color: #40a1dc;
            transition: 0.8s;
          }
        }
        img {
          width: 50px;
        }
        .watchVideo {
          display: flex;
          cursor: pointer;
          align-items: center;
          p {
            margin-left: 8px;
          }
        }
        .watchbtn {
          padding: 9px 15px;
          background: transparent;
          outline: white;
          border: 1px solid #fff;
          color: #fff;
          &:hover {
            background: #40a1dc;
            color: white;
          }
        }
      }
    }
    .rightsection {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        max-width: 80%;
        height: 100%;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container {
    .wrapper {
      flex-direction: row;
      .leftsection {
        width: 50%;
        justify-content: center;
        row-gap: 2rem;
        label {
          max-width: 460px;
          font-size: 3.2rem;
          line-height: 4rem;
          text-align: left;
          position: relative;
        }
        h6 {
          max-width: 460px;
          font-size: 3.3rem;
          line-height: 4rem;
          text-align: left;
        }
        label::after {
          content: "";
          background: #40a1dc;
          width: 10rem;
          opacity: 0.3;
          height: 1px;
          position: absolute;
          bottom: 110%;
          right: 29%;
        }
        .clrLeft {
          background: #40a1dc;
          height: 1px;
          width: 10rem;
          opacity: 0.3;
          align-self: flex-start;
        }
        .textsection {
          p {
            line-height: 2rem;
            font-size: 18px;
            text-align: left;
          }
          ul {
            margin-left: 1rem !important;
            align-items: flex-start;
          }
        }
        .btndiv {
          justify-content: flex-start;
          align-self: flex-start;
        }
      }
      .rightsection {
        width: 50%;
        height: 90vh;
        padding: 3rem 0rem;
        img {
          max-width: 80%;
          max-height: 95%;
        }
      }
    }
  }
}
@media screen and (min-width: 1500px) {
  .container {
    .wrapper {
      .leftsection {
        row-gap: 2rem;
        justify-content: center;
        label {
          max-width: 600px;
          font-size: 4rem;
        }
        h6 {
          max-width: 600px;
          font-size: 4rem;
        }
        .textsection {
          p {
            font-size: 18px;
          }
        }
      }
      .rightsection {
        width: 50%;
        height: 90vh;
        img {
          max-width: 80%;
          max-height: 95%;
        }
      }
    }
  }
}
