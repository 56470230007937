.container {
  position: fixed;
  display: flex;
  align-self: flex-end;
  justify-content: end;
  top: 90%;
  z-index: 1000;
  min-height: 100vh;
  right: 7%;
  .wrapper {
    position: relative;
    left: -5%;
    .chatbox {
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: none;
      @media screen and (min-width: 768px) {
        cursor: pointer;
      }
      img {
        width: 100%;
      }
    }
  }
}
