.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  background: #0000001a;
  opacity: 0;
  animation: showModal 0.4s ease forwards;
  // overflow: auto;
  z-index: 10000;
  backdrop-filter: blur(8px);
}

.modal2 {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  // background: #0000001a;
  opacity: 0;
  animation: showModal2 0.4s ease forwards;
  // overflow: auto;
  z-index: 10000;
  backdrop-filter: blur(8px);
}

@keyframes showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showModal2 {
  0% {
    transform: scale(0) rotate(0);
    opacity: 0;
  }

  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

.video {
  position: relative;
  width: 50%;
  // max-width: 591px;
  margin: auto;
  z-index: 2;
  border-radius: 10px !important;
  border-radius: 16px;
  background: #2d3143;
  // border: 1.5px solid #111422;
  box-shadow: 0px 34px 33px rgba(22, 28, 45, 0.329);
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
}
.outer {
  position: relative;
  width: 100%;
  max-width: 341px;
  margin: auto;
  z-index: 2;
  border-radius: 10px !important;
  border-radius: 16px;
  background: #2d3143;
  border: 1.5px solid #111422;
  box-shadow: 0px 34px 33px rgba(22, 28, 45, 0.329);
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 0px;
  height: 0px;
  background: url("../../../Assets/image/stop.svg");
  background-color: #40a1dc;
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  border: 15px solid transparent;
  border-radius: 5px;
  font-size: 0;
  cursor: pointer;

  &:hover {
    // border: 1px solid #40a1dc;
    // color: #40a1dc;
    background-color: #40a1dc !important;
    background: url("../../../Assets/image/stop.svg");
    background-repeat: no-repeat;
    background-position: center;
    // border: 1px solid;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1400px) {
  .outer {
    max-width: 500px;
    // padding: 30px 10px;
    border-radius: 16px !important;
  }

  .close {
    top: -7px;
    right: -10px;
    width: 35px;
    height: 35px;
  }
}

@media only screen and (min-width: 1400px) {
  .outer {
    max-width: 508px;
    // padding: 30px 10px;
  }

  .close {
    top: -10px;
    right: -15px;
    width: 35px;
    height: 35px;
    background-size: 20px;
  }
}
