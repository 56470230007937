.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  // margin: 2rem/;
  img {
    max-height: 50px;
    @media screen and (max-width: 600px) {
      height: 40px;
    }
  }
  label {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }
  p {
    // width: 598px;
    // height: 116px;
    // font-family: Montserrat;
    font-weight: normal;
    font-size: 30px;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .btndiv {
    width: 100%;
    display: flex;
    column-gap: 5px;
    button {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      width: 50%;
      padding: 13px;
      border: 1px solid #40a1dc;
      background-color: #40a1dc;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        transition: 0.5s;
        background: #1c587e;
        border: 1px solid #1c587e;
        color: #fff;
        border: 1px solid #1c587e;
      }
      @media screen and (max-width: 600px) {
        padding: 8px;
        font-size: 15px;
      }
    }
    .cancelbtn {
      background-color: transparent;
      color: #fff;
      border: 1px solid #40a1dc;
      cursor: pointer;
      &:hover {
        transition: 0.9s;
        box-shadow: inset -290px 0 0 0 #40a1dc, inset 290px 0 0 0 #40a1dc;

        color: #fff;
      }
    }
  }
}
