.container {
  width: 100%;

  .wrapper {
    width: 100%;

    @media screen and (max-width: 1185px) {
      padding-bottom: 70px;
    }
    .headingWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .headingg {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        text-align: left;
        color: #40a1dc;
      }
      .subHeading {
        width: 668px;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: rgba(255, 255, 255, 0.445);
      }
    }
    .textField {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      form {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-bottom: 60px;
        input {
          outline: none;
          background: transparent;
          border-bottom: 1px solid rgba(255, 255, 255, 0.37) !important;
          color: white;
          border: none;
          width: 100%;
          height: 52.5px;
          padding: 1rem;

          &::placeholder {
            color: white !important;
            // opacity: 1; /* Firefox */
          }
        }
        button {
          width: 100%;
          height: 52.5px;

          font-weight: normal;
          border-radius: 5px;
          background: #40a1dc;
          color: black;
          font-size: 18px;
          border: none;
          cursor: pointer;
          transition: 3s;
          &:hover {
            background: transparent;
            border: 2px solid #40a1dc;
            color: #40a1dc;
            transition: 2s;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .container {
    .wrapper {
      .headingWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        .heading {
          font-weight: bold;
          font-size: 35px;
          line-height: 40px;
        }
        .subHeading {
          width: 300px;
          font-size: 15px;
        }
      }
      .textField {
        form {
          input {
          }
          button {
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .container {
    .wrapper {
      .headingWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        .heading {
          font-weight: bold;
          font-size: 35px;
          line-height: 40px;
        }
        .subHeading {
          width: 500px;
          font-size: 15px;
        }
      }
      .textField {
        form {
          input {
          }
          button {
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    .wrapper {
      .headingWrapper {
        .heading {
          font-weight: bold;
          font-size: 50px;
        }
        .subHeading {
          width: 450px;
          font-weight: normal;
          font-size: 20px;
        }
      }
      .textField {
        form {
          input {
          }
          button {
          }
        }
      }
    }
  }
}
