.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .wrapper {
    max-width: 1500px;
    padding: 2rem 0rem;
    .headingWrapper {
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;

      .heading {
        font-weight: bold;
        font-size: 76px;
        text-align: left;
        color: white;
        line-height: 150px;
        span {
          color: #40a1dc;
        }
      }
      .subHeading {
        font-weight: lighter;
        font-size: 20px;
        width: 60%;
        line-height: 35px;
        text-align: center;
        color: rgba(255, 255, 255, 0.637);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .container {
    .wrapper {
      .headingWrapper {
        padding: 0rem 1.5rem;

        .heading {
          font-size: 40px;
          text-align: left;
          line-height: 100px;
        }
        .subHeading {
          font-size: 15px;
          line-height: 35px;
          text-align: justify;
          width: 90%;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    .wrapper {
      .headingWrapper {
        padding: 0rem 3rem;

        .heading {
          font-size: 40px;
          text-align: left;
          line-height: 100px;
        }
        .subHeading {
          font-size: 15px;
          line-height: 35px;
          text-align: justify;
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    .wrapper {
      .headingWrapper {
        .heading {
          font-weight: bold;
          font-size: 66px;
          text-align: left;
          color: #40a1dc;
          line-height: 110px;
        }
        .subHeading {
          font-weight: lighter;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: rgba(255, 255, 255, 0.637);
        }
      }
    }
  }
}
