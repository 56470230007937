.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px 30px 0px;

    p {
      font-size: 65px;
      font-weight: bold;
      color: #40a1dc;
    }
    label {
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .playerwrapper {
      display: flex;
      flex-direction: column;

      justify-content: space-between;
      video {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .textField {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-top: 1rem;
      form {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        input {
          outline: none;
          background: transparent;
          border-bottom: 1px solid rgba(255, 255, 255, 0.37) !important;
          color: white;
          border: none;
          width: 100%;
          height: 52.5px;
          padding: 1rem;

          &::placeholder {
            color: white !important;
            // opacity: 1; /* Firefox */
          }
        }
        button {
          width: 100%;
          height: 52.5px;

          font-weight: normal;
          border-radius: 5px;
          background: #40a1dc;
          color: black;
          font-size: 18px;
          border: none;
          cursor: pointer;
          transition: 3s;
          &:hover {
            background: transparent;
            border: 2px solid #40a1dc;
            color: #40a1dc;
            transition: 2s;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .container {
    .wrapper {
      .playerwrapper {
        flex-direction: row;
        gap: 2;
        video {
          width: 45%;
        }
      }
      .textField {
        display: flex;
        width: 50%;
        flex-direction: column;
        padding-top: 1rem;
      }
    }
  }
}
