.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .textsection {
    width: 80%;
    text-align: center;
    line-height: 3rem;

    p {
      font-size: 20px;
      text-transform: capitalize;
    }
    button {
      max-width: 60%;
      height: 40px;
      margin-bottom: 1.3rem;
    }
  }
}
