.wrapper {
  background-color: #1b1e2f;
  width: 100%;
  max-width: 1750px;
  margin-top: 32px;
  border-radius: 10px;
  padding: 20px;
  .imgWrapper {
    display: flex;

    text-align: center;
    img {
      width: 30px;
      margin-bottom: 10px;
    }
    span {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .imgWraaper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .opputnityWrapper {
      img {
        margin-right: auto;
        width: 25px;
        margin-right: 4px;
      }
      span {
        // margin-left: 5px;
        font-size: 25px;
        p {
          margin-left: 5px;
          display: inline;
          font-size: 20px;
        }
      }
    }
    label {
      display: block;
      font-size: 21px;
      margin-top: 10px;
      p {
        margin-left: 4px;
        display: inline;
        font-size: 18px;
      }
    }

    img {
      width: 25%;
      max-width: 250px;
    }
    .darklogo {
      width: 50%;
      align-items: end;
    }
  }
}
