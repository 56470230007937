.skeletonCard {
  padding-left: 1rem;
  margin-bottom: 10px;
  display: flex;
  .circleSkelton {
    margin-top: 10px;
  }
  .rightSkeleton {
    margin-top: 10px;
    margin-left: 20px;
  }
}
