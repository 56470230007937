.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1750px;
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 15px;

      tr {
        text-align: left;
        margin-bottom: 30px;
        &:hover {
          transition: 0.9s;
          box-shadow: inset -1290px 0 0 0 #212642, inset 1290px 0 0 0 #212642;
        }
        td {
          padding: 0 9px;
          .firstimage {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            img {
              width: 35px;
            }
            label {
              color: rgba(255, 255, 255, 0.514);
            }
          }
          .bottomimage {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;
          }
        }
      }
      td:first-child {
        padding-left: 2rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      tr:first-child {
        font-weight: 400;
        height: 45px;
        background: #1b1e2f;
        border-radius: 15px;
        color: #8e909c;
        font-size: 15px;
      }
      tr:not(:first-child) {
        cursor: pointer;
        font-weight: 500;

        height: 70px;
        border-radius: 15px;
        background: #1b1e2f;
        :nth-child(4) {
          color: #51c511;
        }
        :nth-child(5) {
          color: white;
        }
        :nth-child(7) {
          :not(:first-child) {
            color: rgba(255, 255, 255, 0.438);

            padding-left: 2.1rem;
          }
        }
      }
    }
  }
}
