.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 89.5vh;
  padding: 20px 0;
  @media screen and (max-width: 1124px) {
    flex-direction: column;
  }
  .wrapper {
    width: 45%;
    display: flex;
    margin-left: 30px;
    flex-direction: column;
    // justify-content: center;
    // line-height: 5px;

    padding: 15px 20px;
    @media screen and (max-width: 1124px) {
      font-size: 18px;
      width: 100%;
      margin-left: 0px;
    }
    .bactoImg {
      width: 35px;
      margin-top: -10px;

      cursor: pointer;
    }
    .borderRight {
      border-right: 1px solid rgb(186, 179, 179);

      // height: 450px;
      margin-top: 20px;
      @media screen and (max-width: 1124px) {
        border: none;
      }

      .heading {
        width: 100%;
        margin-top: 20px;

        .headingBtn {
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          width: 170px;
          height: 39px;
          border-radius: 50px;
          background: #40a1dc;
          border: 1px solid #40a1dc;
          padding: 6px;
          @media screen and (max-width: 600px) {
            height: 33px;
            font-size: 11.5px;
          }

          font-weight: 600;
          font-size: 15px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          transition: 1s;

          &:hover {
            transition: 1.5s;
            background: transparent;
            border: 1px solid #40a1dc;
            color: #40a1dc;
          }
        }
      }
      label {
        font-size: 40px;

        // text-align: center;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
          font-size: 18px;
          width: 90%;
        }
        img {
          width: 30px;
        }
      }

      form {
        width: 90%;
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.5rem;
        @media screen and (max-width: 1124px) {
          width: 100%;
        }
        .addressWrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          img {
            width: 30px;
            cursor: pointer;
          }
          label {
            font-weight: 400;
            font-size: 20px;
          }
        }
      }
      .infoWrapper {
        margin-top: 20px;
        width: 90%;
        @media screen and (max-width: 1124px) {
          width: 100%;
        }
        .withdrawInfo {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 400;
            font-size: 16px;
          }
          p {
            color: #fff;
            margin-top: -0px;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      .btnsection {
        width: 90%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.5rem;
        @media screen and (max-width: 1124px) {
          width: 100%;
        }
        .btn {
          width: 95%;
          margin-top: 0.5rem;
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          width: 100%;
          height: 45px;
          border-radius: 8px;
          background: #40a1dc;
          font-weight: bold;
          font-size: 17px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          transition: 1s;
          &:hover {
            transition: 0.5s;
            background: #1c587e;
          }
        }
      }
    }

    .QSymbolImg {
      img {
        width: 12px;
      }
    }
    .DnyamicWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .historyWraper {
    width: 55%;
    margin-top: 40px;
    @media screen and (max-width: 1124px) {
      width: 100%;
    }
  }
  p {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.623);
  }
}
