.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  .portfolioInfo {
    img{
      cursor: pointer;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    position: absolute;
    // padding: 20px;
    top: 50%;
    bottom: 50%;
    row-gap: 10px;
    .heading {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.637);
      opacity: 0.5;
    }
    .totalWorth {
      font-size: 25px;
      text-align: center;
      font-weight: 500;
      color: #fff;
    }
    .totalAssets {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.637);
      opacity: 0.5;
    }
  }
}
