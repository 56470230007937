.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;

    p {
      font-size: 25px;
      color: #fff;
      font-weight: 600;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #be2539;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #800b1a;

        transition: 0.5s;
      }

      &:nth-last-child(2) {
        background: #40a1dc;

        &:hover {
          background: #11537c;
          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
    .countrySelect {
      background: #1b1e2f;
      border: none;
      font-family: Arial;
      font-weight: normal;
      height: 50px;
      margin-top: 20px;
      font-size: 15px;
      text-align: left;
      color: #fff;
      color-scheme: dark;
      outline: none;
      width: 100%;

      &::placeholder {
        color: rgba(255, 255, 255, 0.301);
      }
    }
    .countrySelects {
      margin-top: 5px;
      margin-bottom: 10px;
      label {
        color: rgba(255, 255, 255, 0.623);
        margin-bottom: 10px;
        line-height: 30px;
      }
      .buttonClass {
        background-color: #1b1e2f;
        border: none;

        &:hover {
          background-color: #1b1e2f !important;
        }
      }
    }
    .bankDetails {
      text-align: center;
      margin: 10px 0px 10px 0px;
      span {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
