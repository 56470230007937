//Desktop Query Stats Here

.container {
  width: 100%;

  .menuItem {
    a {
      &:hover {
        color: #40a1dc;
      }
    }

    button {
      width: 130px;
      height: 45px;
      border-radius: 12px;
      background: #40a1dc;
      outline: none;
      border: 1px solid #40a1dc;
      cursor: pointer;
      font-weight: normal;
      border-radius: 5px;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        transition: 0.5s;
        background: #1c587e;
        border: 1px solid #1c587e;
        color: #fff;
        border: 1px solid #1c587e;
      }
      @media screen and (max-width: 1024px) {
        width: 90px;
        height: 30px;
      }
    }
  }
}

//Desktop Query Ends here
