.container {
  width: 100%;
  transition: 1s;
  // padding: 0px 20px;
  display: flex;
  justify-content: end;
  // align-items: flex-end;
  .wrapper {
    width: 100%;

    .chatView {
      width: 100%;

      background-color: #111422;
      .profile {
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        column-gap: 0.5rem;
        // margin-top: 20px;
        padding: 1rem 0rem 1rem 2rem;
        background: #191b2b;
        // border-bottom: 1px solid rgba(255, 255, 255, 0.06);

        // box-shadow: 2px 1px 18px rgba(255, 255, 255, 0.06);

        .left {
          padding: 0.6rem 0rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .profilepic {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            border: 1px solid greenyellow;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              border-radius: 50%;
              height: 100%;
            }
          }
          .infoWraper {
            margin-left: 10px;
            p {
              font-size: 0.9rem;
            }
          }
        }
        .right {
          display: flex;
          color: white;
          align-items: center;
          label {
            font-size: 1.1rem;
            font-weight: normal;
          }
          p {
            font-size: 0.6rem;

            font-weight: normal;
          }
        }
      }
      .messagelist {
        // height: 75vh;
        height: 355px;

        background-color: #1f222f;
        @media screen and (min-width: "1424px") {
          height: 495px;
          background-color: #1f222f;
        }
        @media screen and (min-width: "1624px") {
          height: 695px;
          background-color: #1f222f;
        }
        @media screen and (min-width: "1924px") {
          height: 895px;
        }
        .messages {
          width: 100%;
          overflow-y: auto;

          overflow-x: hidden;
          height: 355px;
          display: flex;
          padding: 0rem 1rem;
          flex-direction: column;
          row-gap: 1rem;
          align-items: center;
          @media screen and (min-width: "1424px") {
            height: 495px;
            background-color: #1f222f;
          }
          @media screen and (min-width: "1624px") {
            height: 695px;
            background-color: #1f222f;
          }
          @media screen and (min-width: "1924px") {
            height: 895px;
          }
          .loader {
            height: 50vh;
            display: flex;
            align-items: center;
          }
          .chatMessage {
            width: 100%;
            height: 100%;
          }

          .currentday {
            width: 100%;
            min-height: 2rem;
            text-align: center;
            padding: 2rem 0rem;
            span {
              font-size: 1rem;
              padding: 0.3rem 0.5rem;
              background-color: #272a3d;
              border-radius: 0.5rem;
            }
          }
          .sendermessage {
            max-width: 70%;
            word-wrap: break-word;
            align-self: flex-end;
            border-bottom-left-radius: 0.7rem;
            border-top-left-radius: 0.7rem;
            border-bottom-right-radius: 0.7rem;
            padding: 0.2rem 0.3rem 0.4rem 0.3rem;
            background-color: rgb(15, 107, 119);
            span {
              color: #21263b;
              font-size: 1rem;
              line-height: 25px;
            }
            p {
              color: white;
            }
            .fileDownload {
              display: flex;
              cursor: pointer;
              padding-top: 5px;
              p {
                color: white;
              }
              .icon {
                width: 20px;
                margin-left: 5px;
              }
            }
          }
          .receivermessage {
            max-width: 70%;
            word-wrap: break-word;
            align-self: flex-start;
            background-color: #272a3d;
            border-bottom-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            border-bottom-right-radius: 0.7rem;
            padding: 0.3rem 0.3rem 0.5rem 0.5rem;
            span {
              font-size: 1rem;
              line-height: 25px;
            }
            .fileDownload {
              display: flex;
              cursor: pointer;
              padding-top: 5px;
              p {
                color: white;
                margin-right: 8px;
              }
              .icon {
                width: 20px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .sendmessage {
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #1f222f;

        padding: 1rem 0.5rem;
        .typemessage {
          height: 2.5rem;
          padding: 0 1rem;
          background-color: #111422;
          border-radius: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .type {
            width: 90%;
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              background-color: #111422;
              outline: none;
              border-radius: 10px;
              border: none;
              font-weight: 600;
              padding: 0 0.3rem;
              color: white;
            }
          }
          .loader {
            width: 40px;
          }
          .choosefile {
            width: 20px;
            cursor: pointer;
            input {
              display: none;
            }
            label {
              cursor: pointer;
              img {
                height: 18px;
                color: #fff;
                filter: grayscale(1) invert(1);
              }
            }
          }
          .send {
            width: 35px;
            height: 70%;
            outline: none;
            margin-left: 10px;
            border: none;
            border-radius: 10px;
            background-color: #40a1dc;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              width: 40%;
            }
          }
        }
      }

      .heading {
        width: 100%;

        padding: 1rem 1rem;
        span {
          font-size: 1rem;
        }
      }
      .formbody {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        padding: 5px 1rem;
        .inputfield {
          width: 100%;
          span {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.452);
          }
          .field {
            margin-top: 5px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-left: 20px;
            border-radius: 5px;
            border: 1px solid #40a1dc;
            input {
              width: 90%;
              height: 40px;
              background-color: transparent;
              outline: none;
              border: none;
              border-radius: 5px;
              color: #fff;
              &::placeholder {
                font-size: 15px;
                color: #fff;
              }
            }
            textarea {
              width: 100%;
              background-color: transparent;
              outline: none;
              border: none;
              color: #fff;
              padding: 0.3rem 0rem;
              &::placeholder {
                color: #fff;
              }
            }
          }
        }
        .sendbtn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 1rem 0rem;
          button {
            padding: 0.3rem 1.3rem;
            background-color: #40a1dc;
            border-radius: 5px;
            border: 1px solid #40a1dc;
            outline: none;
            color: #fff;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
              transition: 0.5s;
              background-color: transparent;
              border: 1px solid #40a1dc;
            }
          }
        }
      }
    }
    .chatbox {
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: none;
      @media screen and (min-width: 768px) {
        cursor: pointer;
      }
      img {
        width: 100%;
      }
      .notificationbadge {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #ff3e3e;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
}
