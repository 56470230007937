.container {
  width: 100%;

  .headingWrapper {
    position: sticky;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #111422;
    z-index: 0;
    // top: 4.9rem;
    top:78px;
    padding: 1rem 6.1rem 1rem 6.1rem;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      padding: 1rem 2.1rem 1rem 2.1rem;
      top: 0;
    }

    // @media screen and (min-width: 1024px) and (max-width: 1350px) {
    //   top: 80.1px;
    // }

    // @media screen and (min-width: 1350px) and (max-width: 1550px) {
    //   top: 80.3px;
    // }

    .headingBtn {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 170px;
      height: 39px;
      border-radius: 50px;
      background: #40a1dc;
      border: 1px solid #40a1dc;
       padding: 6px;
      @media screen and (max-width: 600px) {
        height: 33px;
        font-size: 11.5px;
      }

      font-weight: 600;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        transition: 1.5s;
        background: transparent;
        border: 1px solid #40a1dc;
        color: #40a1dc;
      }
    }

    .heading {
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      padding-right: 5rem;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 100%;

      img {
        width: 100px;
      }

      @media screen and (max-width: 600px) {
        // font-size: 18px;
        // padding-right: 5rem;
        img {
      display: none;
        }
      }
    }

    //****//
    .tooltip {
      position: relative;
      text-align: right;

      .imgWrapper {
        border-radius: 50%;
        background: #40a1dc;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: rotate(0deg);
        transition: 1.5s;

        @media screen and (max-width: 1024px) {
          cursor: unset;
        }

        img {
          width: 25px;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            cursor: unset;
          }
        }

        &:hover {
          transform: rotate(360deg);
          transition: 0.5s;
        }
      }
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #20232f;
      box-shadow: inset 0px 0px 3px #40a0dc;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      right: 50px;
      top: 5px;

      @media screen and (max-width: 600px) {
        width: 80px;
      }
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  //**//

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0rem 2.1rem 1rem 2.1rem;
    box-sizing: border-box;
    overflow: auto;

    .pageHeight {
      min-height: 65vh;

      .cardWrapper {
        display: flex;
        column-gap: 4.5rem;
        row-gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;

        .loaderPosition {
          display: flex;
          justify-content: center;
          height: 70vh;
        }

        .card {
          margin-top: 2rem;
          min-width: 280px;
          max-width: 310px;
          height: auto;
          padding: 0.4rem 0 0.8rem 0;
          border-radius: 20px;
          background: #1b1e2f;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .imgWrapper {
            display: flex;
            justify-content: center;
            align-self: center;
            // .img1 {
            //   height: 200px;
            //   padding: 0 1rem;
            //   cursor: pointer;
            //   text-align: center;
            // }
          }

          .cardHeading {
            padding-left: 1rem;
            padding-top: 0.5rem;
            width: 150px;
            height: 32px;
            font-weight: bold;
            font-size: 20px;
            letter-spacing: 0.01em;
            line-height: 32px;
            text-align: left;
            color: #fcfcfc;
          }

          .paragraphWrapper {
            padding: 1rem;
            display: flex;
            justify-content: space-between;

            .leftSide {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;

              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 25px;
                text-align: left;
                color: #fff;
                white-space: nowrap;
              }

              p {
                font-weight: 600;
                font-size: 14px;
                text-align: left;
                color: #fff;
                white-space: nowrap;
              }
            }

            .rightSide {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;

              span {
                font-weight: 600;
                font-size: 18px;
                text-align: right;
                color: #fff;

                img {
                  width: 12%;
                }
              }

              p {
                font-weight: 600;
                font-size: 14px;
                text-align: right;
                color: #fff;
              }
            }
          }

          .btn {
            display: flex;
            align-self: center;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            width: 90%;
            height: 49px;
            border-radius: 8px;
            background: #40a1dc;
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            color: #fff;
            cursor: pointer;
            transition: 1s;

            &:hover {
              transition: 1.5s;
              background: transparent;
              border: 1px solid #40a1dc;
              color: #40a1dc;
            }
          }
        }
      }
    }
  }
}

.innerClass {
  // margin: 3rem 11.1rem 0rem 2.1rem;
  margin: 3rem 0 1rem 0;
  display: flex;
  // justify-content: flex-end;
  justify-content: center;
  column-gap: 1rem;

  @media screen and (max-width: 600px) {
    margin: 3rem 0rem 0rem 0rem;
    // justify-content: center;
  }

  .itemClass {
    background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #fff;
    transition: 0.8s;

    @media screen and (max-width: 600px) {
      width: 30px;
      height: 30px;
    }

    a {
      color: black;
      font-weight: bold;
    }

    // &:hover {
    //   transition: 1.2s;
    //   border: 1px solid rgb(163, 163, 163);
    //   background: rgb(163, 163, 163);
    //   box-sizing: border-box;
    // }
  }

  .activeClass {
    background: #40a1dc;
    border: 1px solid #40a1dc;

    a {
      color: #fff;
      font-weight: bold;
    }
  }
}
