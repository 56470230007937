.container {
  position: fixed;
  display: flex;
  align-self: flex-end;
  justify-content: end;
  top: 90%;
  z-index: 1000;
  min-height: 100vh;
  right: 7%;
  .wrapper {
    position: relative;
    left: -5%;
    .chatView {
      width: 20rem;
      height: 30rem;
      position: absolute;
      bottom: 101%;
      right: 7%;
      background-color: #21263b;
      border-radius: 10px;
      .profile {
        width: 100%;
        display: flex;
        .left {
          width: 20%;
          padding: 0.6rem 0rem;
          display: flex;
          justify-content: center;
          .profilepic {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            border: 1px solid greenyellow;
            display: flex;
            justify-content: center;
            img {
              width: 80%;
              height: 100%;
            }
          }
        }
        .right {
          width: 80%;
          display: flex;
          flex-direction: column;
          color: rgba(255, 255, 255, 0.671);
          row-gap: 2px;
          padding: 0.3rem 0rem;
          label {
            font-size: 1.1rem;
            font-weight: normal;
          }
          span {
            font-size: 0.6rem;
            font-weight: normal;
          }
        }
      }
      .messagelist {
        height: 23rem;
        .messages {
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 23rem;
          display: flex;
          padding: 0rem 1rem;
          flex-direction: column;
          row-gap: 1rem;
          align-items: center;

          .chatMessage {
            width: 100%;
            height: 100%;
          }

          .currentday {
            width: 100%;
            min-height: 2rem;

            text-align: center;
            padding: 2rem 0rem;
            span {
              font-size: 0.8rem;
              padding: 0.3rem 0.5rem;
              background-color: #272a3d;
              border-radius: 0.5rem;
            }
          }
          .sendermessage {
            max-width: 13rem;
            width: auto;
            word-wrap: break-word;
            align-self: flex-end;
            border-bottom-left-radius: 0.7rem;
            border-top-left-radius: 0.7rem;
            border-bottom-right-radius: 0.7rem;
            padding: 0.2rem 0.3rem 0.4rem 0.3rem;
            background-color: rgb(15, 107, 119);
            span {
              font-size: 0.8rem;
            }
          }
          .receivermessage {
            max-width: 13rem;
            width: auto;
            word-wrap: break-word;
            align-self: flex-start;
            background-color: #272a3d;
            border-bottom-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            border-bottom-right-radius: 0.7rem;
            padding: 0.3rem 0.3rem 0.5rem 0.5rem;
            span {
              font-size: 0.7rem;
            }
          }
        }
      }
      .sendmessage {
        width: 100%;
        padding: 1rem 1rem;
        .typemessage {
          width: 100%;
          height: 2.5rem;
          background-color: #1b1e2f;
          border-radius: 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .type {
            width: 70%;
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              background-color: #1b1e2f;
              outline: none;
              border-radius: 10px;
              border: none;
              font-weight: 600;
              padding: 0 0.3rem;
              color: white;
            }
          }
          .choosefile {
            width: 10%;
            cursor: pointer;
            input {
              display: none;
            }
            label {
              cursor: pointer;
              img {
                width: 70%;
                height: 20px;
                color: #fff;
                filter: grayscale(1) invert(1);
              }
            }
          }
          .send {
            width: 15%;
            height: 80%;
            outline: none;
            border: none;
            border-radius: 10px;
            background-color: #40a1dc;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              width: 40%;
            }
          }
        }
      }

      .heading {
        width: 100%;

        padding: 1rem 1rem;
        span {
          font-size: 1rem;
        }
      }
      .formbody {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        padding: 5px 1rem;
        .inputfield {
          width: 100%;
          span {
            font-size: 12px;
            color: rgb(241, 239, 239);
          }
          .field {
            margin-top: 5px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-left: 20px;
            border-radius: 5px;
            border: 1px solid #40a1dc;

            .input1 {
              width: 90%;
              height: 40px;
              background-color: transparent;
              outline: none;
              border: none;
              border-radius: 5px;
              color: #fff;
              &::placeholder {
                font-size: 13px;
                color: red;
              }
            }

            .textarea1 {
              width: 100%;
              background-color: transparent;
              outline: none;
              border: none;
              color: #fff;
              resize: none;
              padding: 0.3rem 0rem;
              &::placeholder {
                font-size: 13px;
                color: red;
              }
            }
            .textarea2 {
              width: 100%;
              background-color: transparent;
              outline: none;
              border: none;
              color: #fff;
              resize: none;
              padding: 0.3rem 0rem;
              &::placeholder {
                font-size: 13px;
                color: rgb(153, 150, 150);
              }
            }
            .input2 {
              width: 90%;
              height: 40px;
              background-color: transparent;
              outline: none;
              border: none;
              border-radius: 5px;
              color: #fff;
              &::placeholder {
                font-size: 13px;
                color: rgb(153, 150, 150);
              }
            }
            transition: 0.5s;
            &:focus-within {
              border: 1px solid #fff;
              transition: 0.8s;
            }
          }
        }
        .sendbtn {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 1rem 0rem;
          button {
            padding: 0.3rem 1.3rem;
            background-color: #40a1dc;
            border-radius: 5px;
            border: 1px solid #40a1dc;
            outline: none;
            color: #fff;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
              transition: 0.5s;
              background-color: transparent;
              border: 1px solid #40a1dc;
            }
          }
        }
      }
    }
    .chatbox {
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: none;
      @media screen and (min-width: 768px) {
        cursor: pointer;
      }
      img {
        width: 100%;
      }
      .notificationbadge {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #ff3e3e;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
}
