.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 90%;

    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 0px 10px 0px;

      img {
        width: 20%;
      }
    }

    .formbody {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      padding: 10px 0px;

      .inputfield {
        width: 100%;

        span {
          font-size: 16px;
        }

        .field {
          margin-top: 15px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding-left: 20px;
          border-radius: 5px;
          border: 1px solid #40a1dc;

          input {
            width: 90%;
            height: 50px;
            background-color: transparent;
            outline: none;
            border: none;
            border-radius: 5px;
            color: #fff;

            &::placeholder {
              font-size: 15px;
              color: #fff;
            }
          }

          img {
            cursor: pointer;
            width: 23px;
            max-width: 30px;
          }
        }

        p {
          padding: 12px 0px;
          font-size: 16px;
          text-align: left;
          cursor: pointer;

          span {
            font-size: 15px;
            color: #3b94cb;
            font-weight: bold;
          }
        }
      }

      .passwordfield {
        width: 100%;

        .field {
          margin-top: 15px;
          width: 100%;
          display: flex;
          justify-content: space-around;
          border-radius: 5px;
          border: 1px solid #40a1dc;

          input {
            width: 75%;
            height: 50px;
            background-color: transparent;
            outline: none;
            border: none;
            border-radius: 5px;
            color: #fff;

            &::placeholder {
              font-size: 15px;
              color: #fff;
            }
          }

          img {
            cursor: pointer;
            width: 23px;
            max-width: 30px;
          }
        }
      }

      .butttonfield {
        width: 100%;
        padding: 10px 0px 5px 0px;

        .btn {
          width: 100%;
          height: 45px;
          background-color: #40a1dc;
          border: none;
          outline: none;

          font-weight: 600;
          font-size: 15px;
          border-radius: 5px;
          transition: 0.5s;
          cursor: pointer;
          color: white;

          &:hover {
            background-color: #1c587e;
            color: white;
          }
        }
      }
    }
  }
}
