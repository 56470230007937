.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    img {
      width: 30%;
      max-width: 30%;
    }
    .content {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 7px;
      .heading {
        font-size: 15px;
        font-weight: 600;
        color: white;
      }
      .Text {
        color: white;
        font-size: 12px;
      }
    }
  }
}
