.container {
  width: 100%;
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 100%;
    max-width: 1750px;

    .textSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 2rem 0;

      label {
        font-weight: 500;
        font-size: 40px;
        letter-spacing: 0.01em;
        line-height: 80px;
        text-align: center;
      font-weight: bold;
        @media screen and (max-width: 600px) {
          font-size: 30px;
          line-height: 50px;
          padding: 1rem 3rem;
      
        }
      
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          font-size: 35px;
          line-height: 60px;
        }
      

        span {
          color: #40a1dc;
          font-weight: bold;

        }
      }

      p {
        font-weight: normal;
        font-size: 40px;
        letter-spacing: 0.01em;
        line-height: 80px;
        text-align: center;

        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: 40px;
        }

        @media screen and (min-width: 600px) and (max-width: 1024px) {
          font-size: 35px;
          line-height: 40px;
        }

        span {
          color: #40a1dc;
          font-weight: bold;
          font-style: italic;
        }
      }

      .dec {
        text-align: center;
        width: 80%;
        letter-spacing: 0.02em;
        line-height: 28px;
        font-size: 19px;
        font-weight: 300;

      }
    }

    .cardWrapper {
      display: flex;
      column-gap: 2.5rem;
      row-gap: 2.5rem;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (max-width: 600px) {
        column-gap: 1rem;
        row-gap: 1rem;
      }

      .card {
        width: 30%;
        min-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        height: 350px;
        border-radius: 20px;
        background: #40a1dc;
        opacity: 0.8;

        &:hover {
          background: #1e7fbb;
          transition: 0.5s;
        }

        .textWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 1rem;

          img {
            width: 90px;
            height: 90px;

            @media screen and (max-width: 600px) {
              width: 60px;
              height: 60px;
            }
          }

          label {
            font-weight: bold;
            font-size: 24px;
            letter-spacing: 0.03em;
            color: #fff;
            text-align: center;

            // &:hover {
            //   text-shadow: 3px 3px #071b2783;
            //   transition: 0.5s;
            // }
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }

          p {
            text-align: center;
            font-weight: normal;
            font-size: 18px;
            letter-spacing: 0.03em;
            line-height: 30px;
            color: #fff;
            opacity: 0.8;

            @media screen and (max-width: 600px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}