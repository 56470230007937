.dropdown {
  width: 100%;
  position: relative;
  user-select: none;
  // min-width: 150px;
  cursor: pointer;
  p {
    width: 100%;
    color: rgba(255, 255, 255, 0.722);
    margin-bottom: 7px;
    font-size: 13px;
    display: flex;
    align-items: center;
    span {
      width: 20px;
      display: flex;
      position: relative;
      .status {
        width: 220px;
        display: none;
        position: absolute;
        font-size: 13px;
        top: 120%;
        left: 0%;
        z-index: 1;
        padding: 5px;
        border-radius: 5px;
        background-color: #0e0f16;
        color: #fff;
        opacity: 1;
        text-align: justify;
      }
      .exclamationMark {
        position: relative;
        font-size: 14px;
        margin-top: -12px;
        cursor: pointer;
        color: rgba(255, 255, 0, 0.74);
      }
      &:hover {
        .status {
          display: flex;
        }
      }
    }
  }
  .lastChild {
    width: 100%;
    .text {
      padding: 0rem 0.5rem;
      width: 100%;
      display: flex;
      font-size: 13px;
      margin-top: 5px;
      align-items: center;
      column-gap: 4px;
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 1024px) {
    cursor: unset;
  }
  .dropdownbtn {
    display: flex;
    border-radius: 10px;
    background: transparent;

    background-color: #1b1e2f;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      cursor: unset;
    }
    span {
      width: 100%;
      // min-width: 200px;
      padding: 0 10px;
      border: none;
      border-radius: 10px;
      resize: none;
      outline: none;
      color: White;
      display: flex;
      flex: 1;
      appearance: none;
      font-size: 16px;
      text-transform: capitalize;
      word-break: break-all;
      span {
        width: 20px;
        display: flex;
        position: relative;
        .status {
          width: 220px;
          display: none;
          position: absolute;
          font-size: 13px;
          top: 120%;
          right: -650%;
          z-index: 1;
          padding: 5px;
          border-radius: 5px;
          background-color: #0e0f16;
          color: #fff;
          opacity: 1;
          text-align: justify;
        }
        .exclamationMark {
          position: relative;
          font-size: 14px;
          margin-top: 3px;
          cursor: pointer;
          color: rgba(255, 255, 0, 0.74);
        }
        &:hover {
          .status {
            display: flex;
          }
        }
      }
    }
    .imgContainer {
      min-height: 20px;
      min-width: 20px;
      max-height: 20px;
      max-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .leftIcon {
        max-width: 25px;
        min-width: 25px;
        max-height: 25px;
        margin-right: 10px;
      }
    }
    .withRightIcon {
      max-width: 15px;
      height: auto;
      padding-right: 7px;
    }
  }
  .dropdowncontent {
    position: absolute;
    z-index: 10;
    top: 110%;
    border-radius: 10px;
    background: #1b1e2f;
    border: 1px solid rgba(112, 112, 112, 0.26);
    text-align: left;
    padding: 10px 10px;
    width: 100%;
    color: white;
    max-height: 200px;
    overflow-y: auto;
    text-transform: capitalize;
    scrollbar-width: thin;
    scrollbar-color: #e48d0f #555;
  }
  .dropdowncontentPaddingZero {
    padding: 0 !important;
  }

  .dropdownImg {
    padding: 0px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    column-gap: 0.7rem;
    text-transform: capitalize;
    border-bottom: 1px solid #40a0dc2f;
    img {
      height: 20px;
      // width: 100%;
      text-align: right;
    }
    .dropdownitems {
      padding: 12px 20px;
      cursor: pointer;
      text-transform: capitalize;
      color: white;
      box-sizing: border-box;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
      font-size: 15px;
    }
  }

  .dropdownImg:hover {
    background-color: #40a1dc;
    color: white;
    font-weight: 600;
  }
}
