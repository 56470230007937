.container {
  width: 100%;
  display: flex;
  justify-content: center;
  .wrapper {
    width: 100%;
    height: 50vh;
    overflow: auto;
    @media screen and (min-width: 1024px) {
    }
    .noData {
      width: 200px;
      margin-top: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .loaderPosition {
      height: 50vh;
      display: flex;
      align-items: center;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 25px;
      padding-right: 10px;
      .loaderPosition {
        height: 50vh;
        display: flex;
        align-items: center;
      }

      tr {
        // height: 80px;

        td {
          background: #191c2c;

          padding-left: 1rem;
          padding-right: 1rem;
          // padding: 1rem;
          font-size: 14px;

          height: 80px;
          text-align: center;

          .tabelWrapper {
            display: flex;
            align-items: center;
            column-gap: 2rem;
            text-align: left;

            .innerWrapper {
              span {
                font-weight: normal;
                // white-space: nowrap;
                font-size: 16px;
                color: #ffffff;
              }
              .memoWrapper {
                display: flex;
                margin-top: 2px;
                p {
                  font-weight: 700;
                }
                span {
                  margin-left: 4px;
                }
              }
            }
          }

          .bottomtabelWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            column-gap: 2rem;
            text-align: right;
            .imgWithName {
              display: flex;
              column-gap: 5px;
              white-space: nowrap;
            }
            img {
              width: 20px;
              height: 20px;
            }

            span {
              font-weight: normal;
              font-size: 16px;
              color: #fcfcfd;
            }
          }
          &:nth-child(2) {
            justify-content: flex-end;
            display: flex;
            height: 80px;
            text-align: right !important;
          }
        }
      }

      td:first-child {
        padding-top: 0.2rem;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      td:last-child {
        padding-top: 1rem;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
}
