.container {
  width: 100% !important;
  height: 300px;

  .wrapper {
    // overflow: hidden;  \
    border-radius: 23px;
    background: transparent;
    backdrop-filter: blur(13px);
    --webkit-backdrop-filter: blur(13px);
    background-color: rgba(255, 255, 255, 0.15) !important;
    margin: 1rem 0rem;
    font-weight: lighter;
  }
}
