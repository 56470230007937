.container {
  width: 100%;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      height: 110vh;
      @media only screen and (min-width: 700px) and (max-width: 1130px) {
        height: 75vh;
      }
      @media only screen and (min-width: 300px) and (max-width: 700px) {
        height: 60vh;
      }
      @media only screen and (min-width: 1500px) and (max-width: 2030px) {
        height: 75vh;
      }
    }
      

    .imgClass {
      position: relative;
      top: -245px;
      right: -55px;
      z-index: 0;
      width: 80px;
    }
    .card {
      display: flex;
      position: relative;
      box-sizing: border-box;
      .textWrapper {
        margin: 2rem 0;
        z-index: 20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 8rem 8rem 8rem 8rem;
        box-sizing: border-box;
        background: transparent;
        backdrop-filter: blur(50px);
        --webkit-backdrop-filter: blur(50px);
        background-color: rgba(255, 255, 255, 0.05);

        .heading {
          font-weight: 600;
          font-size: 50px;
          letter-spacing: 0.1em;
          text-align: left;
          color: #fff;
          padding-bottom: 1rem;
        }
        label {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          column-gap: 2rem;
          padding: 1rem 1rem;
         
          &:nth-child(3){
            column-gap: 2.2rem;
          }
          &:nth-child(4){
            column-gap: 2.2rem;
          }
        }
      }
      .imgClass {
        position: relative;
        top: 245px;
        right: 55px;
        z-index: 0;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .container {
    .wrapper {
      .img {
        width: 75%;
        align-items: center;
      }
      .card {
        .textWrapper {
          padding: 2rem 3rem 2rem 3rem;

          .heading {
            font-weight: 600;
            font-size: 40px;
            letter-spacing: 0.1em;
            text-align: left;
            color: #fff;
            padding-bottom: 1rem;
          }
          label {
            display: flex;
            flex-direction: row;
            // align-items: center;
            column-gap: 1rem;
            padding: 1rem 1rem;
            &:nth-child(3){
              column-gap: 1.2rem;
            }
            &:nth-child(4){
              column-gap: 1.2rem;
            }
          }
        }
        .dColor {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    .wrapper {
      .img {
        width: 75%;
      }
      .card {
        .textWrapper {
          padding: 4rem 8rem 4rem 8rem;

          .heading {
            font-weight: 600;
            font-size: 40px;
            letter-spacing: 0.1em;
            text-align: left;
            color: #fff;
            padding-bottom: 1rem;
          }
          label {
            display: flex;
            flex-direction: row;
            // align-items: center;
            column-gap: 1rem;
            padding: 1rem 1rem;
            &:nth-child(3){
              column-gap: 1.2rem;
            }
            &:nth-child(4){
              column-gap: 1.2rem;
            }
          }
        }
        .dColor {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 1180px) {
  .container {
    .wrapper {
      .img {
        width: 40%;
      }
    }}
  }
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    .wrapper {
      .imgClass {
        position: relative;
        top: -225px;
        right: -55px;
        z-index: 0;
      }
      .card {
        .textWrapper {
          margin: 2rem 0;
          padding: 7rem 5rem 7rem 5rem;
          .heading {
            font-weight: 600;
            font-size: 45px;
          }
          label {
            display: flex;
            flex-direction: row;
            // align-items: center;
            column-gap: 1rem;
            padding: 1rem 1rem;
            &:nth-child(3){
              column-gap: 1.2rem;
            }
            &:nth-child(4){
              column-gap: 1.2rem;
            }
          }
        }
        .imgClass {
          position: relative;
          top: 225px;
          right: 55px;
          z-index: 0;
        }
      }
    }
  }
}
