.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1750px;
    row-gap: 1.5rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;
    @media screen and (max-width: 1024px) {
      padding: 1rem 1rem;
    }
    .mainHeading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      column-gap: 2.5rem;
      width: 100%;
      font-weight: bold;
      font-size: 32px;
      text-align: left;
      color: #fff;

      img {
        width: 40px;
        text-align: center;
        cursor: pointer;
      }
      @media screen and (max-width: "600px") {
        font-size: 22px;
        column-gap: 1rem;

        img {
          height: 20px;
        }
      }
    }
    .tableContainer {
      overflow-x: auto;
      .tableWrapper {
        min-width: 1007px;
      }
    }
  }
}
