.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 1.5rem;
  .topWrapper {
    display: flex;
    width: 100%;
    max-width: 1750px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 2rem 0;
    img {
      cursor: pointer;
      width: 35px;
    }
  }

  .wrapper {
    width: 100%;
    max-width: 1750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding: 3rem 0 0 0;
    .textWrapper {
      width: 90%;
      text-align: center;
      .heading {
        font-weight: bold;
        font-size: 36px;
        text-align: center;
        color: #fff;

        img {
          width: 29px;
        }
      }
      p {
        margin-top: 20px;
      }
    }

    .swapSection {
      display: flex;
      flex-direction: row;
      padding-top: 2rem;
      width: 75%;
      justify-content: center;
      column-gap: 2rem;
      align-items: center;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .leftSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 1rem;

        .headings {
          display: flex;
          justify-content: space-between;

          .left {
            row-gap: 1rem;
            display: flex;
            flex-direction: column;

            label {
              font-weight: normal;
              font-size: 18px;
              white-space: nowrap;
              letter-spacing: 0.1em;
              text-align: left;
              color: #fff;
              opacity: 0.58;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;

              @media screen and (max-width: 1024px) {
                font-size: 15px;
              }
            }

            .coinSection {
              display: flex;
              column-gap: 1rem;
              align-items: center;
              cursor: pointer;

              .btcImg {
                height: 35px;

                @media screen and (max-width: 1024px) {
                  height: 25px;
                }
              }

              p {
                font-weight: bold;
                font-size: 18px;
                letter-spacing: 0.1em;
                text-align: center;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;

                @media screen and (max-width: 1024px) {
                  font-size: 15px;
                }
              }

              .rightImg {
                width: 9px;

                @media screen and (max-width: 1024px) {
                  height: 10px;
                }
              }
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            row-gap: 1.3rem;

            label {
              font-weight: normal;
              font-size: 18px;
              letter-spacing: 0.1em;
              text-align: right;
              color: #fff;
              opacity: 0.58;

              @media screen and (max-width: 1024px) {
                font-size: 14px;
              }
            }

            p {
              font-weight: bold;
              font-size: 18px;
              letter-spacing: 0.1em;
              text-align: right;
              color: #fff;

              @media screen and (max-width: 1024px) {
                font-size: 15px;
              }
            }
          }
        }

        .btns {
          width: 100%;
          height: 44px;
          border-radius: 7px;
          background: #1b1e2f;
          display: flex;
          column-gap: 1rem;
          justify-content: center;
          align-items: center;

          .notActivebutton {
            width: 25%;
            height: 30px;
            border-radius: 7px;
            background: transparent;
            color: white;
            border: none;
            outline: none;
            cursor: pointer;
            transition: 0.5s;
          }

          .Activebutton {
            width: 25%;
            height: 30px;
            border-radius: 7px;

            color: white;
            border: none;
            outline: none;
            cursor: pointer;

            transition: 1s;
            background: #40a1dc;
          }
        }

        .inputHeadings {
          .inputWrapper {
            width: 100%;
            height: 59px;
            border-radius: 7px;
            background: #1b1e2f;
            display: flex;
            justify-content: space-between;
            column-gap: 1rem;
            align-items: center;
            box-sizing: border-box;
            padding: 0 1rem;

            @media screen and (max-width: 1024px) {
              justify-content: space-around;
            }

            input {
              background: transparent;
              border: none;
              outline: none;
              color: white;
              height: 59px;

              &::placeholder {
                font-weight: normal;
                font-size: 16px;
                text-align: left;
                color: #7f819a;
              }
            }

            .inputHeadings {
              label {
                font-weight: 500;
                font-size: 18px;
                text-align: right;
                color: #fff;

                @media screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }

              p {
                font-weight: normal;
                font-size: 12px;
                text-align: right;
                color: #fff;
              }
            }
          }

          p {
            padding-top: 10px;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 520px;
            width: 100%;
            font-size: 14px;
            text-align: right;
            color: #9b9b9b;

            @media screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }

      .centerSection {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1024px) {
          flex-direction: row;
        }

        .borderClass {
          width: 5px;
          border-radius: 1.5px;
          background-color: rgba(255, 255, 255, 0.123);
          height: 100px;
          border-radius: 50px;

          @media screen and (max-width: 1024px) {
            width: 100px;
            height: 5px;
          }
          .rightImg {
            width: 9px;

            @media screen and (max-width: 1024px) {
              height: 10px;
            }
          }
        }

        img {
          width: 80px;
          padding: 1rem;

          @media screen and (max-width: 1024px) {
            transform: rotate(90deg);
          }
        }
      }

      .rightSection {
        display: flex;
        width: 100%;
        flex-direction: column;
        row-gap: 1rem;
        padding-bottom: 5.1rem;

        @media screen and (max-width: 1024px) {
          padding-bottom: 0rem;
        }

        .headings {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            row-gap: 1rem;
            display: flex;
            flex-direction: column;

            label {
              font-weight: normal;
              font-size: 18px;
              letter-spacing: 0.1em;
              text-align: left;
              white-space: nowrap;
              color: #fff;
              opacity: 0.58;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;

              @media screen and (max-width: 1024px) {
                font-size: 15px;
              }
            }

            .coinSection {
              display: flex;
              column-gap: 1rem;
              align-items: center;
              text-align: center;
              cursor: pointer;

              .ETHImg {
                height: 35px;

                @media screen and (max-width: 1024px) {
                  height: 25px;
                }
              }

              p {
                font-weight: bold;
                font-size: 18px;
                letter-spacing: 0.1em;
                text-align: center;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;

                @media screen and (max-width: 1024px) {
                  font-size: 15px;
                }
              }

              .rightImg {
                width: 9px;
                text-align: center;

                @media screen and (max-width: 1024px) {
                  height: 10px;
                }
              }
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            row-gap: 1.3rem;

            label {
              font-weight: normal;
              font-size: 18px;
              letter-spacing: 0.1em;
              text-align: right;
              color: #fff;
              opacity: 0.58;

              @media screen and (max-width: 1024px) {
                font-size: 14px;
              }
            }

            p {
              font-weight: bold;
              font-size: 18px;
              letter-spacing: 0.1em;
              text-align: right;
              color: #fff;

              @media screen and (max-width: 1024px) {
                font-size: 15px;
              }
            }
          }
        }

        .inputWrapper {
          width: 100%;
          height: 59px;
          border-radius: 7px;
          background: #1b1e2f;
          display: flex;
          justify-content: space-between;
          column-gap: 1rem;
          align-items: center;
          box-sizing: border-box;
          padding: 0 1rem;

          @media screen and (max-width: 1024px) {
            justify-content: space-around;
          }

          input {
            background: transparent;
            border: none;
            outline: none;
            color: white;
            height: 59px;

            &::placeholder {
              font-weight: normal;
              font-size: 16px;
              text-align: left;
              color: #7f819a;
            }
          }

          .inputHeadings {
            label {
              font-weight: 500;
              font-size: 18px;
              text-align: right;
              color: #fff;

              @media screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }

            p {
              font-weight: normal;
              font-size: 12px;
              text-align: right;
              color: #fff;
            }
          }
        }
      }
    }

    .SwapButton {
      width: 20%;
      color: white;
      height: 65px;
      border-radius: 10px;
      background: #40a1dc;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
      border: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #fff;
      transition: 0.5s;

      &:hover {
        transition: 0.5s;
        background: #1c587e;
        border: 1px solid #1c587e;
        color: #fff;
        border: 1px solid #1c587e;
      }

      @media screen and (max-width: 1024px) {
        width: 75%;
        height: 55px;
        font-size: 15px;
        margin-bottom: 2rem;
      }
    }
  }
}
