.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../../Assets/backgrounds/Aboutbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // height: auto;
  padding: 3rem 0rem;
  // height: 50vh;
  .wrapper {
    max-width: 1500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    column-gap: 2rem;
    padding: 0rem 6rem;
    .card {
      width: 30%;
      height: auto;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.425);
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading {
        font-weight: bold;
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        opacity: 0.9;
      }
      .subHeading {
        font-weight: 500;
        font-size: 26px;
        line-height: 60px;
        text-align: center;
        color: #fff;
      }
      .subHeading2 {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .container {
    width: 100%;
    .wrapper {
      .card {
        width: 30%;

        .heading {
          font-weight: bold;
          font-size: 50px;
          line-height: 50px;
          text-align: center;
          color: #fff;
          opacity: 0.9;
        }
        .subHeading {
          font-weight: 500;
          font-size: 20px;
          line-height: 50px;
          text-align: center;
          color: #fff;
        }
        .subHeading2 {
          font-weight: normal;
          font-size: 13px;
          line-height: 22px;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 0rem;
    .wrapper {
      row-gap: 2rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0rem 0rem;
      .card {
        width: 65%;
        .heading {
          font-weight: bold;
          font-size: 45px;
          line-height: 45px;
          text-align: center;
          color: #fff;
          opacity: 0.9;
        }
        .subHeading {
          font-weight: 500;
          font-size: 25px;
          line-height: 45px;
        }
        .subHeading2 {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .container {
    .wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 2rem;
      padding: 0rem 0rem;
      .card {
        width: 80%;
        .heading {
          font-weight: bold;
          font-size: 40px;
          line-height: 45px;
          text-align: center;
          color: #fff;
          opacity: 0.9;
        }
        .subHeading {
          font-weight: 500;
          font-size: 22px;
          line-height: 45px;
        }
        .subHeading2 {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
  }
}
