.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1750px;

    .innerWrapper {
      width: 100%;
    }
  }
}

.btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0;
  }
}



.btnImg {
  cursor: pointer;
  opacity: 1;


}