.container {
  width: 100%;
  padding-right: 20px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  // min-width: 550px;
  @media screen and (max-width: 1124px) {
    padding: 20px;
  }
  .wrapper {
    width: 100%;
    // max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // row-gap: 20px;

    .noData {
      max-width: 150px;
      margin-top: 40px;
    }
    .heading {
      width: 100%;
      padding: 0px 0px 10px 0;
      display: flex;
      // flex-direction: column;
      column-gap: 40px;

      img {
        width: 11px;
        cursor: pointer;
      }

      p {
        font-weight: 500;
        font-size: 30px;
      }

      @media screen and (max-width: 660px) {
        p {
          font-size: 20px;
        }
      }
    }

    .wrapper {
      width: 100%;
      overflow-x: auto;
      display: flex;
      align-items: flex-start;
      // justify-content: center;
      table {
        width: 100%;
        overflow-x: auto;
        border-collapse: collapse;
        text-align: left;
        // margin-top: -50px;
        border-collapse: separate;
        border-spacing: 0px 1rem;
        // position: relative;

        .emptyImg {
          position: absolute;
          top: 30%;
          left: 40%;
          bottom: 30%;
        }

        tr {
          text-align: left;
          margin-bottom: 30px;
          overflow: auto;
          border-radius: 10px;
          td {
            padding: 0 9px;
            .nameWrapper {
              display: flex;
              column-gap: 1rem;
              justify-content: flex-start;
              text-align: left;
              align-items: center;
              .green {
                color: rgb(106, 205, 106);
              }
              .red {
                color: rgb(180, 41, 41);
              }
              .purple {
                color: rgb(207, 180, 61);
              }
              img {
                // background: #fff;
                width: 40px;
                height: 40px;
                border-radius: 5px;
              }
              .symbol {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                img {
                  width: 15px;
                  height: 15px;
                }
              }
            }
            .estApyWrapper {
              img1 {
                // filter: invert(100%) sepia(54%) saturate(570%) hue-rotate(206deg) brightness(118%) contrast(108%);
                // border: 1px solid red;
              }
              .img2 {
                filter: invert(45%) sepia(99%) saturate(513%) hue-rotate(142deg)
                  brightness(100%) contrast(98%);
              }
            }
            .firstimage {
              display: flex;
              align-items: center;
              column-gap: 0.5rem;

              img {
                width: 35px;
              }

              label {
                color: rgba(255, 255, 255, 0.514);
              }
            }

            .bottomimage {
              display: flex;
              align-items: center;
              column-gap: 0.3rem;
            }
          }
        }

        td:first-child {
          padding-left: 2rem;
          padding: 1rem 1rem;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        tr:first-child {
          font-weight: 400;
          height: 18px;
          border-radius: 5px;
        }
        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        tr:not(:first-child) {
          font-weight: 500;
          height: 75px;
          border-radius: 5px;
          background: #1b1e2f;

          .description {
            font-weight: normal;
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-top: 0px;
          }
          .changedDescription {
            font-size: 12px;
            margin-top: 10px;
            font-weight: normal;
            width: 350px;
          }
          :nth-child(7) {
            :not(:first-child) {
              color: rgba(255, 255, 255, 0.438);

              padding-left: 2.1rem;
            }
          }
        }
      }
    }
  }
}
