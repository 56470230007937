.container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
  display: flex;
  overflow-x: hidden;
  overflow: hidden;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;

    .coinimage {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        height: auto;
        width: 70%;

        @media screen and (max-width: "1024px") {
          width: 50%;
        }
      }
    }

    .textWrapper {
      display: flex;
      flex-direction: column;

      .heading {
        font-weight: 600;
        font-size: 21px;
        line-height: 74px;
        text-align: center;
        color: #fff;
      }

      .price {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        column-gap: 10px;

        img {
          width: 30px;
          height: 30px;
        }

        p {
          font-size: 30px;
        }
      }

      .subHeading {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;

        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
          height: 100%;
          min-height: 150px;
        }

        button {
          padding: 10px 25px;
          border: 1px solid #40a1dc;
          outline: none;
          background-color: #40a1dc;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
          font-weight: 600;
          font-size: 13px;
          transition: 0.5s;

          &:hover {
            background-color: transparent;
            border: 1px solid #40a1dc;
            color: #40a1dc;
          }
        }
      }
    }
  }
}

.popupContainer {
  width: 100%;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 2rem;

  h1 {
    color: #40a1dc;
    width: 100%;
  }

  p {
    font-size: 16px;
    color: black;
  }
}
