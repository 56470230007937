.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .wrapper {
        width: 100%;
        max-width: 1750px;
        padding: 1rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;



        @media screen and (max-width: 1024px) {
            padding: 1rem 1rem;
        }

        .topWrapper {
            width: 68%;
            display: flex;
            align-items: center;
            padding-bottom: 0rem;

            @media screen and (max-width:600px) {
                width: 90%;
            }

            img {
                height: 20px;
                cursor: pointer;
            }

            label {
                width: 100%;
                text-align: center;
                // font-family: Montserrat;
                font-weight: 600;
                font-size: 34px;
                // text-align: left;
                color: #fff;

                @media screen and (max-width: 1024px) {
                    font-size: 20px;
                }

            }
        }

        .pera {
            width: 100%;
            padding: 0.6rem 25%;
            text-align: center;
            color: #fff;

            @media screen and (max-width:600px) {
                padding: 0.6rem 1rem;
            }
        }

        .itemWrapper {
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 70%;
            column-gap: 2rem;
            background-color: rgba(27, 30, 47, 0.2745098039);
            box-shadow: 2px 2px 40px 5px rgba(33, 42, 93, 0.548);
            border-radius: 20px;
            padding: 1rem;

            @media screen and (max-width:600px) {
                width: 95%;
            }

            .inpuwrapper {
                display: flex;
                column-gap: 1rem;
                row-gap: 1rem;
                flex-wrap: wrap;


            }

            button {

                margin-top: 1rem;
                width: 200px;
                outline: none;
                padding: 10px 28px;
                background-color: #40a1dc;
                border-radius: 5px;
                border: 1px solid #40a1dc;
                cursor: pointer;
                transition: 0.5s;
                font-weight: 500;
                font-size: 15px;
                color: #fff;

                @media screen and (max-width:600px) {
                    width: 100%;
                }

                &:hover {
                    background-color: transparent;
                    border: 1px solid #40a1dc;
                }
            }
        }
    }


}