@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap");
@font-face {
  font-family: "CQRTokenSymbols";
  src: local("CQRTokenSymbols"),
    url("../src/Assets/fonts/TokenSymbols.ttf") format("truetype");
}
* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111422;

  color: white;
  background-image: url(./Assets/backgrounds/bgTop.png),
    url(./Assets/backgrounds/bgLeft.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: top -350px right -350px, top 350px left -450px;
}
a {
  text-decoration: none;
  color: white;
}

/* .react-datepicker-ignore-onclickoutside{
  border :1px solid red !important;
  color: red !important;
} */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: red;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 0px !important;
  top: 5px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background-color: white;
  outline: none;
}

#react-burger-cross-btn {
  outline: none;
}

/*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
.bm-menu-wrap {
  position: fixed;
  top: 0;
  width: 220px !important;
  height: 100%;
  background-color: rgb(26, 16, 85, 0.644) a;
}

/* General sidebar styles */
.bm-menu {
  background: #111422;
  padding: 2.5em 1.5em 0;
  padding: 0;
  /* width: 230px; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0 !important;
  color: #ffffff;
  padding: 0.8em;
  outline: none;
  font-size: 20px;
  display: flex;
  row-gap: 20px;
  font-weight: 400;
  box-sizing: border-box;
}

/* Individual item */
.bm-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
}
.bm-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Styling of overlay */
.bm-overlay {
  background: transparent;
  backdrop-filter: blur(4px);
  top: 0;
}

.navItems {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000;
}
/* Burger menu ended */
.bBjxWM {
  box-sizing: border-box;
  padding: 0px;
  transition: all 250ms ease 0s;
  border: none;
  margin: 5px;
  background-color: #40a1dc;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: #40a1dc;
  border-radius: 50%;
  outline: none;
}
.dropdown-menu {
  position: absolute;
  top: 130px;
  list-style: none;
  text-align: start;
  background: #2b2b2a;
  right: 0px;
  width: 60%;
  padding: 40px 0px;
  height: 560px;
}
.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  font-family: "DM Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 19.2px;
  text-align: right;
  color: #fff;
}

.bm-item {
  width: 150px !important;
  box-sizing: border-box;
  height: 0% !important;
}
.mainlist {
  margin-top: 5px !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.mainlist > ul > li {
  list-style-type: none;
  font-size: 17px;
  padding: 8px 0px 10px 0px !important;
}
.mainlist > ul > li > a {
  color: #40a1dc !important;
}
.mainlist > ul {
  display: none;
}
.headerLogo img {
  padding: 1.1rem 0;
  width: 50%;
  height: 50%;
}
/* slider */
/* .rec.rec-arrow { */
/* border-radius: 0; */
/* background-color: transparent !important;
  color: #fff !important;

} */
/* .rec.rec-arrow:hover { */
/* border-radius: 50%; */
/* background-color: transparent !important;
  color: #40a1dc !important;

} */
/* .rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
  background-color: transparent !important;
  color: #40a1dc !important;
} */
/* .fViXLK {
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  background-color: #40a1dc !important;
  color: #fff !important;
  box-shadow: 0 0 2px 0px #40a0dccb !important;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
} */
/* .fVgyLU {
  box-sizing: border-box;
  transition: all 0.3s ease 0s;
  font-size: 1.6em;
  background-color: #42718fcb !important;
  color: rgb(51, 51, 51);
  box-shadow: rgb(51 51 51) 0px 0px 2px 0px;
  border-radius: 50%;
  border: none;
  padding: 0px;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  align-self: center;
  cursor: pointer;
  outline: none;
} */
.gpDNSX {
  box-sizing: border-box;
  padding: 0px;
  transition: all 250ms ease 0s;
  border: none;
  margin: 5px;
  background-color: rgb(51, 51, 51) !important;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 1px 2px;
  border-radius: 50%;
  outline: none;
}
.gpDNSX:hover,
.gpDNSX:focus {
  cursor: pointer;
  box-shadow: #fff 0px 0px 1px 3px !important;
}

.gpDNSX:hover,
.gpDNSX:focus {
  cursor: pointer;
  box-shadow: #fff 0px 0px 1px 3px !important;
}
/* .bBjxWM {
  box-sizing: border-box;
  padding: 0px;
  transition: all 250ms ease 0s;
  border: none;
  margin: 5px;
  background-color: #40a1dc !important;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: #40a1dc 0px 0px 1px 3px !important;
  border-radius: 50%;
  outline: none;
}
.fVgyLU:hover:enabled,
.fVgyLU:focus:enabled {
  color: rgb(255, 255, 255);
  background-color: #40a1dc !important;
  box-shadow: rgb(51 51 51) 0px 0px 2px 0px;
}
.fVgyLU:hover:enabled,
.fVgyLU:focus:enabled {
  color: rgb(255, 255, 255);
  background-color: #40a1dc !important;
  box-shadow: rgb(51 51 51) 0px 0px 2px 0px;
} */

/*Dev Slider*/
/* button.sc-bdvvtL.jRFJkP.rec.rec-arrow.rec.rec-arrow {
  background-color: #40a1dc;
}
button.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-left {
  background-color: #40a1dc;
}
button.sc-bdvvtL.cNNJIt.rec.rec-arrow.rec.rec-arrow-right {
  background-color: #40a1dc;
} */
button.sc-gKclnd.eaptOe.rec.rec-dot {
  background-color: #ffffff;
}
button.sc-gKclnd.lcUbOd.rec.rec-dot.rec.rec-dot_active {
  background-color: #40a1dc;
  box-shadow: 0 0 1px 3px #40a1dc;
}
/*Dev Slider*/

/*input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/**/
/*scrollBar*/
::-webkit-scrollbar {
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto !important;
  background: #40a1dc !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar:vertical {
  width: 5px !important;
}

::-webkit-scrollbar:horizontal {
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: #40a1dc !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-track {
  background: #555 !important;
  border-radius: 10px !important;
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 7px;
} */

/* ::-webkit-scrollbar-track {
  background: #555;
  border-radius: 10px;
} */

/* ::-webkit-scrollbar-thumb {
  background: #40a1dc;
  border-radius: 10px;
} */

::-webkit-scrollbar-thumb:hover {
  background: #40a1dc;
}
/*scrollBar*/

/* Loading Bar */
/* .pulse {
  height: 100px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.pulse::after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200px 100px" enable-background="new 0 0 200px 100px" xml:space="preserve"><polyline fill="none" stroke-width="3px" stroke="white" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>')
    0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 2s pulse linear infinite;
  -moz-animation: 2s pulse linear infinite;
  -o-animation: 2s pulse linear infinite;
  animation: 2s pulse linear infinite;
  clip: rect(0, 0, 100px, 0);
}
.pulse::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;
}

@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
    opacity: 0.4;
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
    opacity: 0.6;
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
    opacity: 0.8;
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }

  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  90% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }

  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
} */
/* Loading Bar */
.CQRloader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.CQRloader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #a5a6a7;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.CQRloader div:nth-child(1) {
  left: 8px;
  animation: CQRloader1 0.6s infinite;
}
.CQRloader div:nth-child(2) {
  left: 8px;
  animation: CQRloader2 0.6s infinite;
}
.CQRloader div:nth-child(3) {
  left: 32px;
  animation: CQRloader2 0.6s infinite;
}
.CQRloader div:nth-child(4) {
  left: 56px;
  animation: CQRloader3 0.6s infinite;
}
@keyframes CQRloader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes CQRloader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes CQRloader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/****/
.react-tel-input .selected-flag {
  background-color: #1b1e2f !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #12131d !important;
}
.react-tel-input .country-list .country {
  padding: 15px 15px !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #12131d !important;
}
.react-tel-input .country-list {
  width: 350px !important;
}
@media (max-width: 700px) {
  .react-tel-input .country-list {
    width: 220px !important;
  }
}
/****/
