.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  .wrapper {
    max-height: 625px;
    @media screen and (max-width: 1500px) {
      max-height: 530px;
    }
    width: 100%;
    max-width: 1750px;
    display: flex;
    justify-content: center;
    align-self: center;

    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.5rem 0;
    .cardwrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border-radius: 16px;

      .iconImg {
        width: 100%;
        text-align: left;
        padding: 30px 0px 0px 30px;

        img {
          height: 20px;
          cursor: pointer;
        }
      }
      .headingWrapper {
        justify-content: center;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin: 1rem 2rem 2rem 2rem;
        @media screen and (max-width: 600px) {
          margin: 0rem;
        }
        label {
          font-weight: 600;
          font-size: 30px;
          text-align: center;
          color: #fff;
          @media screen and (max-width: 600px) {
            font-size: 25px;
          }
          @media screen and (min-width: 600px) and (max-width: 700px) {
            font-size: 28px;
          }
        }
        .Selection {
          display: flex;
          flex-direction: column;
          width: 100%;
          @media screen and (max-width: 1024px) {
            width: 100%;

            align-self: center;
          }

          img {
            height: 30px;
            width: 30px;
          }
        }
        img {
          height: 150px;
          @media screen and (max-width: 600px) {
            height: 70px;
          }
        }
        span {
          font-weight: 300;
          font-size: 26px;
          text-align: center;
          color: #fff;
          @media screen and (max-width: 600px) {
            font-size: 22px;
          }
        }
        .inputWrapper {
          width: 100%;
          height: 60px;
          border-radius: 12px;
          background: #161824;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-self: center;
          padding: 0 1rem;
          @media screen and (max-width: 600px) {
            width: 80%;
          }
          @media screen and (min-width: 600px) and (max-width: 700px) {
            width: 80%;
          }
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
            font-size: 20px;
            @media screen and (max-width: 600px) {
              font-size: 18px;
            }
          }
          img {
            width: 20px;
            cursor: pointer;
            @media screen and (max-width: 600px) {
              width: 20px;
            }
          }
        }
        h2 {
          font-weight: 500;
          font-size: 22px;
          letter-spacing: 0.01em;
          text-align: center;
          color: #fff;
          opacity: 0.8;
          @media screen and (max-width: 600px) {
            font-size: 20px;
          }
        }
        ul {
          font-weight: normal;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          font-size: 18px;
          letter-spacing: 0.01em;
          // text-align: center;
          color: #fff;
          opacity: 0.5;
          padding-bottom: 1.5rem;

          li {
            list-style-type: circle;
            margin-left: 1rem;
          }
          @media screen and (max-width: 600px) {
            word-wrap: break-word;
            font-size: 13px;
            padding: 0 2.5rem 2.5rem 2rem;
            letter-spacing: unset;
          }
          @media screen and (min-width: 600px) and (max-width: 700px) {
            word-wrap: break-word;
            font-size: 16px;
            padding: 0 2.5rem 2.5rem 2rem;
            letter-spacing: unset;
          }
        }
      }
    }
  }
}
