.container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  .wrapper {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;

    .heading {
      width: 100%;
      padding: 50px 0px 10px 0px;
      display: flex;
      justify-content: flex-end;
      .editprofile {
        width: 10rem;
        height: 3rem;
        border: 1px solid #40a1dc;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        border-radius: 5px;
        p {
          color: #40a1dc;
        }
      }
    }
    .progressbar {
      width: 100%;
      height: 100px;
      display: flex;
      .instructions {
        width: 15%;
        .box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #474747;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;

            text-align: center;
            label {
              font-size: 10px;
              color: #40a1dc;
            }
          }
        }
      }
      .active {
        width: 15%;
        .box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #40a1dc;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                filter: invert(66%) sepia(2%) saturate(5796%) hue-rotate(99deg)
                  brightness(820%) contrast(111%);
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;
            text-align: center;
            label {
              font-size: 10px;
              color: #40a1dc;
            }
          }
        }
      }
      .gpalevel {
        width: 26%;
        display: flex;
        .innerprogressbar {
          width: 40%;
          .bar {
            margin-top: 30px;
            width: 90%;
            height: 2px;
            background-color: #474747;
          }
        }
        .box {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #474747;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;
            text-align: center;
            label {
              font-size: 10px;
              color: #4d4e52;
            }
          }
        }
      }
      .inneractive {
        width: 26%;
        display: flex;
        .innerprogressbar {
          width: 40%;
          .bar {
            margin-top: 30px;
            width: 90%;
            height: 2px;
            background-color: #40a1dc;
          }
        }
        .box {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #40a1dc;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                filter: invert(66%) sepia(2%) saturate(5796%) hue-rotate(99deg)
                  brightness(820%) contrast(111%);
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;
            text-align: center;
            label {
              font-size: 10px;
              color: #40a1dc;
            }
          }
        }
      }
      .beneficiary {
        width: 26%;
        display: flex;
        .innerprogressbar {
          width: 40%;
          .bar {
            margin-top: 30px;
            width: 90%;
            height: 2px;
            background-color: #474747;
          }
        }
        .box {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #474747;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;
            text-align: center;
            label {
              font-size: 10px;
              color: #4d4e52;
            }
          }
        }
      }
      .importantnote {
        width: 26%;
        display: flex;
        .innerprogressbar {
          width: 40%;
          .bar {
            margin-top: 30px;
            width: 90%;
            height: 2px;
            background-color: #474747;
          }
        }
        .box {
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          cursor: pointer;
          .Circlesection {
            width: 100%;
            display: flex;
            justify-content: center;
            .circle {
              width: 30px;
              height: 30px;
              background-color: #393a3d;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
              }
            }
          }
          .role {
            height: 30%;
            width: 100%;
            text-align: center;
            label {
              font-size: 10px;
              color: #4d4e52;
            }
          }
        }
      }
    }
    .bttnsection {
      width: 100%;
      display: flex;
      padding: 20px 70px 20px 0px;
      column-gap: 10px;
      justify-content: space-between;

      .nextbutton {
        outline: none;
        padding: 10px 28px;
        background-color: #40a1dc;
        border-radius: 5px;
        border: 1px solid #40a1dc;
        cursor: pointer;
        transition: 0.5s;
        font-weight: 500;
        font-size: 15px;
        color: #fff;
        &:hover {
          transition: 0.5s;
          background: #1c587e;
          border: 1px solid #1c587e;
          color: #fff;
          border: 1px solid #1c587e;
        }
      }
      .nextbuttons {
        visibility: hidden;
        padding: 10px 28px;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid #40a1dc;
        cursor: pointer;
        transition: 0.5s;
        font-weight: 500;
        font-size: 15px;
        color: #fff;
        &:hover {
          background-color: #40a1dc;
          border: 1px solid #40a1dc;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
    .wrapper {
      width: 80%;
      padding: 0px 0px 30px 0px;
      .progressbar {
        .instructions {
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
        .active {
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
        .gpalevel {
          .innerprogressbar {
            width: 80%;
          }
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
        .inneractive {
          .innerprogressbar {
            width: 80%;
          }
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
        .beneficiary {
          .innerprogressbar {
            width: 80%;
          }
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
        .importantnote {
          .innerprogressbar {
            width: 80%;
          }
          .box {
            .role {
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
