.container {
  width: 100%;

  // min-width: 300px;
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 5px 0px 10px 0px;

    p {
      width: 100%;
      font-weight: normal;
      font-size: 15px;
      text-align: left;
      color: rgba(255, 255, 255, 0.623);

      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      span {
        width: 20px;
        display: flex;
        position: relative;
        .status {
          width: 220px;
          display: none;
          position: absolute;
          font-size: 13px;
          top: 120%;
          right: -650%;
          z-index: 1;
          padding: 5px;
          border-radius: 5px;
          background-color: #0e0f16;
          color: #fff;
          opacity: 1;
          text-align: justify;
        }
        .exclamationMark {
          position: relative;
          font-size: 14px;
          margin-top: 3px;
          cursor: pointer;
          color: rgba(255, 255, 0, 0.74);
        }
        &:hover {
          .status {
            display: flex;
          }
        }
      }
    }

    .inputWrapper {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      background-color: #1b1e2f;
      display: flex;
      column-gap: 2rem;
      padding: 0 0.5rem;
      text-align: center;
      align-items: center;
.formfield {
  padding: 15px 20px;
  background-color: #1b1e2f !important;
  color: white !important;
}
      img {
        height: 20px;
      }

      .spanContainter {
        font-family: "CQRTokenSymbols";
        padding-right: 20px;
      }

      input {
        background: none;
        border: none;
        font-family: Arial;
        font-weight: normal;
        font-size: 15px;
        text-align: left;
        color: #fff;
        color-scheme: dark;
        outline: none;
        width: 100%;

        &::placeholder {
          color: rgba(255, 255, 255, 0.301);
        }

        img {
          width: 12px;
        }
      }

      &:focus-within {
        border: 1px solid #40a1dc;
      }
    }
  }
  .lastChild {
    width: 100%;
    .text {
      padding: 0rem 0.5rem;
      width: 100%;
      display: flex;
      font-size: 13px;
      margin-top: 0px;
      align-items: center;
      column-gap: 4px;
      justify-content: flex-end;
    }
  }
}
