.container {
  width: 100%;
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 50px;
    flex-direction: row;
    // align-items: center;
    min-height: 88vh;
    @media screen and (max-width: "900px") {
      flex-direction: column;
    }

    .cardWrapper {
      width: 45%;
      display: flex;
      flex-direction: column;
      // row-gap: 1.5rem;

      padding: 0px 0px 10px 40px;
      @media screen and (max-width: "900px") {
        width: 100%;
        padding: 0px 0px 10px 10px;
      }
      .backTo {
        width: 35px;
        cursor: pointer;
        margin-left: 15px;
        margin-top: -15px;
        margin-bottom: 12px;
      }
      .heading {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .headingWrapper {
          width: 90%;
          // text-align: center;
          font-weight: bold;
          font-size: 30px;
          display: flex;
          padding-left: 20px;
          // justify-content: center;
          // align-items: center;

          @media screen and (max-width: "600px") {
            font-size: 22px;

            img {
              width: 17px;
            }
          }
        }
        .history {
          .icon {
            font-size: 35px;
            cursor: pointer;
          }
        }
      }
      .formWrapper {
        // background-color: #1b1e2f46;
        border-right: 1px solid #ffff;

        padding: 20px;
        padding-right: 30px;
        @media screen and (max-width: "900px") {
          border-right: none;
        }
        // border-radius: 20px;
        .borderRight {
        }
        form {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;

          .inputWrapper {
            p {
              font-weight: normal;
              font-size: 15px;
              text-align: left;
              color: #fff;
              opacity: 0.6;
              padding: 0 0.5rem;
              padding-bottom: 0.5rem;
            }

            .divWrapper {
              display: flex;
              align-items: center;
              padding-left: 10px;
              width: 100%;
              height: 50px;
              border-radius: 10px;
              background-color: #1b1e2f;
              border: none;
              font-family: Arial;
              font-weight: normal;
              font-size: 15px;
              text-align: left;
              color: #fff;
              color-scheme: dark;
              outline: none;
              width: 100%;

              img {
                width: 13px;
                margin-bottom: 3px;
              }

              &::placeholder {
                color: white;
                padding-left: 1rem;
              }
            }
            .avbalance {
              padding: 0 0.5rem;
              margin-top: 5px;
              text-align: end;
              font-size: 13px;
            }
          }

          .btn {
            margin-top: 0.5rem;
            display: flex;
            align-self: center;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            width: 100%;
            height: 45px;
            border-radius: 8px;
            background: #40a1dc;
            font-weight: bold;
            font-size: 17px;
            text-align: center;
            color: #fff;
            cursor: pointer;
            transition: 1s;

            &:hover {
              transition: 0.5s;
              background: #1c587e;
            }
          }

          .inputWrapper {
            p {
              font-weight: normal;
              font-size: 15px;
              text-align: left;
              color: #fff;
              opacity: 0.6;
              padding: 0 0.5rem;
              padding-bottom: 0.5rem;
            }

            input {
              width: 100%;
              height: 50px;
              border-radius: 10px;
              background-color: #1b1e2f;
              border: none;
              font-family: Arial;
              font-weight: normal;
              font-size: 15px;
              text-align: left;
              color: #fff;
              color-scheme: dark;
              outline: none;
              width: 100%;
              padding-left: 1rem;

              &::placeholder {
                color: white;
                padding-left: 1rem;
              }
            }
          }
        }
      }
      .details {
        width: 100%;
        display: flex;
        justify-content: center;
        table {
          width: 70%;
          border-collapse: collapse;
          text-align: left;
          border-collapse: separate;
          border-spacing: 0px 1rem;
          position: relative;
          tr {
            text-align: left;
            margin-bottom: 30px;
            cursor: pointer;
            td {
              padding: 0 9px;
              .nameWrapper {
                display: flex;
                column-gap: 1rem;
                justify-content: flex-start;
                text-align: left;
                align-items: center;
                .heading {
                  margin-bottom: 10px;
                }
                .description {
                  color: rgba(255, 255, 255, 0.577);
                }
              }
            }
          }

          td:first-child {
            padding-left: 2rem;
            padding: 1rem 1rem;
          }

          tr:first-child {
            font-weight: 400;
            height: 18px;
            border-radius: 5px;
          }

          tr:not(:first-child) {
            font-weight: 500;
            height: 75px;
            border-radius: 5px;
            background: #1b1e2f;

            .description {
              font-weight: normal;
              width: 250px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              margin-top: 0px;
            }
            .changedDescription {
              font-size: 10px;
              margin-top: 10px;
              font-weight: normal;
              width: 350px;
            }
            :nth-child(7) {
              :not(:first-child) {
                color: rgba(255, 255, 255, 0.438);
                padding-left: 2.1rem;
              }
            }
          }
        }
      }
    }
    .historyWrapper {
      width: 45%;
      margin-top: 30px;
      @media screen and (max-width: "900px") {
        width: 100%;
        padding: 0px 0px 10px 10px;
      }
    }
  }
}
