.container {
  width: 100%;
  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-x: hidden;
    overflow-y: auto;
    .textWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 0;

      label {
        font-weight: bold;
        font-size: 23px;
        letter-spacing: 0.02em;
        line-height: 24px;
        text-align: center;
        color: #fff;
        margin: 0.5rem 0 1rem 0;
        @media screen and (max-width: 600px) {
          font-size: 19px;
        }
      }
      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
        padding: 10px 0;
        .innerTextWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 0.5rem;
          padding: 0px 52px;
          label {
            font-size: 20px;
            line-height: 15px;
          }
          p {
            font-weight: bold;
          }
          span {
            text-align: justify;
            color: rgba(255, 255, 255, 0.788);
            line-height: 1.5rem;
          }
        }
        .button {
          width: 80%;
          height: 70px;
          border-radius: 10px;
          background: #202336;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          color: white;
          border: none;
          outline: none;
          font-weight: normal;
          font-size: 22px;
          letter-spacing: 0.02em;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            font-size: 15px;
            height: 60px;
          }
          @media screen and (min-width: 600px) and (max-width: 750px) {
            font-size: 15px;
          }
          img {
            height: 15px;
            text-align: center;
            @media screen and (min-width: 600px) and (max-width: 750px) {
              height: 14px;
            }
            @media screen and (max-width: 600px) {
              height: 13px;
            }
          }
        }
        .buttonRemit {
          width: 80%;
          height: 70px;
          border-radius: 10px;
          border: 1px solid #40a1dc;
          background-color: #40a1dc;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          color: white;
          border: none;
          outline: none;
          font-weight: normal;
          font-size: 22px;
          letter-spacing: 0.02em;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            font-size: 15px;
            height: 60px;
          }
          @media screen and (min-width: 600px) and (max-width: 750px) {
            font-size: 15px;
          }
          img {
            height: 15px;
            text-align: center;
            @media screen and (min-width: 600px) and (max-width: 750px) {
              height: 14px;
            }
            @media screen and (max-width: 600px) {
              height: 13px;
            }
          }
          &:hover {
            transition-duration: 1s;
            border: 1px solid #196899;
            background-color: #196899;
          }
        }
      }
    }
  }
  .activeButton {
    width: 80%;
    height: 70px;
    border-radius: 10px;
    background: #202336;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
    color: white;
    border: 1px solid #40a1dc;
    // border: none;
    outline: none;
    font-weight: normal;
    font-size: 22px;
    letter-spacing: 0.02em;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      font-size: 15px;
      height: 60px;
    }
    @media screen and (min-width: 600px) and (max-width: 750px) {
      font-size: 15px;
    }
    img {
      height: 15px;
      text-align: center;
      @media screen and (min-width: 600px) and (max-width: 750px) {
        height: 14px;
      }
      @media screen and (max-width: 600px) {
        height: 13px;
      }
    }
  }
}
.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;

    p {
      font-size: 18px;
      color: #fff;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #be2539;

      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #800b1a;
        transition: 0.5s;
      }

      &:nth-last-child(2) {
        background: #40a1dc;

        &:hover {
          background: #11537c;

          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
