.container {
  width: auto;
  height: auto;

  .Wrapper {
    width: auto;
    height: auto;
    padding: 30px 20px 30px 20px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 350px;

    .CardWrapper {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      row-gap: 1rem;
      justify-content: center;

      .Card {
        width: 100px;
        padding: 0.8rem;
        height: auto;
        border-radius: 8px;
        background: #bebebe54;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
        // padding: 0px 0px 0px 10px;
        transition: 0.5s;

        img {
          height: 20px;
          margin-bottom: 10px;
        }

        .text {
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

.Card:hover {
  border: 1px solid #40a1dc;
  border-radius: 10px;
  transition: 0.9s;
}

@media only screen and (min-width: 700px) and (max-width: 1400px) {
  .container {
    width: auto;
    height: auto;

    .Wrapper {
      width: auto;
      height: auto;

      .CardWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        justify-content: center;

        .Card {
          width: 140px;

          border-radius: 8px;
          background: #bebebe54;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;

          // &:focus-within {
          //   border: 1px solid #00eaea;
          //   box-sizing: border-box;
          // }
          img {
            height: 30px;
            margin-bottom: 20px;
          }

          .text {
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }

    // .active {
    //   border: 1px solid #00eaea;
    //   box-sizing: border-box;
    // }
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    width: auto;
    height: auto;

    .Wrapper {
      width: auto;
      height: auto;

      .CardWrapper {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        justify-content: center;

        .Card {
          width: 160px;

          border-radius: 8px;
          background: #bebebe54;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;

          img {
            height: 35px;
            margin-bottom: 20px;
          }

          .text {
            font-weight: bold;
            font-size: 13px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }

    // .active {
    //   border: 1px solid #00eaea;
    //   box-sizing: border-box;
    // }
  }
}