.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  row-gap: 10px;
  .heading {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .textsection {
    width: 90%;
    text-align: center;
    line-height: 2rem;
    padding: 10px 0 0 0;
    label {
      // margin-top: 20px;
      font-size: 20px;
    }
    p {
      font-size: 20px;
      text-transform: capitalize;
    }
    .btn {
      width: 50%;
      height: 40px;
      background-color: #40a1dc;
      border-radius: 10px;
      border: none;
      color: white;
      font-weight: 400;
      transition: 0.5s;

      &:hover {
        //  background-color: #0c5c8d;
        border: 2px solid #0c5f93;
        background-color: transparent;
        cursor: pointer;
        //  font-weight: bold;
        transition: 0.7s;
      }
    }
  }
  .btnsection {
    width: 80%;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    text-align: center;
    line-height: 3rem;
    padding: 0 0 20px 0;
    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #11537c;
        transition: 0.5s;
      }

      &:nth-last-child(1) {
        background: #be2539;

        &:hover {
          background: #800b1a;
          transition: 0.5s;
        }
      }
    }
  }
}
