.container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  // margin: 2rem/;
  img {
    max-height: 100px;

    @media screen and (max-width: 600px) {
      height: 40px;
    }
  }

  label {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  p {
    // width: 598px;
    // height: 116px;
    // font-family: Montserrat;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    color: #fff;
  }

  .btndiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 5px;

    button {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      width: 100%;
      padding: 13px;
      border: 1px solid #202336;
      background-color: #202336;
      border-radius: 10px;
      height: 60px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background-color: #212642;
        border: 1px solid #212642;
        color: #fff;
      }
    }

    .cancelbtn {
      color: #fff;
      border: 1px solid #202336;
      background-color: #202336;
      cursor: pointer;

      &:hover {
        background-color: #212642;
        border: 1px solid #212642;
        color: #fff;
      }
    }

    .Qvestbtn {
      color: #fff;
      border: 1px solid #202336;
      background-color: #202336;
      cursor: pointer;

      &:hover {
        background-color: #212642;
        border: 1px solid #212642;
        color: #fff;
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.5rem;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 5px 10px 5px;
    display: flex;
    row-gap: 10px;
    p {
      font-size: 18px;
      color: #fff;
      line-height: 2rem;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #11537c;
        transition: 0.5s;
      }

      &:nth-last-child(1) {
        background: #be2539;

        &:hover {
          background: #800b1a;
          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
