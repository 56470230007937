.container {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 80vh;
  // align-items: flex-start;
  .wrapper {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px 0;
    .leftSide {
      flex: 0.5;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 18px;
      min-width: 375px;
      @media screen and (max-width:1024px) {
        width: 100%;
        flex: 1;
      }
   
      .leftHeading {
        font-size: 29px;
        font-weight: 600;
      }
      .inputField {
        width: 90%;
        display: flex;
        column-gap: 1rem;
        cursor: pointer;
      }

      .lowerInput {
        width: 90%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }

      .sugnature {
        width: 90%;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        label {
          font-size: 1.2rem;
          font-weight: bold;
        }
        p {
          font-weight: 400;
          color: lightblue;
        }
        .signaturePad {
          width: 100%;
          height: auto;
          background-color: #1b1e2f;
          border-radius: 8px;
          text-align: center;
          // margin: 10px 0px;
          img{
            width: 90%;
            height: auto;
          }
        }

        .parentButton {
          display: flex;
          column-gap: 1rem;
          justify-content: flex-end;
          button {
            border-radius: 10px;
            width: 20%;
            height: 30px;
            color: white;
            background-color: rgb(64 158 220);
            border: none;
            font-size: 16px;
            outline: none;
            transition: all 0.5s;
            &:hover {
              cursor: pointer;
              background-color: #1f222f;
              columns: rgb(64 158 220);
              border: 1px solid rgb(64 158 220);
              transition: all 0.5s;
            }
          }
        }
      }
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      flex: 0.5;
      min-width: 375px;
      @media screen and (max-width:1024px) {
        width: 100%;
        flex: 1;
      }
      // border: 1px solid red;
      //   row-gap: 1rem;
      .rightHeading {
        font-size: 29px;
        font-weight: 600;
        padding: 0px 0 13px 0;
      }
      .rightContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p{
          align-self: flex-end;
          margin: 0 2rem 1rem 0;
          color: #40a1dc;
          text-decoration: underline;
          cursor: pointer;
        }

        .rightwrapper {
          width: 90%;
          display: flex;
          // padding: 10px 0;

          .text {
            padding: 15px 0;
            width: 50%;
            text-align: center;
            border: 1px solid lightgrey;
          }
        }
      }
      .contribution {
        width: 90%;
        display: flex;
        .textArea {
          label {
            padding: 0 15px;
            font-weight: bold;
            font-size: 18px;
            text-align: left;
          }
          width: 100%;
          border: 1px solid lightgrey;
          padding: 10px 0;
          p {
            background-color: gray;
            margin: 10px 15px;
            padding: 0 5px;
            box-sizing: border-box;
            text-align: left;
            font-weight: lighter;
            line-height: 25px;
          }
          .p2 {
            padding: 0 15px;
            box-sizing: border-box;
            text-align: justify;
          }
          .check {
            padding: 10px 15px;
          }
        }
        .button {
          width: 98%;
          display: flex;
          justify-content: flex-end;

          button {
            // border-radius: 5px;
            width: 30%;
            height: 30px;
            color: white;
            background-color: rgb(64 158 220);
            border: none;
            font-size: 16px;
            outline: none;
            transition: all 0.5s;
            &:hover {
              cursor: pointer;
              background-color: #1f222f;
              columns: rgb(64 158 220);
              border: 1px solid rgb(64 158 220);
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}

.loader{
min-height: 80vh;
display: flex;
align-items: center;
justify-content: center;
}
