.container {
  width: 100%;
  height: 100%;
  padding: 0px 0rem;
  display: block;
  // z-index: 10000000;
  display: flex;
  justify-content: flex-end;
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: end;
    position: relative;
    z-index: 0.5;
    @media screen and (max-width: 433px) {
      z-index: 2;
    }
    .notification {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      cursor: pointer;
      button {
        outline: none;
        margin-top: 30px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 130px;
        margin-left: 10px;
        height: 42px;
        border-radius: 8px;
        background: #40a1dc;

        border: 1px solid #40a1dc;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: 0.9s;
        @media screen and (max-width: 624px) {
          width: 90px;
        }

        img {
          width: 15px;
          margin-right: 10px;
        }

        &:hover {
          transition: 0.5s;
          background: #1c587e;
          border: 1px solid #1c587e;

          color: #fff;
        }
      }
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      .notificationbadge {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #ff3e3e;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 0.8rem;
        }
      }
    }
    .bellmessage {
      position: absolute;
      overflow-y: auto;
      max-height: 20rem;
      right: -9%;
      top: 53px;
      width: 16.3rem;
      border-radius: 5px;
      z-index: 5;

      background-color: #21263b;
      border: 1px solid #263c58;
      @media screen and (max-width: "510px") {
        left: -140%;
        width: 15rem;
      }
      @media screen and (max-width: "333px") {
        left: 10%;
      }

      .heading {
        width: 100%;
        padding: 0.5rem 1rem;
        text-align: center;
        // background-color: #2c324d;
        span {
          font-size: 1.2rem;
        }
        position: sticky;
        top: 0;
      }
      .card {
        width: 100%;
        padding: 0.3rem 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        min-height: 4rem;
        .inputWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;
          input {
            background-color: #111422;
            width: 40%;
            outline: none;
            color: white;
            padding: 7px;
            height: 35px;
            border: none;
            border-radius: 5px;
            text-align: center;
          }
        }
        .buttonWrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px;

          button {
            background-color: #40a1dc;
            color: white;
            width: 45%;
            cursor: pointer;
            border-radius: 7px;
            height: 35px;
            border: none;
            &:hover {
              transition: 0.5s;
              background: #1c587e;

              color: #fff;
            }
          }
          .resetButton {
            background-color: transparent;
            color: white;
            width: 45%;

            border: 1px solid #40a1dc;
            cursor: pointer;
            border-radius: 7px;
            height: 35px;

            &:hover {
              transition: 0.9s;
              box-shadow: inset -90px 0 0 0 #40a1dc, inset 90px 0 0 0 #40a1dc;

              border: 1px solid #212642;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .container {
    width: 30px;
    height: 30px;
    padding: 0px 1rem;
    display: block;
    .wrapper {
      .bellmessage {
        right: 0%;
      }
    }
  }
}
