.container {
  width: 100%;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  padding: 4rem 0rem 0rem 0rem;
  @media only screen and (min-width: 1024px) and (max-width: 1550px) {
    padding: 0rem 4rem 1.5rem 4rem;
  }


  .wrapper {
    width: 100%;
    max-width: 1750px;
    border-top: 1px solid rgba(255, 255, 255, 0.158);
    box-sizing: border-box;
    padding: 4rem 0rem 0rem 0rem;

  
    .textWrapper {
      .topWrapper {
        display: flex;
        flex-direction: row;
        row-gap: 1rem;
        column-gap: 1rem;
        justify-content: space-between;
        padding: 0rem 2rem 0rem 2rem;
        align-items: center;

        .logoClass {
          width: 148px;
          height: 69px;
          cursor: pointer;
          border-radius: 34.5px;

          img {
            width: 120px;
          }
        }

        .lableClass {
          display: flex;
          column-gap: 1rem;
          padding-left: 2rem;

          input {
            width: 300px;
            height: 40px;
            border-radius: 5px;
            background: #fff;
            border: 1px solid #dadada;
            outline: none;
            box-sizing: border-box;
            padding: 1rem;

            &::placeholder {
              color: black;
              opacity: 1;
            }
          }

          button {
            width: 120px;
            height: 40px;
            font-weight: bold;
            border-radius: 5px;
            background: #40a1dc;
            color: white;
            font-size: 16px;
            border: none;
            cursor: pointer;
            transition: 3s;
            outline: none;

            &:hover {
              background: transparent;
              border: 1px solid #40a1dc;
              color: #40a1dc;
              transition: 2s;
            }
          }
        }
      }

      .bottomWrapper {
        width: 97%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5rem 2rem 0rem 2rem;
        justify-content: space-between;
        box-sizing: border-box;



        .firstWrapper {
          min-width: 250px;
          padding: 1rem 0;
          width: 15%;
          display: flex;
          flex-direction: column;
          row-gap: 2rem;
          column-gap: 2rem;



          &:nth-child(2) {
            width: 30%;
            row-gap: 2rem;
            padding-right: 1.5rem;

            @media screen and (min-width:"700px") and (max-width:"1350px") {
              width: 50%;
            }
          }

          &:last-child {
            width: 28%;
            row-gap: 2rem;
            padding-right: 1.5rem;

            @media screen and (min-width:"700px") and (max-width:"1350px") {
              width: 50%;
            }
          }

          .heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            color: #d6d6d6;
          }

          .subHeading {
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #afaeae;


            ul {
              li {
                color: #40a1dc;
                cursor: pointer;
                line-height: 25px;

                &:hover {
                  transition: 0.5s;
                  color: #86cbf6;
                }
              }
            }

            span {
              color: #40a1dc;
              cursor: pointer;
            }

            a {
              color: #40a1dc;
              cursor: pointer;
            }
          }
        }

        .secWrapper {
          // width: 25%;
          min-width: 210px;
          display: flex;
          flex-direction: column;
          row-gap: 2rem;
          column-gap: 2rem;
          padding: 1rem 0;

          &:nth-child(2) {
            width: 25%;
            row-gap: 2rem;
            padding-right: 1.5rem;

            @media screen and (min-width:"700px") and (max-width:"1350px") {
              width: 50%;
            }
          }

          &:last-child {
            width: 25%;
            row-gap: 2rem;
            padding-right: 1.5rem;

            @media screen and (min-width:"700px") and (max-width:"1350px") {
              width: 50%;
            }
          }

          .heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            color: #d6d6d6;
          }

          .iconsHeading {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;

            .text {
              display: flex;
              column-gap: 2rem;

              img {
                width: 19px;
                height: 30px;
              }

              ul {


                li {
                  list-style-type: circle;
                  height: 28px;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 14px;
                  text-align: left;
                  color: #d9d7d7;
margin-left: 1rem;
                  @media screen and (max-width:"1024px") {
                    list-style-type: none;
                    margin-left: 0rem 
                  }

                  a {
                    color: #40a1dc;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .decWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 1rem;

      .dec {
        width: 97%;
        padding: 0rem 2rem 0rem 2rem;

        label {
          color: #40a1dc;
          font-weight: bold;
        }

        span {
          width: 100%;
          text-align: justify;
          color: #afaeae;
          line-height: 25px;

          span {
            color: #40a1dc;
            cursor: pointer;
          }

          a {
            color: #40a1dc;
            cursor: pointer;
          }
        }
      }
    }

    .bWrapper {
      border-top: 1px solid rgba(255, 255, 255, 0.158);
      display: flex;
      justify-content: center;
      padding: 1.5rem 2rem 0 2rem;
      margin-top: 1rem;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #838383;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #ffffff;
  color: black !important;
  font-weight: bold;
  text-align: center;
  border-radius: 6px 6px 6px 0px;
  padding: 0.2rem 0rem !important;
  padding: 0px 0;
  bottom: 23px;
  right: 2px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 750px) {
  .container {
    padding: 2rem 0rem 2rem 0rem;
    flex-direction: column;

    .wrapper {
      .textWrapper {
        .topWrapper {
          flex-direction: column;
          row-gap: 2rem;
          align-items: flex-start;
          padding: 0rem 1rem;

          .logoClass {
            width: 50px;
            height: 50px;
            border-radius: 34.5px;

            img {
              height: 60px;
            }
          }

          .lableClass {
            padding-left: 0rem;
            display: flex;
            column-gap: 0.5rem;

            input {
              width: 200px;
              height: 40px;
              border-radius: 5px;
              padding: 0.5rem;
            }

            button {
              width: 100px;
              height: 40px;
              font-weight: bold;
            }
          }
        }

        .bottomWrapper {
          display: flex;
          flex-direction: column;
          padding: 2rem 2rem 0rem 2rem;
          box-sizing: border-box;
          row-gap: 2rem;

          .firstWrapper {
            width: 100%;
            row-gap: 1rem;

            &:not(:first-child) {
              padding-left: 0rem;
            }

            .heading {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
            }

            .subHeading {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;

              ul {
                display: flex;
                flex-direction: column;
              }
            }
          }

          .secWrapper {
            width: 100%;
            padding-left: 0rem;

            .heading {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
            }

            .iconsHeading {
              display: flex;
              flex-direction: column;
              row-gap: 2rem;

              .imgText {
                display: flex;
                column-gap: 2rem;

                img {
                  width: 19px;
                  height: 30px;
                }

                .subheading {
                  height: 48px;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: left;
                  color: #838383;
                }
              }
            }
          }
        }
      }
    }
  }
}