.modalcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;

  .modalheading {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;

    img {
      height: 80px;
    }
  }

  .modaltextsection {
    width: 80%;
    text-align: center;

    p {
      font-size: 13px;
      text-align: justify;
      // text-transform: capitalize;
      line-height: 1.5rem;
    }

    button {
      background-color: #40a1dc;
      border: 1px solid #40a1dc;
      border-radius: 5px;
      outline: none;
      padding: 0rem 2rem;
      width: 60%;
      height: 35px;
      margin-bottom: 1.3rem;
      margin-top: 1.3rem;
      transition: 0.5s;
      cursor: pointer;
      font-weight: bold;
      color: #fff;

      &:hover {
        transition-duration: 1s;
        border: 1px solid #196899;
        background-color: #196899;
      }
    }
  }
}

.container {
  width: 100%;

  .wrapper {
    width: 100%;

    .btnWrapper {
      width: 100%;
      margin-top: 30px;
      display: flex;
      align-self: center;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 600px) {
        justify-content: center;
      }

      .headingBtn {
        margin: 1rem 5rem;
        text-align: center;
        outline: none;
        border: none;
        // width: 110px;
        padding: 0 1rem;
        height: 39px;
        border-radius: 13px;
        background: #40a1dc;
        border: 1px solid #40a1dc;

        @media screen and (max-width: 600px) {
          height: 33px;
          font-size: 11.5px;
          margin: 1rem 0rem;
        }

        font-weight: 600;
        font-size: 15px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: 1s;

        &:hover {
          transition: 1.5s;
          background: #1c6999;
          border: 1px solid #1c6999;
        }
      }
    }

    .cards {
      width: 100%;
      padding: 1rem 0rem 5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 2rem;
      column-gap: 2.5rem;
      flex-wrap: wrap;

      .card {
        max-width: 20rem;
        display: flex;
        flex-wrap: wrap;
        border-radius: 20px;
        background-color: #1b1e2f;
        transition: transform 0.5s;

        &:hover {
          transform: scale(0.9);
        }

        .heading {
          width: 100%;
          text-align: center;
          padding: 0.6rem 0rem;

          span {
            font-size: 1.5rem;
          }
        }

        .logo {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.6rem 0rem;

          img {
            width: 20%;
          }
        }

        .text {
          width: 100%;
          text-align: center;
          padding: 0rem 1.9rem;

          p {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        .price {
          width: 100%;
          padding: 0.5rem 0rem;
          line-height: 1.9rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 1rem;

          span {
            font-size: 1.3rem;

            img {
              width: 15px;
            }
          }

          p {
            font-size: 1rem;
            font-weight: 500;
            display: flex;

            img {
              width: 25px;
              height: 25px;
            }
          }
        }

        .purchasebtn {
          width: 100%;
          text-align: center;
          padding: 2rem 0rem;

          button {
            width: 90%;
            border: 1px solid #40a1dc;
            background-color: #40a1dc;
            color: #fff;
            font-weight: bold;
            border-radius: 20px;
            font-size: 18px;
            padding: 0.6rem 0rem;
            cursor: pointer;
            transition-duration: 0.5s;

            &:hover {
              transition-duration: 1s;
              border: 1px solid #196899;
              background-color: #196899;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .wrapper {
      .cards {
        justify-content: center;
        flex-direction: row;

        .card {
          max-width: 17rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1150px) {
  .container {
    .wrapper {
      .cards {
        justify-content: center;
        flex-direction: row;

        .card {
          width: 20rem;
          max-width: 20rem;
        }
      }
    }
  }
}
