.modalWrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    font-size: 25px;
  }
  .btnsection {
    width: 80%;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    text-align: center;
    line-height: 3rem;
    padding: 20px 0 0 0;
    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #11537c;
        transition: 0.5s;
      }

      &:nth-last-child(1) {
        background: #be2539;

        &:hover {
          background: #800b1a;
          transition: 0.5s;
        }
      }
    }
  }
}
.wrapper {
  background-color: #1f222f;
  height: 510px;

  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  @media screen and (min-width: "1424px") {
    height: 660px;
  }
  @media screen and (min-width: "1624px") {
    height: 860px;
    background-color: #1f222f;
  }
  @media screen and (min-width: "1924px") {
    height: 1060px;
  }
  .inboxWrapper {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    P {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
    img {
      cursor: pointer;
    }
  }
  .inputWrapper {
    padding-left: 20px;
    margin-top: -15px;
    padding-right: 20px;
    margin-bottom: 20px;
  }

  .messageWraper {
    display: flex;
    margin-top: 10px;
    align-items: center;
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 30px;
    justify-content: space-between;

    &:hover {
      background-color: #40a1dc;
      transition: 0.7s;
      cursor: pointer;
    }
    .userWrapper {
      display: flex;
      .imgwrapper {
        position: relative;
        img {
          margin-right: 7px;
          width: 43px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 43px;
          border-radius: 50%;
        }
        .isOnline {
          background-color: green;
          height: 10px;
          position: absolute;
          top: 33px;
          left: 35px;
          width: 10px;
          border-radius: 50%;
          border: 1px solid white;
        }
      }
      label {
        font-weight: 600;
        color: #b1b1b1;
      }
      span {
        margin-top: 6px;
        display: block;
        font-size: 13px;
        margin-left: 2px;
        color: #b1b1b1;
      }
    }
    .delteWrapper {
      display: flex;
      row-gap: 5;
      p {
        color: #727272;
        font-size: 12px;
        &:hover {
          color: white;
        }
      }
      .icon {
        margin-left: 10px;
      }
    }
  }
  .messageWraper:hover {
    display: flex;
    margin-top: 10px;
    align-items: center;
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 20px;
    justify-content: space-between;
    &:hover {
      background-color: #40a1dc;
      transition: 0.7s;
      cursor: pointer;
    }
    .userWrapper {
      display: flex;
      img {
        margin-right: 7px;
      }
      label {
        font-weight: 600;
        font-size: 16px;
        color: black;
      }
      span {
        margin-top: 6px;
        color: black;
        display: block;
        font-size: 13px;
      }
    }
    p {
      color: white;
    }
  }
}
