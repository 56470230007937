.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 12px 12px 12px 12px;
  // width: 100%;
}
.loaders {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 12px 12px 12px 12px;
  // width: 100%;
}

.barwave {
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
}

.buttonload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: #40a1dc;
  border: 1px solid #3b94cb;
  outline: none;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    border: 1px solid #3b94cb;
  }
  p {
    width: 20%;
    text-align: center;
  }
  .loader {
    margin-left: -12px;
    margin-right: 8px;
  }
}

.buttonload1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 40px;
  background-color: #40a1dc;
  border: 1px solid #3b94cb;
  outline: none;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    border: 1px solid #3b94cb;
  }
  p {
    width: 20%;
    text-align: center;
  }
  .loader1 {
    margin-left: -12px;
    margin-right: 8px;
  }
}
