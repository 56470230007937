.container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    .heading {
      width: 100%;
      padding: 20px 0px 10px 0;
      display: flex;
      // flex-direction: column;
      column-gap: 40px;

      img {
        width: 11px;
        cursor: pointer;
      }

      p {
        font-weight: 500;
        font-size: 30px;
      }

      @media screen and (max-width: 660px) {
        p {
          font-size: 20px;
        }
      }
    }

    .optionCard {
      width: 100%;
      height: 100px;
      padding: 0px 20px;
      background-color: #131a29;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .option {
        display: flex;
        align-items: center;

        .icon {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 20px;
          }
        }

        .text {
          margin-left: 10px;
          font-weight: 600;
        }
      }

      .arrow {
        display: flex;
        column-gap: 20px;
        margin-right: 10px;
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  align-items: center;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 0px 0px;

    p {
      font-size: 25px;
      color: #279af1;
    }
  }

  .inputfields {
    width: 70%;
    padding: 30px 0px;
  }

  button {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 70%;
    height: 40px;
    // padding: 10px 0px;
    outline: none;
    border: 1px solid #279af1;
    cursor: pointer;
    color: #fff;
    background-color: #279af1;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.5s;

    &:hover {
      background-color: transparent;
      border: 1px solid #279af1;
    }
  }
}