.container {
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 2.1rem 2rem 2.1rem;
    box-sizing: border-box;

    .heading {
      display: flex;
      width: 100%;
      text-align: center;

      .back {
        width: 35px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .imgLogo {
        width: 100%;
        text-align: center;
        .logo {
          width: 12%;
          margin-right: 35px;
          @media screen and (max-width: 1024px) {
            width: 25%;
          }
          @media screen and (max-width: 574px) {
            width: 50%;
          }
        }
      }
    }

    .CardWrapper {
      display: flex;
      column-gap: 0.8rem;
      flex-direction: row;
      row-gap: 1rem;
      min-height: 300px;
      justify-content: center;
      // flex-wrap: wrap;
      // height: 81vh;
      align-items: center;
      margin: 1rem 0;

      @media screen and (max-width: 1024px) {
        height: auto;
        flex-direction: column;
        row-gap: 1rem;
        column-gap: 0.8rem;
      }

      .leftCard {
        // height: 550.71px;
        width: 50%;
        row-gap: 1rem;
        display: flex;
        border-radius: 7px;
        justify-content: center;

        align-items: center;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        img {
          width: 100%;
          height: 500px;
          object-fit: contain;
          border-radius: 7px;
          // margin-bottom: 10px;
          // object-fit: cover;
          text-align: center;
        }

        @media screen and (max-width: 600px) {
          height: 400.71px;

          img {
            height: 400px;
            width: 100%;
            text-align: center;
            object-fit: contain;
          }
        }
      }

      .rightCard {
        width: 50%;
        max-height: 500px;
        min-height: 500px;
        position: relative;
        // max-height: 610.71px;
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;
        background: #1b1e2f;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1.1rem;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 600px) {
          padding: 2rem 1.5rem 2rem 1.5rem;
        }

        .userWrapper {
          display: flex;
          // padding-top: 3rem;
          justify-content: space-between;
          .userInfo {
            display: flex;
            flex-wrap: wrap;

            img {
              border-radius: 50%;
              width: 40px;
              height: 40px;
              margin-right: 10px;
              border: 2px solid #40a1dc;
              @media screen and (max-width: "480px") {
                margin-bottom: 5px;
              }

              span {
                font-size: 15px;
                font-weight: 500;
              }
              p {
                font-size: 13px;
              }
            }
          }
          img {
            width: 40px;
            cursor: pointer;
          }
        }
        .Dec {
          label {
            font-weight: 600;
            font-size: 22px;
            font-size: 500;

            text-align: left;
            color: #40a1dc;

            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
          span {
            font-weight: 600;
            font-size: 22px;
            font-size: 500;

            text-align: left;
            color: #40a1dc;

            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }

          p {
            margin-top: 10px;
            font-weight: 300;
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 22px;
            text-align: left;
            color: #c8c8c8;

            a {
              color: #40a1dc;
              transition: 0.9s;

              &:hover {
                color: #2c739f;
                transition: 0.5s;
              }
            }
          }
        }
        .cardHeading {
          font-weight: bold;
          font-size: 25px;
          letter-spacing: 0.06em;
          line-height: 32px;
          text-align: left;
          color: #fcfcfc;

          @media screen and (max-width: 600px) {
            font-size: 25px;
          }
        }

        .paragraphWrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 2rem;

          @media screen and (max-width: 600px) {
            justify-content: space-between;
          }
          label {
            display: block;
            margin-top: 15px;
            font-size: 13px;
          }
          p {
            margin-top: 15px;
            font-size: 13px;
          }
        }

        .buttonWrapper {
          display: flex;
          justify-content: end;
          margin-top: 20px;
          @media screen and (max-width: 1300px) {
            position: relative;
          }
          .btn1 {
            border-radius: 4px;
            width: 140px;
            justify-content: center;
            display: flex;
            align-items: center;
            // padding-bottom: 8px;
            height: 36px;
            background: transparent;
            border: 1px solid #40a1dc;
            // font-weight: bold;
            font-size: 15px;
            // text-align: center;
            cursor: pointer;
            color: #fff;
            transition: 0.5s;
            img {
              width: 20px;
              margin-left: 5px;
            }
            @media screen and (max-width: 600px) {
              height: 35px;
              width: 80px;
              font-size: 15px;
            }

            &:hover {
              background: #40a1dc;
              transition: 0.9s;
            }
          }

          .btn2 {
            border-radius: 4px;

            width: 140px;
            // display: flex;
            height: 36px;
            background: #40a1dc;
            display: flex;
            justify-content: center;
            align-items: center;
            // font-weight: bold;
            font-size: 15px;
            // text-align: center;
            border: none;
            cursor: pointer;
            color: #fff;
            transition: 0.5s;
            margin-left: 10px;
            span {
            }
            img {
              width: 20px;
              margin-left: 5px;
              // margin-top: 10px;
            }

            @media screen and (max-width: 600px) {
              height: 35px;
              width: 80px;
              font-size: 15px;
            }

            &:hover {
              background: transparent;
              border: 1px solid #40a1dc;
              transition: 0.9s;
            }
          }
        }
      }
    }
  }
}
