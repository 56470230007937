.wrapper {
  background-color: #1b1e2f;
  width: 100%;

  //   margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
  .imgWrapper {
    display: flex;

    text-align: center;
    img {
      width: 30px;
      margin-bottom: 10px;
    }
    span {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .imgWraaper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    span {
      font-size: 30px;
    }
    label {
      display: block;
      font-size: 21px;
      margin-top: 10px;
    }

    img {
      width: 25%;
      max-width: 250px;
    }
    .darklogo {
      width: 50%;
      align-items: end;
    }
  }
}
