.container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  width: 100%;
  min-height: 70vh;
  justify-content: center;
  .widthWrapper {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .headingWrapper {
      row-gap: 3rem;
      @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0 0 0 2rem;
      }
      img {
        margin-bottom: -40px;
        width: 40px;
        cursor: pointer;
      }
    }
    .textWrapper {
      text-align: center;
      font-weight: bold;
      font-size: 25px;
    }
  }
  .cardsWrapper {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;

    flex-wrap: wrap;
    gap: 7;
    .Card {
      width: 30%;
      display: flex;
      align-self: center;
      margin: 20px;
      background: #1b1e2f;

      padding: 0rem 1rem 3rem 1rem;
      align-items: center;
      border-radius: 20px;
      box-shadow: 0 0 5px #2c314b, 0 0 10px #2a2e42;

      @media screen and (max-width: 800px) {
        width: 100%;
      }
      @media screen and (min-width: 800px) and (max-width: 1600px) {
        padding: 1rem;
        width: 40%;
      }

      .cardWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        row-gap: 1.5rem;

        .imgWrapper {
          width: 60%;

          @media screen and (max-width: 600px) {
            padding: 1rem 0;
          }

          padding: 1rem 0 0 0;
          height: auto;
          display: flex;
          justify-content: center;

          img {
            width: 150px;
            height: 100px;
            object-fit: contain;

            @media screen and (max-width: 600px) {
              width: 80px;
            }
          }
        }

        .heading {
          text-align: center;

          label {
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
          }

          p {
            font-size: 17px;
            line-height: 30px;
          }
        }

        .peragraph {
          width: 70%;
          display: flex;

          @media screen and (max-width: 600px) {
            width: 100%;
          }

          .leftSide {
            width: 50%;

            label {
              font-size: 20px;
              color: white;
              font-weight: bold;
              line-height: 30px;

              @media screen and (max-width: 600px) {
                font-size: 16px;
              }
            }

            p {
              color: rgba(255, 255, 255, 0.685);
              line-height: 30px;
              font-size: 15px;
            }
          }

          .rightSide {
            width: 50%;
            text-align: right;

            label {
              line-height: 30px;
              font-size: 20px;
              color: white;
              font-weight: bold;

              @media screen and (max-width: 600px) {
                font-size: 16px;
              }
            }

            p {
              text-transform: capitalize;
              font-size: 15px;
              line-height: 30px;
              color: rgba(255, 255, 255, 0.685);
            }
          }
        }

        .buttonload1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40%;
          height: 45px;
          background: rgb(207, 1, 1);
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
          transition: 0.3s;
          outline: none;
          font-weight: 600;
          font-size: 15px;
          border-radius: 5px;
          transition: 0.5s;
          cursor: pointer;

          &:hover {
            background: rgb(156, 3, 3);
          }

          p {
            width: 20%;
            text-align: center;
          }

          .loader1 {
            margin-left: -12px;
            margin-right: 8px;
          }
        }

        .btn {
          width: 100%;
          // padding-top: 1rem;
          display: flex;
          justify-content: center;

          button {
            text-align: center;
            width: 80%;
            height: 45px;
            outline: none;
            border: none;
            border-radius: 10px;
            background: rgb(207, 1, 1);
            box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
            transition: 0.3s;
            cursor: pointer;
            font-weight: 600;
            font-size: 15px;
            color: #fff;
            transition: 0.9s;

            @media screen and (max-width: 600px) {
              font-size: 10px;
              height: 30px;
              width: 100%;
            }

            @media screen and (min-width: 1024px) and (max-width: 1200px) {
              font-size: 12px;
              height: 35px;
            }

            &:hover {
              transition: 0.5s;
              background: rgb(156, 3, 3);
            }
          }
        }
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;

    p {
      font-size: 18px;
      color: #fff;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #40a1dc;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #11537c;
        transition: 0.5s;
      }

      &:nth-last-child(2) {
        background: #be2539;

        &:hover {
          background: #800b1a;
          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
