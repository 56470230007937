.container {
  width: 100%;
  .wrapper {
    display: flex;
    justify-content: center;
    .NoData {
      width: 200px;
      margin-top: 30px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 25px;
      tr {
        height: 80px;
        td {
          background: #191c2c;
          padding: 0rem 0rem 0rem 1rem;
          text-align: center;

          .tabelWrapper {
            display: flex;
            align-items: center;
            column-gap: 2rem;
            text-align: right;
            .innerWrapper {
              display: flex;
              flex-direction: column;
              span {
                font-weight: normal;
                font-size: 25px;
                text-align: left;
                color: #7f819a;
              }
            }
          }
          &:nth-child(2) {
            justify-content: flex-end;
            display: flex;
            height: 80px;
            text-align: right !important;
            padding-right: 22px;
          }
        }
      }

      td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      td:last-child {
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
}
