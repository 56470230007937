.button1 {
  background: transparent;
  outline: none;
  border: none;
  width: 15%;
  min-width: 200px;
  height: 52px;
  border-radius: 6px;
  background: #1b1e2f;
  color: white;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  @media screen and (max-width: 580px) {
    width: 100%;
  }
  @media screen and (min-width: 580px) and (max-width: 664px) {
    min-width: 170px;
  }
  @media screen and (min-width: 664px) and (max-width: 814px) {
    min-width: 140px;
  }
  &:hover {
    border: 1px solid #40a1dc;
    color: #40a1dc;
    transition: 0.9s;
    background: transparent;
  }
}
