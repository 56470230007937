.skeltonCard {
  width: 100%;
  display: flex;
  align-self: center;
  background: #1b1e2f;
  padding: 1rem;
  // min-height: 20vh;
  box-shadow: 1px 1px 2px black, 0 0 25px #1b1e2f, 0 0 5px darkblue;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 1rem;
  }
  .skeltonWrapper {
    display: flex;
    flex-wrap: wrap;
    .rightSkeleton {
      margin-left: 20px;
      margin-top: 20px;
    }
  }
}
.Card {
  width: 100%;
  display: flex;
  align-self: center;
  background: #1b1e2f;
  // min-height: 20vh;
  box-shadow: 1px 1px 2px black, 0 0 25px #1b1e2f, 0 0 5px darkblue;
  border-radius: 10px;
  padding: 3rem 3rem 3rem 0;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    padding: 1rem;
  }
  .leftSide {
    width: 20%;
    display: flex;
    justify-content: center;
    img {
      height: 100px;
      padding: 1rem;
      @media screen and (max-width: 600px) {
        height: 80px;
        margin-right: 1rem;
      }
    }
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    row-gap: 1rem;
    span {
      font-size: 15px;
      // padding-right: 1rem;
      label {
        font-weight: bold;
      }
      img {
        width: 12px;
      }
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-start;
      column-gap: 1rem;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        width: 30%;
        height: 40px;
        outline: none;
        border: none;
        border-radius: 10px;
        background: #40a1dc;
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
        transition: 0.3s;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        transition: 0.9s;
        img {
          width: 12px;
        }
        @media screen and (max-width: 600px) {
          width: 40%;
          font-size: 10px;
          height: 30px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          width: 40%;
          font-size: 12px;
          height: 35px;
        }
        &:hover {
          transition: 0.5s;
          background: transparent;
          border: 1px solid #40a1dc;
          color: #40a1dc;
        }
      }
    }
  }
}
