.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1750px;
    width: 100%;

    .loaderPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
    }

    .addbutton {
      width: 85%;
      display: flex;
      justify-content: flex-end;

      img {
        max-width: 2rem;
        cursor: pointer;
      }
    }

    .tableWrapper {
      overflow-x: auto;

      table {
        width: 100%;
        min-width: "400px";
        border-collapse: collapse;
        text-align: left;
        border-collapse: separate;
        border-spacing: 0px 1rem;
        position: relative;

        .emptyImg {
          position: absolute;
          top: 30%;
          left: 40%;
          bottom: 30%;
        }

        tr {
          text-align: left;
          margin-bottom: 30px;
          position: relative;

          .cross {
            position: absolute;
            top: -7px;
            right: 54px;
            background-color: #279af1;
            padding: 4px;
            border-radius: 10%;
            cursor: pointer;

            @media only screen and (max-width: 700px) {
              top: -12px;
              right: 28px;
            }
          }

          td {
            padding: 0 9px;

            .nameWrapper {
              display: flex;
              padding-top: 10px;
              padding-bottom: 10px;
              column-gap: 1rem;
              justify-content: flex-start;
              text-align: left;
              align-items: center;

              img {
                background: #fff;
                width: 70px;
                height: 70px;
                border-radius: 5px;
              }
            }

            .estApyWrapper {
              img1 {
                // filter: invert(100%) sepia(54%) saturate(570%) hue-rotate(206deg) brightness(118%) contrast(108%);
                // border: 1px solid red;
              }

              .img2 {
                filter: invert(49%) sepia(100%) saturate(1587%)
                  hue-rotate(38deg) brightness(190%) contrast(103%);
              }
            }

            .firstimage {
              display: flex;
              align-items: center;
              column-gap: 0.5rem;

              img {
                width: 35px;
              }

              label {
                color: rgba(255, 255, 255, 0.514);
              }
            }

            .bottomimage {
              display: flex;
              align-items: center;
              column-gap: 0.3rem;
            }
          }
        }

        td:first-child {
          padding-left: 2rem;
          padding: 1rem 1rem;
        }

        tr:first-child {
          font-weight: 400;
          height: 18px;
          border-radius: 5px;
        }

        tr:not(:first-child) {
          cursor: pointer;
          font-weight: 500;
          height: 75px;
          border-radius: 5px;
          background: #1b1e2f;

          .description {
            font-weight: normal;
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-top: 10px;
          }

          .changedDescription {
            font-size: 12px;
            margin-top: 10px;
            font-weight: normal;
            width: 350px;
          }

          :nth-child(7) {
            :not(:first-child) {
              color: rgba(255, 255, 255, 0.438);

              padding-left: 2.1rem;
            }
          }
        }
      }
    }
  }
}

.passwordmodal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  align-items: center;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;

  .heading {
    width: 100%;
    text-align: center;
    padding: 10px 0px 10px 0px;

    p {
      font-size: 18px;
      color: #fff;
    }
  }

  .buttonset {
    display: flex;
    column-gap: 0rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    .Delete {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      width: 35%;
      min-height: 40px;
      border-radius: 8px;
      background: #be2539;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transition: 1s;

      &:hover {
        background: #800b1a;

        transition: 0.5s;
      }

      &:nth-last-child(2) {
        background: #40a1dc;

        &:hover {
          background: #11537c;
          transition: 0.5s;
        }
      }
    }
  }

  .inputfields {
    width: 70%;
    padding: 15px 0px;
  }

  .signature {
    // width: 100%;
    // min-height: 30vh;
    background-color: #131a29;
    margin-top: 1rem;
    text-align: center;
  }

  .termAndCondition {
    margin: 1rem 0;
  }

  .btn {
    // padding: 10px 100px;
    // box-sizing: border-box;
    width: 100%;
    text-align: center;

    button {
      width: 70%;
      height: 40px;
      // padding: 10px 0px;
      outline: none;
      border: 1px solid #279af1;
      cursor: pointer;
      color: #fff;
      background-color: #279af1;
      font-weight: bold;
      border-radius: 5px;
      transition: 0.5s;

      &:hover {
        background-color: transparent;
        border: 1px solid #279af1;
      }
    }
  }
}
