.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  height: 100px;
  background: #111422;
  width: 100%;

  .leftWrapper {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 30%;
    height: 100%;

    .logo {
      img {
        max-width: 500px;
        height: 40px;
        cursor: pointer;
        margin-right: 20px;
        @media screen and (max-width: 350px) {
          margin-right: 5px;
        }
      }
    }
  }
  .profilediv {
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    row-gap: 10px;
    cursor: pointer;

    .profile {
      width: 45px;
      height: 45px;
      max-width: 50px;
      max-height: 50px;
      border-radius: 100%;
      position: relative;
      background-color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .img2 {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        // background-size: 100% 100%;
        overflow: hidden;
        object-fit: cover;
      }

      .img1 {
        width: 20px;
        height: 20px;
        text-align: center;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;

        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }

          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      .editprofile {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        text-align: center;
        background-color: #000000;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -5px;
        bottom: -5px;
        border: 2px solid #fff;
        background-color: #40a1dc;

        input {
          display: none;
        }

        .selectimage {
          width: 100%;
          height: 100%;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            padding-bottom: 1px;

            @media screen and (max-width: 1024px) {
              position: absolute;
              top: 1px;
              right: 0px;
            }
          }
        }
      }
    }

    .showmail {
      width: 100%;
      // max-width: 12rem;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      .textElllip {
        white-space: nowrap;
        width: 14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 14px;
        color: #fff;
        opacity: 0.6;
        padding: 0 1rem;
        word-break: break-word;

        @media screen and (max-width: 1024px) {
          font-size: 10px;
        }

        &:hover {
          color: #40a1dc;
          opacity: 1;
        }
      }
    }
  }
  .middleWrapper {
    width: 50%;
    height: 100%;
    padding: 0px 0px;
    position: relative;
  }
  .rightWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    .menuIcon {
      width: 100%;
      img {
        max-width: 400px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 1024px) {
  .container {
    display: none;
    .leftWrapper {
      justify-content: space-around;
      width: 15%;
      .logo {
        width: 50%;
        height: 50%;
      }
    }
    .middleWrapper {
      position: relative;
    }
    .rightWrapper {
      .menuIcon {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1450px) {
  .logo {
    width: 30%;
    height: 30%;
  }
}
