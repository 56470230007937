.container {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  // padding: 1.5rem;
  .wrapper {
    // width: 100%;
    // max-width: 1750px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    video {
      height: auto;
      width: 100%;
    }
    .textWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      text-align: left;
      align-self: flex-start;
      .heading {
        font-weight: 600;
        font-size: 28px;
        line-height: 74px;
        text-align: left;
        color: #fff;
      }
      .subHeading {
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .container {
    .wrapper {
      video {
        height: auto;
        width: 100%;
      }
      .textWrapper {
        display: flex;
        flex-direction: column;
        .heading {
          font-weight: 600;
          font-size: 18px;
          line-height: 40px;
          text-align: left;
          color: #fff;
        }
        .subHeading {
          font-weight: 300;
          font-size: 13px;
          line-height: 25px;
          text-align: left;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .container {
    .wrapper {
      video {
        height: auto;
        width: 100%;
      }
      .textWrapper {
        display: flex;
        flex-direction: column;
        .heading {
          font-weight: 600;
          font-size: 22px;
          line-height: 40px;
          text-align: left;
          color: #fff;
        }
        .subHeading {
          font-weight: 300;
          font-size: 14px;
          line-height: 25px;
          text-align: left;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
