.modalWrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  label {
    font-size: 25px;
  }
  .buttonWrapper {
    button {
      margin-top: 15px;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      width: 100%;
      padding: 13px;
      border: 1px solid #202336;
      background-color: #202336;
      border-radius: 10px;
      height: 60px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transition: 0.9s;
        box-shadow: inset -290px 0 0 0 #40a1dc, inset 290px 0 0 0 #40a1dc;

        border: 1px solid #212642;
        color: #fff;
      }
    }
  }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  .wrapper {
    width: 100%;
    max-width: 1750px;
    padding: 1rem 3rem;
    @media screen and (max-width: 1024px) {
      padding: 1rem 1rem;
    }
    .topWrapper {
      width: 100%;
      display: flex;
      margin-bottom: -45px;
      margin-top: 20px;
      align-items: center;
      padding-bottom: 1rem;
      img {
        height: 35px;
        cursor: pointer;
      }
      label {
        width: 100%;
        text-align: center;
        // font-family: Montserrat;
        font-weight: 600;
        font-size: 34px;
        // text-align: left;
        color: #fff;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
    }
    .itemWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      column-gap: 2rem;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
        row-gap: 3rem;
      }

      .leftWrapper {
        width: 50%;
        order: 1;
        margin-top: 40px;
        @media screen and (max-width: 1024px) {
          width: 100%;
          order: 2;
        }
        @media screen and (min-width: 1024px) and (max-width: 1400px) {
          width: 40%;
        }
        .buttonWrapper {
          display: flex;
          flex-wrap: wrap;

          justify-content: start;
          button {
            background-color: #111422;
            color: white;
            display: flex;
            margin-top: 15px;
            height: 38px;
            padding-left: 9px;
            padding-right: 9px;
            // padding: 7px;
            margin-right: 10px;
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #40a1dc;
            align-items: center;
            &:hover {
              transition: 0.9s;
              box-shadow: inset -90px 0 0 0 #40a1dc, inset 90px 0 0 0 #40a1dc;
            }
            img {
              width: 30px;
              margin-left: 8px;
            }
          }
        }
        .headingWrapper {
          display: flex;
          justify-content: space-between;
          padding: 1.5rem 0 0 0;
          margin-bottom: 10px;
          span {
            font-weight: 500;
            font-size: 28px;
            text-align: center;
            color: #fff;
            @media screen and (max-width: 1024px) {
              font-size: 20px;
            }
          }
          p {
            font-weight: normal;
            font-size: 20px;
            text-align: left;
            color: #7f819a;
          }
        }
      }
      .rightWrapper {
        width: 50%;
        order: 2;
        padding-top: 2.5rem;
        // height: 400px !important;
        @media screen and (max-width: 1024px) {
          width: 100%;
          order: 1;
          // height: 400px !important;
        }
        @media screen and (min-width: 1024px) and (max-width: 1400px) {
          width: 60%;
          // height: 400px !important;
        }
        .graph {
          height: 400px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
