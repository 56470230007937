.container {
  width: 70%;

  @media screen and (max-width: "1024px") {
    width: 97%;
  }

  @media screen and (max-width: "600px") {
    width: 98%;
    height: auto;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    // background-color: #1b1e2f46;
    padding: 40px 20px;
    border-radius: 20px;
    width: 100%;

    label {
      img {
        width: 30px;
      }
    }

    .btnBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      width: 100%;
      height: 55px;
      outline: none;
      border: none;
      border-radius: 10px;
      background: #40a1dc;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
      transition: 0.3s;
      cursor: pointer;
      font-weight: 600;
      font-size: 20px;
      margin-top: 10px;
      color: #fff;

      @media screen and (max-width: 1024px) {
        width: 100%;
        align-self: flex-start;
        height: 50px;
        font-size: 18px;
      }

      &:hover {
        transition: 0.5s;
        background: transparent;
        border: 1px solid #40a1dc;
        color: #40a1dc;
      }
    }

    label {
      font-size: 40px;
      text-align: center;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      font-size: 17px;
      text-align: justify;
      width: 80%;

      img {
        width: 15px;
      }

      @media screen and (max-width: 1024px) {
        font-size: 18px;
        width: 90%;
        // img {
        //   width: 15px;
        // }
      }
    }

    form {
      width: 95%;
      display: flex;
      flex-direction: column;
      row-gap: 3px;
      .inputWrapper {
        p {
          font-weight: normal;
          font-size: 15px;
          text-align: left;
          color: #fff;
          opacity: 0.6;
          padding: 0 0.5rem;
          padding-bottom: 0.5rem;
        }

        .divWrapper {
          display: flex;
          align-items: center;
          padding-left: 10px;
          width: 100px;
          height: 50px;
          border-radius: 10px;
          background-color: #1b1e2f;
          border: none;
          font-family: Arial;
          font-weight: normal;
          font-size: 15px;
          text-align: left;
          color: #fff;
          color-scheme: dark;
          outline: none;
          width: 100%;

          img {
            width: 13px;
            margin-bottom: 3px;
          }

          &::placeholder {
            color: white;
            padding-left: 1rem;
          }
        }
      }

      .QSymbolImg {
        img {
          width: 12px;
        }
      }

      .btnBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        width: 100%;
        height: 55px;
        outline: none;
        border: none;
        border-radius: 10px;
        background: #40a1dc;
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
        transition: 0.3s;
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        margin-top: 10px;
        color: #fff;

        @media screen and (max-width: 1024px) {
          width: 100%;
          align-self: flex-start;
          height: 50px;
          font-size: 18px;
        }

        &:hover {
          transition: 0.5s;
          background: transparent;
          border: 1px solid #40a1dc;
          color: #40a1dc;
        }
      }
    }
  }
}
