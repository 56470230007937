.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 90%;
    min-height: 100vh;
    padding: 50px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    .debit {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 5px;
      max-width: 550px;
      background-color: #1e2236;
      .heading {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0px;
        img {
          width: 70px;
          height: 70px;
        }
      }
      .text {
        width: 100%;
        padding: 15px 0px 25px 0px;
        display: flex;
        justify-content: center;
        max-width: 380px;
        p {
          font-size: 23px;
        }
        span {
          font-size: 20px;
          text-align: center;
        }
      }
      .btnsection {
        padding: 10px 0px 20px 0px;
      }
    }
  }
}
