.container {
  width: 100%;
  height: auto;

  .wrapper {
    width: 100%;

    .heading {
      max-width: 900px;
      padding: 20px 0px;
      line-height: 3rem;

      label {
        font-size: 20px;
        font-weight: 500;
      }

      p {
        line-height: 2rem;
      }
    }

    .selectionare {
      width: 100%;

      .displayfield {
        max-width: 300px;

        .innerselected {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          label {
            color: red;
          }
        }
      }

      .namefield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 10px;

            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }

      .phonefield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .selected {
          width: 100%;
          display: flex;
          // max-width: 600px;
          flex-direction: column;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }

      .addressfield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }

        .provinceselected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }

      .mailingfield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }
    }
  }
}
