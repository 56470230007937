.container {
  width: 100%;
  .wrapper {
    // margin: 3rem 0;
    padding: 2rem;
    background: #1b1e2f;
    border-radius: 20px;
    opacity: 0.8;
    filter: drop-shadow(0px 1px 16px rgba(255, 91, 164, 0.09));
    .headingWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.137);
      img {
        position: relative;
        bottom: 70px;
        height: 80px;
        @media screen and (max-width: 1024px) {
          height: 40px;
          bottom: 50px;
        }
      }
      .topWrapper {
        @media screen and (max-width: 1024px) {
          h3 {
            font-size: 12px;
          }
          p {
            font-size: 12px;
          }
        }
      }
      .bottomWrapper {
        display: flex;
        flex-direction: column;
        label {
          color: #fff;
          opacity: 0.4;
          @media screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
        span {
          font-weight: normal;
          font-size: 20px;
          letter-spacing: 0.01em;
          text-align: left;
          color: #fff;
          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }
        }
      }
    }
    .graphWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
      .btnbtn {
        width: 8%;
        height: 34.04px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.164);
        outline: none;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        @media screen and (max-width: 1024px) {
          width: 13%;
        }
        &:hover {
          border: 1px solid #ffffff;
          color: #ffffff;
          background: transparent;
          transition: 0.5 c s;
        }
        &:focus-within {
          background: #40a1dc;
        }
      }
      .ActiveBtn {
        width: 8%;
        height: 34.04px;
        border-radius: 15px;
        background: #40a1dc;
        outline: none;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        @media screen and (max-width: 1024px) {
          width: 13%;
        }
      }
    }
  }
}
