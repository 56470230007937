.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow-x: hidden;

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 50px 0px;

    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 2rem 0;
      p {
        font-weight: 500;
        font-size: 40px;
        letter-spacing: 0.01em;
        line-height: 80px;
        text-align: center;
        font-weight: bold;
        @media screen and (max-width: 600px) {
          font-size: 30px;
          line-height: 50px;
          padding: 1rem 3rem;
        }

        @media screen and (min-width: 600px) and (max-width: 1024px) {
          font-size: 35px;
          line-height: 60px;
        }
      }
      span {
        color: #40a1dc;
      }
    }

    .featuresection {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;

      .leftfeature {
        order: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: space-evenly;

        @media screen and (min-width: "1024px") and (max-width: "1550px") {
          row-gap: 50px;
        }

        @media screen and (max-width: "1024px") {
          row-gap: 50px;
        }

        .anaylize {
          width: 100%;
          height: 30%;
          display: flex;

          .left {
            width: 18%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            p {
              font-size: 3rem;
              font-weight: 500;
              color: #40a1dc;
            }
          }

          .right {
            width: 70%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            label {
              font-size: 1.5rem;
              font-weight: 500;
            }

            p {
              font-size: 16px;
              line-height: 1.5rem;
              font-weight: 300;
              padding-bottom: 1rem;
              @media screen and (min-width: "1024px") and (max-width: "1550px") {
                font-size: 14px;
              }
            }
          }
        }
      }

      .mobilefeature {
        order: 2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        align-items: center;
        row-gap: 1rem;
        padding: 20px 0px;
        height: 40vh;

        @media screen and (min-width: "600px") and (max-width: "1024px") {
          margin: 3rem 0 2rem 0;
        }

        @media screen and (max-width: "600px") {
          margin: 1.8rem 0;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .btn {
          margin-top: 0.5rem;
          display: flex;
          align-self: center;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          width: 100%;
          padding: 0 2rem;
          min-height: 45px;
          border-radius: 8px;
          background: #40a1dc;
          font-weight: bold;
          font-size: 17px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          transition: 1s;

          @media screen and (min-width: "1024px") and (max-width: "1366px") {
            font-size: 13px;
          }

          @media screen and (max-width: "1024px") and (min-width: "600px") {
            margin-bottom: 2rem;
          }

          @media screen and (max-width: "600px") {
            width: 80%;
            font-size: 15px;
            margin-left: 2rem;
          }

          &:hover {
            transition: 0.5s;
            background: #1c587e;
          }
        }
      }

      .rightfeature {
        order: 3;
        width: 100%;
        display: flex;
        row-gap: 20px;
        flex-direction: column;
        justify-content: flex-start;
        justify-content: space-evenly;
        margin-top: 1%;

        @media screen and (max-width: "1024px") {
          row-gap: 60px;
        }

        .anaylize {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: flex-end;

          .left {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            @media screen and (max-width: "1024px") {
              width: 18%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
            }

            p {
              font-size: 3rem;
              font-weight: 500;
              color: #40a1dc;
              text-align: center;
            }
          }

          .right {
            width: 70%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            padding-bottom: 1rem;
            @media screen and (max-width: "1024px") {
              width: 100%;
            }

            label {
              font-size: 1.5rem;
              font-weight: 500;
              margin-top: 6px;
            }

            p {
              font-size: 16px;
              line-height: 1.5rem;
              font-weight: 300;

              @media screen and (min-width: "1024px") and (max-width: "1300px") {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .wrapper {
      .heading {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 1.5rem;
      }

      .featuresection {
        flex-direction: row;

        .leftfeature {
          width: 30%;
        }

        .mobilefeature {
          width: 40%;
          height: 81vh;

          img {
            width: auto;
            max-height: 100%;
          }
        }

        .rightfeature {
          width: 31%;
        }
      }
    }
  }
}
