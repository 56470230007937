.container {
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 1rem;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 1.5rem 0;
        row-gap: 0.9rem;


        label {
            font-size: 25px;
            color: #40a1dc;
            font-weight: bold;

        }

        p {
            text-align: justify;
            font-size: 16px;
            line-height: 25px;
            color: black;

            img {
                width: 17px;
            }

            span {
                font-weight: bold;
            }

            a {
                color: blue;
                font-size: 16px;
                font-weight: unset;
                border-bottom: 1px dotted blue;
                cursor: pointer;

            }
        }
    }
}