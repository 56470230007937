.container {
  width: 100%;
  padding: 50px 0px 0px 0px;
  .wrapper {
    width: 100%;
    .heading {
      width: 100%;
      text-align: center;
      padding: 0px 0px 32px 0px;
      p {
        font-size: 2rem;
        font-weight: 500;
        span {
          color: #40a1dc;
        }
      }
    }
    .profile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .leftsection {
        width: 100%;

        .title {
          width: 100%;
          height: 50%;
          line-height: 2rem;
          padding: 10px 0px;
          label {
            font-size: 30px;
          }
          span {
            color: #40a1dc;
          }
          p {
            font-size: 15px;
            line-height: 1.5rem;
          }
        }
        .profilearea {
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .circleprofile {
            display: flex;
            column-gap: 20px;
            align-items: center;
            .profileimage {
              border-radius: 50%;
              width: 50px;
              height: 50px;
              overflow: hidden;
              img {
                max-width: 100%;
                height: 100%;
              }
            }
            span {
              font-size: 15px;
              display: flex;
              flex-direction: column;
              label {
                color: #40a1dc;
              }
            }
          }
        }
      }
      .rightsection {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
}
@media screen and (min-width: 700px) {
  .container {
    .wrapper {
      .heading {
        p {
          font-size: 3rem;
        }
      }
      .profile {
        flex-direction: row;
        .leftsection {
          order: 1;
          width: 50%;
          .title {
            label {
              font-size: 20px;
            }
            p {
              font-size: 15px;
              line-height: 2rem;
            }
          }
          .profilearea {
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 12px;
            column-gap: 19px;
            .circleprofile {
              min-width: 150px;
            }
          }
        }
        .rightsection {
          width: 45%;
          order: 2;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .wrapper {
      .heading {
        padding-bottom: 3rem;
        p {
          font-size: 4rem;
        }
      }
      .profile {
        justify-content: space-evenly;
        .leftsection {
          width: 40%;
          .title {
            label {
              font-size: 30px;
            }
            p {
              font-size: 20px;
            }
          }
          .profilearea {
            padding: 2rem 0rem 0rem 0rem;
            column-gap: 4rem;
            row-gap: 2rem;
            max-width: 400px;
            .circleprofile {
            }
          }
        }
        .rightsection {
          width: 30%;
          justify-content: flex-start;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
