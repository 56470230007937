.wrapper {
  padding: 20px;
  width: 100%;
  column-gap: 1rem;
  row-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  .chatWidth {
    width: 30%;
    @media screen and (max-width: "800px") {
      width: 100%;
    }
  }
  .messageWrapper {
    width: 66%;
    @media screen and (max-width: "800px") {
      width: 100%;
    }
  }
}
