.container {
  width: 100%;
  height: auto;

  .wrapper {
    width: 100%;

    .selectionare {
      width: 100%;

      .namefield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;
          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }

        .displayfield {
          // max-width: 850px;
          width: 100%;
          flex-wrap: wrap;

          .innerselected {
            width: 100%;
            display: flex;
            row-gap: 10px;
            label {
              color: red;
            }
          }

          @media screen and (min-width: 600px) {
            max-width: 47%;
          }
        }
      }

      .phonefield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
        }

        .selected {
          width: 100%;
          display: flex;

          flex-direction: column;
          padding: 10px 0px;
          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }

      .addressfield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
            @media screen and (max-width: 600px) {
              font-size: 20px;
            }
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;

          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }

        .provinceselected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;
          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }
      }

      .mailingfield {
        width: 100%;

        .heading {
          width: 100%;
          padding: 20px 0px;

          label {
            font-size: 25px;
            font-weight: 500;
          }
        }

        .selected {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;
          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }
          @media screen and (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 50px;
          }
        }

        .displayfield {
          max-width: 450px;
          .innerselected {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            label {
              color: red;
            }
          }

          @media screen and (min-width: 600px) {
            max-width: 265px;
          }
        }
      }
    }
  }
}
