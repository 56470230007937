.container {
  .loaderWrapper {
    padding: 1rem 6.2rem 0 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    align-items: center;
    @media screen and (max-width: 1024px) {
      padding: 0rem 2.3rem 0 0;
    }
    .refresh {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
    .active {
      width: 30px;
      height: 30px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      @media screen and (max-width: 1024px) {
        right: 12%;
      }
      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 89vh;
  .wrapper {
    width: 100%;
    max-width: 1750px;
    row-gap: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    @media screen and (max-width: 1024px) {
      padding: 1rem 1rem;
    }
    .card {
      width: 60%;
      display: flex;
      align-self: center;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .buttonWrapper {
      column-gap: 1rem;
      width: 100%;
      row-gap: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      flex-wrap: wrap;
      .button2 {
        outline: none;
        border: none;
        width: 15%;
        min-width: 200px;
        height: 47px;
        border-radius: 6px;
        background: #40a1dc;
        color: white;
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        @media screen and (max-width: 580px) {
          width: 100%;
        }
        @media screen and (min-width: 580px) and (max-width: 664px) {
          min-width: 170px;
        }
        @media screen and (min-width: 664px) and (max-width: 814px) {
          min-width: 140px;
        }
      }
      .button1 {
        background: transparent;
        outline: none;
        border: none;
        width: 15%;
        min-width: 200px;
        height: 47px;
        border-radius: 6px;
        background: #1b1e2f;
        color: white;
        // font-weight: bold;
        font-weight: 17px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        @media screen and (max-width: 580px) {
          width: 100%;
        }
        @media screen and (min-width: 580px) and (max-width: 664px) {
          min-width: 170px;
        }
        @media screen and (min-width: 664px) and (max-width: 814px) {
          min-width: 140px;
        }
        &:hover {
          transition: 0.9s;
          box-shadow: inset -200px 0 0 0 #40a1dc, inset 200px 0 0 0 #40a1dc;
        }
        @media screen and (max-width: 614px) {
          &:hover {
            transition: 0.9s;
            box-shadow: inset -300px 0 0 0 #40a1dc, inset 300px 0 0 0 #40a1dc;
          }
        }
      }
    }
    .tableContainer {
      overflow-x: auto;
      .tableWrapper {
        min-width: 1007px;
      }
    }
  }
}
