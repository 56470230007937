.container {
  width: 100%;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3rem;
    padding: 0rem 0 1rem 0;

    .heading {
      width: 89%;
      font-weight: lighter;
      font-size: 20px;
      //   text-align: center;
      padding: 12px 0;
    }

    .orderHeading {
      display: flex;
      column-gap: 2rem;
      width: 90%;
      flex-wrap: wrap;
      row-gap: 2rem;

      .subHeading {
        display: flex;
        flex-direction: column;
        border-right: 4px dotted gray;
        // border: 1px solid red;
        padding: 0 10px;

        &:last-child {
          border: none;
        }

        label {
          font-weight: lighter;
          font-size: 15px;
        }

        span {
          font-weight: bold;
          font-size: 17px;
        }
      }
    }

    .middleBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;

      .rightBox {
        width: 100%;
        // border: 1px solid red;

        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;

        .boxHeading {
          width: 88%;
          font-size: 18px;
          font-weight: bold;
          text-align: left;

          .textWrapper {
            font-size: 17px;
            font-weight: lighter;
          }
        }

        .box {
          padding-top: 10px;
          padding-bottom: 20px;
          background-color: #1b1e2f;
          border-radius: 12px;
          width: 90%;
          .boxHeading {
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            p {
              font-size: 25px;
            }
          }
          .boxWrapper {
            margin-top: 15px;
            .boxData {
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 0.7rem 1rem;

              span {
                width: 100%;
                word-break: break-all;

                &:nth-child(1) {
                  font-weight: 600;

                  width: 70%;
                }
                @media screen and (max-width: 580px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .leftBox {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;

        .boxHeading {
          width: 89%;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
        }

        .box {
          background-color: #1b1e2f;
          border-radius: 12px;
          width: 90%;
          // min-height: 15vh;

          .boxData {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 1rem 1rem;
          }
        }
      }
    }

    .yourSign {
      width: 90%;
      text-align: start;
    }

    .signature {
      text-align: center;
      width: 90%;
      min-height: 15vh;
      border: 1px solid rgb(64 158 220);
      border-radius: 12px;
      background-color: #1b1e2f;

      img {
        width: 50%;
        height: auto;
      }
    }

    .button {
      width: 75%;
      display: flex;
      justify-content: flex-end;
      padding: 0 0 2rem 0;

      button {
        // border-radius: 5px;
        width: 20%;
        height: 30px;
        color: white;
        background-color: rgb(64 158 220);
        border: none;
        font-size: 16px;
        outline: none;
        transition: all 0.5s;

        &:hover {
          cursor: pointer;
          background-color: #1f222f;
          columns: rgb(64 158 220);
          border: 1px solid rgb(64 158 220);
          transition: all 0.5s;
        }
      }
    }
  }
}
