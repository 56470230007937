.container {
  width: 100%;
  height: auto;
  .wrapper {
    width: 100%;
    .heading {
      width: 100%;
      p {
        font-size: 20px;
        font-weight: 500;
        text-decoration: underline;
        color: #40a1dc;
      }
    }
    .textsection {
      max-width: 900px;
      padding: 10px 0px;
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 31px;
        text-align: left;
        span {
          font-weight: 500;
        }
        label {
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;
        }
        a {
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .dontAgree {
        // width: 60%;
        padding: 30px;
        text-align: center;
      }
    }
    .signaturearea {
      text-align: center;
      width: 100%;
      p {
        text-align: left;
        font-size: 20px;
        font-weight: 500;
      }
      .signature {
        width: 100%;
        height: 100px;
        background-color: #fff;
        border-radius: 8px;
        margin: 5px 0px;
        cursor: pointer;
        overflow: hidden;
      }
      .clear {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        column-gap: 1rem;

        p {
          text-align: right;
          color: #40a1dc;
          // text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
      }
      label {
        display: flex;
        justify-content: start;
        text-align: start;
        font-weight: 500;
        font-size: 20px;
        margin-top: 7px;
      }
    }
    .inputfield {
      width: 100%;
      span {
        font-size: 15px;
        font-weight: 300;
      }
      .captacha {
        width: 100%;
        margin: 10px 0px;
        padding: 10px 0px;
        cursor: pointer;
        img {
          max-width: 200px;
        }
      }
    }
  }
}
