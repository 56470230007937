.container {
  width: 100%;
  display: flex;
  justify-content: center;
  .card {
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }
  .cardWrapper {
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .wrapper {
    max-width: 1550px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 89vh;
    box-sizing: border-box;

    .heading {
      font-weight: bold;
      font-size: 30px;
      text-align: left;
      color: #fff;
      margin: 30px 0;
      white-space: nowrap;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
      }
    }

    .inputWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      .secInput {
        display: flex;
        width: 100%;
        flex-direction: row;
        row-gap: 2rem;
        column-gap: 4rem;
        .error {
          color: red;
        }
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          row-gap: 2rem;
        }
      }
    }
    .btnBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      margin-top: 100px;
      margin-bottom: 25px;
      width: 20%;
      height: 65px;
      outline: none;
      border: none;
      border-radius: 10px;
      background: #40a1dc;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.16);
      transition: 0.3s;
      cursor: pointer;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
      @media screen and (max-width: 1024px) {
        margin-top: 50px;
        margin-bottom: 80px;
        width: 100%;
        align-self: flex-start;
        height: 50px;
        font-size: 18px;
      }

      &:hover {
        transition: 0.5s;
        background: transparent;
        border: 1px solid #40a1dc;
        color: #40a1dc;
      }
    }
  }
}
