.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .wrapper {
    max-width: 1750px;
    width: 100%;
    .loaderPosition {
      display: flex;
      justify-content: center;
      height: 70vh;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      border-collapse: separate;
      border-spacing: 0px 15px;
      padding-bottom: 50px;
      .emptyImg {
        position: absolute;
        top: 30%;
        left: 45%;
        bottom: 30%;
      }

      tr {
        text-align: left;
        margin-bottom: 30px;

        td {
          padding: 0 9px;
          .nameWrapper {
            display: flex;
            column-gap: 1rem;
            justify-content: flex-start;
            text-align: left;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .firstimage {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            img {
              width: 35px;
            }
            label {
              color: rgba(255, 255, 255, 0.514);
            }
          }
          .bottomimage {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;
          }
        }
      }
      td:first-child {
        padding-left: 2rem;
      }
      tr:first-child {
        font-weight: 400;
        height: 18px;
        border-radius: 5px;
        // background: #1b1e2f;
      }
      tr:not(:first-child) {
        cursor: pointer;
        font-weight: 500;
        height: 75px;
        border-radius: 5px;
        background: #1b1e2f;
        .description {
          font-weight: normal;
          font-size: 12px;
          margin-top: 10px;
        }

        // :nth-child(4) {
        //   color: #51c511;
        // }
        // :nth-child(5) {
        //   color: #d10000;
        // }
        :nth-child(7) {
          :not(:first-child) {
            color: rgba(255, 255, 255, 0.438);

            padding-left: 2.1rem;
          }
        }
      }
    }
  }
}
